var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticStyle: { width: "100%", position: "relative" },
      style: {
        textAlign: _vm.isExpand ? "" : "left",
        paddingLeft: _vm.isExpand ? "0px" : "20px",
      },
      on: {
        mouseenter: _vm.mouseEntryHandler,
        mouseleave: function ($event) {
          _vm.showListWrapper = false && !_vm.isExpand
        },
      },
    },
    [
      _vm.isExpand
        ? _c(
            "el-collapse",
            {
              staticClass: "collapse-title",
              attrs: { accordion: "" },
              model: {
                value: _vm.activeName,
                callback: function ($$v) {
                  _vm.activeName = $$v
                },
                expression: "activeName",
              },
            },
            [
              _c(
                "el-collapse-item",
                { staticClass: "form-group", attrs: { name: _vm.item.formId } },
                [
                  _c(
                    "template",
                    { slot: "title" },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "form-group-header",
                          on: {
                            mouseenter: function ($event) {
                              _vm.showSettingIcon = true
                            },
                            mouseleave: function ($event) {
                              _vm.showSettingIcon = false
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "form-group-header-left" },
                            [_vm._t("icon"), _vm._t("title")],
                            2
                          ),
                          _c("formActionMenu", {
                            attrs: {
                              type: "form-group",
                              item: _vm.item,
                              page: _vm.page,
                              projectDetail: _vm.projectDetail,
                              loginUserId: _vm.loginUserId,
                              showSettingIcon: _vm.showSettingIcon,
                              isExpand: _vm.isExpand,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("draggable", {
                        staticClass: "drag-in-group-placeholder formItem",
                        attrs: {
                          options: {
                            group: { name: "formItem" },
                            sort: true,
                            handle: ".inner-list-group,.form-icon",
                          },
                          componentData: { groupId: _vm.item.formId },
                          animation: "300",
                          tag: "div",
                          "data-groupId": _vm.item.formId,
                          "data-isGroup": "true",
                        },
                        on: { end: _vm.onGroupDraggableEnd },
                      }),
                    ],
                    1
                  ),
                  _c("div", [_vm._t("list")], 2),
                ],
                2
              ),
            ],
            1
          )
        : _c("div", { staticClass: "form-group-shrink" }, [
            _c(
              "div",
              {
                staticClass: "form-name-wrapper form-name-group-wrapper",
                class: [
                  !_vm.loginUserId ||
                  _vm.projectDetail.userId != _vm.loginUserId
                    ? "disabled"
                    : "",
                ],
              },
              [_vm._t("title")],
              2
            ),
            _vm.showListWrapper
              ? _c(
                  "div",
                  {
                    staticClass: "form-list form-group-header",
                    staticStyle: { color: "black" },
                    style: _vm.shrinkLeftMenuPosition,
                  },
                  [_vm._t("list")],
                  2
                )
              : _vm._e(),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }