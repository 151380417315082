<template>
  <el-dialog title="组内用户" :visible="visible" @close="closeDialog">
    <div style="margin-bottom:15px;">
      <el-row>
        <el-col :span="12">
          <el-input placeholder="搜索用户" v-model="search" clearable @clear="clearSearch"></el-input>
        </el-col>
        <el-col :span="12" style="text-align:right">
          <el-button type="primary" @click="openUserDialog">添加用户</el-button>
        </el-col>
      </el-row>
    </div>
    <div>
      <!-- 用户管理表格 -->
      <el-table :data="filteredUsers">
        <el-table-column prop="name" label="用户名"></el-table-column>
        <el-table-column label="类型">
          <template slot-scope="{row}">
            <div v-if="row.type==='member'">用户</div>
            <div v-if="row.type==='dept'">部门</div>
            <div v-if="row.type==='role'">角色</div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="190" align="center">
          <template slot-scope="scope">
            <el-button type="text" style="margin-right:10px;" @click="removeUser(scope.row)">移除</el-button>
            <el-popover placement="bottom-end" title="移动到分组" width="200" trigger="click">
              <el-select size="small" v-model="moveToUserGroup" placeholder="请选择">
                <el-option v-for="(item,index) in userGroupList" :disabled="item.id === userGroupId" :key="index" :label="item.groupName" :value="item.id">
                </el-option>
              </el-select>
              <div style="text-align:right;margin-top:8px">
                当前：{{userGroupId}} 移动到: {{moveToUserGroup}}
                <el-button type="primary" @click="handleMoveUser(scope.row)">确定</el-button>
              </div>
              <template #reference>
                <el-button type="text">移动到其他组</el-button>
              </template>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <!-- <pagination :total="total" :page.sync="pageNum" :limit.sync="pageSize" @pagination="onHandleQuery" /> -->
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">关闭</el-button>
      <!-- <el-button type="primary" @click="confirmAddUser">确定</el-button> -->
    </div>

    <UserSelect ref="userSelect" v-model="userList" :agencyId="agencyId" listUserType="2" :showType="[1,2,3]"></UserSelect>
  </el-dialog>
</template>

<script>
// import UserSelect from "../../mechan/components/user-select"
import UserSelect from '@/views/system/form/components/setAuditMemberSelect.vue'
import { addUserToGroup, delUserFromGroup, getUserGroupMemberList, moveUserFromGroup } from "@/api/system/userGroup"
export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    agencyId: {
      type: Number,
      default: 0
    },
    userGroupId: {
      type: Number | String,
      default: 0
    },
    userGroupList: {
      type: Array,
      default: () => []
    }
  },
  components: {
    UserSelect
  },
  data () {
    return {
      search: "",
      currentPage: 1,
      total: 10,
      // 分页相关属性
      pageNum: 1,
      pageSize: 10,

      //用户组的用户
      userList: [],
      moveToUserGroup: '',
    };
  },
  computed: {
    filteredUsers () {
      if (!this.search) {
        return this.userList;
      }
      return this.userList.filter((user) => {
        return user.name.includes(this.search)
      }
      );
    },
  },
  methods: {
    clearSearch () {
      this.search = "";
    },
    handleSizeChange (newSize) {
      this.pageSize = newSize;
    },
    handleCurrentChange (newPage) {
      this.currentPage = newPage;
    },
    openUserDialog () {
      this.$refs.userSelect.show(this.userList, (members) => {
        this.userList = members

        let agencyUserIds = members.filter(x => x.type === 'member').map(x => x.agencyUserId);
        let agencyDeptIds = members.filter(x => x.type === 'dept').map(x => x.agencyDeptId);
        let agencyRoleIds = members.filter(x => x.type === 'role').map(x => x.agencyRoleId);


        addUserToGroup({
          agencyId: this.agencyId,
          agencyUserId: agencyUserIds.join(','),
          groupDeptId: agencyDeptIds.join(','),
          groupRoleId: agencyRoleIds.join(','),
          userGroupId: this.userGroupId,
          isDelete: 0
        })
        this.$forceUpdate();
      })
    },
    closeDialog () {
      this.userList.splice(0)
      this.$emit("update:visible", false)
    },
    confirmAddUser () {
      console.log("添加用户数据",
        {
          userSelect: this.userList,
          userGroupId: this.userGroupId
        }
      )


    },
    removeUser (user) {
      // 在这里添加移除用户的逻辑
      console.log("移除用户", user);
      // 提示是否删除用户。
      this.$confirm('确定要移除此用户吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async() => {
        // 确定
        await delUserFromGroup(this.userGroupId, user.type, user.agencyUserId||user.agencyDeptId|| user.agencyRoleId)
        this.getUserList();
      }).catch()      

    },
    getUserList () {
      getUserGroupMemberList(this.userGroupId).then(data=>{
        //  data.agencyUserId
        this.userList.splice(0)
        data.data.agencyUserList.forEach(x=>{
          this.userList.push({
            agencyUserId: x.agencyUserId,
            name: x.agencyUserName,
            type: 'member'
          })
        })
        data.data.agencyDeptList.forEach(x=>{
          this.userList.push({
            agencyDeptId: x.agencyDeptId,
            name: x.agencyDeptName,
            type : 'dept'
          })
        })
        data.data.agencyRoleList.forEach(x=>{
          this.userList.push({
            agencyDeptId: x.agencyRoleId,
            name: x.agencyRoleName,
            type : 'role'
          })
        })

        console.log('userGroupInfo', data)
      })
    },
    onHandleQuery () {
      this.getUserList();
    },
    async handleMoveUser (user) {
      // this.$confirm('确定要移动到该分组吗?', '提示', {
      //   confirmButtonText: '确定',
      //   cancelButtonText: '取消',
      //   type: 'warning'
      // }).then(async () => {
        // 确定
        var type=1;
        if(user.type==='member'){
          type=1;
        }else if(user.type==='dept'){
          type=2;
        }else if(user.type==='role'){
          type=3;
        }
        await moveUserFromGroup(this.userGroupId, this.moveToUserGroup, type, user.agencyUserId||user.agencyDeptId|| user.agencyRoleId)
        this.getUserList();
      // }).catch(() => {
      //   // 取消
      // });
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.getUserList();
      }
    }
  }
};
</script>

<style scoped>
.pagination-container {
}
</style>