<template>
  <div>
    <div id="pie-container" :style="{height}"></div>
  </div>
</template>
<script>
  import * as echarts from "echarts";
  export default {
    name: "PieChart",
    props: {
      height: {
        type: String,
        default: '405px'
      }
    },
    data() {
      return {};
    },
    created() {},
    mounted() {
      this.initChart();
    },
    methods: {
      initChart() {
        var myChart = echarts.init(document.getElementById("pie-container"));

        const option = {
          // title: {
          //   text: "项目申报统计",
          //   left: "center",
          // },
          tooltip: {
            trigger: "item",
          },
          legend: {
            top: 'bottom'
          },
          series: [
            {
              name: "申报统计",
              type: "pie",
              radius: "50%",
              data: [
                { value: 100, name: "省部级" },
                { value: 211, name: "教改项目" },
                { value: 300, name: "校级项目" },
                { value: 168, name: "横向课题" },
              ],
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)",
                },
              },
            },
          ],
        };

        myChart.setOption(option);
      },
    },
  };
</script>
