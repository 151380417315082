<template>
  <div>
    <div id="bar-container" style="width: 100%;height: 405px;"></div>
  </div>
</template>
<script>
  import * as echarts from "echarts";
  export default {
    name: "BarChart",
    data() {
      return {};
    },
    created() {},
    mounted() {
      this.initChart();
    },
    methods: {
      initChart() {
        var myChart = echarts.init(document.getElementById("bar-container"));

        const option = {
          title: {
            text: '成果统计',
            left: "center",
          },
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              crossStyle: {
                color: "#999",
              },
            },
          },
          legend: {
            top: 'bottom'
          },
          xAxis: [
            {
              type: "category",
              data: ["一月", "二月", "三月", "四月", "五月", "六月", "七月"],
              axisPointer: {
                type: "shadow",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
            {
              type: "value",
            },
            {
              type: "value",
            },
          ],
          series: [
            {
              name: "期刊/论文",
              type: "bar",
              data: [2, 4, 7, 23, 25, 76, 135, 162, 32, 20, 34, 22],
            },
            {
              name: "专利",
              type: "bar",
              data: [2, 5, 9, 26, 28, 70, 175, 182, 48, 18, 6, 2],
            },
            {
              name: "软件著作权",
              type: "bar",
              data: [5, 3, 19, 36, 38, 80, 145, 132, 42, 10, 9, 11],
            },
          ],
        };

        myChart.setOption(option);
      },
    },
  };
</script>
