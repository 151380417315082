var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { on: { click: _vm.onCloseFormPop } },
    [
      _vm.viewName == "baseView"
        ? [
            _c("detail-nav", {
              attrs: { breadcrumb: _vm.breadcrumb },
              on: {
                "login-success": _vm.loginSuccess,
                naviTo1: _vm.onProjectNameClick,
                naviTo2: _vm.onBreadcrumbFormNameClick,
              },
            }),
            _c(
              "div",
              { staticClass: "clearfix app-container app-detail-container" },
              [
                _c("div", { staticClass: "clearfix project-relate-form" }, [
                  _c(
                    "div",
                    {
                      staticClass: "relate-form-left fl left-menu",
                      class: _vm.menuExpand ? "" : "shrink",
                    },
                    [
                      _vm.menuExpand
                        ? _c("div", { staticClass: "menu-tool menu-title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "menu-title-text",
                                on: { click: _vm.onProjectNameClick },
                              },
                              [_vm._v(" 应用概览 ")]
                            ),
                            _c("div", { staticClass: "menu-title-action" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "menu-title-action-icon",
                                  on: {
                                    click: function ($event) {
                                      _vm.showMenuSearch = !_vm.showMenuSearch
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "el-tooltip",
                                    {
                                      staticClass: "item",
                                      attrs: {
                                        effect: "dark",
                                        content: "搜索",
                                        placement: "top-start",
                                      },
                                    },
                                    [
                                      _c("svg-icon", {
                                        attrs: { "icon-class": "searchNew" },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm.projectDetail.userId == _vm.loginUserId
                                ? _c(
                                    "div",
                                    { staticClass: "menu-title-action-icon" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "创建分组",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: {
                                              "icon-class": "formAddDir",
                                            },
                                            on: { click: _vm.onShowFormDialog },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm.projectDetail.userId == _vm.loginUserId
                                ? _c(
                                    "div",
                                    { staticClass: "menu-title-action-icon" },
                                    [
                                      _c(
                                        "el-tooltip",
                                        {
                                          staticClass: "item",
                                          attrs: {
                                            effect: "dark",
                                            content: "新建表单",
                                            placement: "top-start",
                                          },
                                        },
                                        [
                                          _c("svg-icon", {
                                            attrs: { "icon-class": "formAdd" },
                                            on: { click: _vm.onNewFormClick },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                          ])
                        : _vm._e(),
                      _c(
                        "div",
                        {
                          staticStyle: { height: "100%", "overflow-y": "auto" },
                        },
                        [
                          _vm.menuExpand && _vm.showMenuSearch
                            ? _c(
                                "div",
                                {
                                  staticClass: "menu-tool",
                                  staticStyle: { padding: "5px 16px" },
                                },
                                [
                                  _c("el-autocomplete", {
                                    staticClass: "nameSearch",
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      "fetch-suggestions": _vm.querySearchName,
                                      clearable: "",
                                      placeholder: "输入表单名称来搜索",
                                      "trigger-on-focus": false,
                                      size: "small",
                                    },
                                    on: { select: _vm.handleSelect },
                                    model: {
                                      value: _vm.detailFormName,
                                      callback: function ($$v) {
                                        _vm.detailFormName = $$v
                                      },
                                      expression: "detailFormName",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            {
                              style: {
                                paddingTop: _vm.menuExpand ? "0px" : "25px",
                              },
                            },
                            [
                              _vm.formGroupList && _vm.formGroupList.length > 0
                                ? _c(
                                    "draggable",
                                    {
                                      staticStyle: {
                                        padding: "0",
                                        margin: "0px",
                                      },
                                      attrs: {
                                        options: {
                                          group: { name: "group" },
                                          sort: true,
                                          handle:
                                            ".inner-list-group,.form-icon",
                                        },
                                        animation: "300",
                                        tag: "ul",
                                        disabled:
                                          !_vm.loginUserId ||
                                          _vm.projectDetail.userId !=
                                            _vm.loginUserId,
                                        "ghost-class": "ghost",
                                      },
                                      on: { end: _vm.onGroupDraggableEnd },
                                      model: {
                                        value: _vm.formGroupList,
                                        callback: function ($$v) {
                                          _vm.formGroupList = $$v
                                        },
                                        expression: "formGroupList",
                                      },
                                    },
                                    [
                                      _c(
                                        "transition-group",
                                        _vm._l(
                                          _vm.formGroupList,
                                          function (formGroupItem, groupIndex) {
                                            return _c("formGroupWrapper", {
                                              key: groupIndex,
                                              attrs: {
                                                item: formGroupItem,
                                                isExpand: _vm.menuExpand,
                                                expandName:
                                                  _vm.searchHandleGroupId,
                                                page: _vm.currentPage,
                                                projectDetail:
                                                  _vm.projectDetail,
                                                loginUserId: _vm.loginUserId,
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "icon",
                                                    fn: function () {
                                                      return [
                                                        _c("div", {
                                                          staticClass:
                                                            "form-icon element-form-icon el-icon-folder",
                                                          style: {
                                                            cursor:
                                                              !!_vm.loginUserId &&
                                                              _vm.projectDetail
                                                                .userId ==
                                                                _vm.loginUserId
                                                                ? "move"
                                                                : "unset",
                                                          },
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "title",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "font-size":
                                                                "14px",
                                                              height: "30px",
                                                              "line-height":
                                                                "30px",
                                                              overflow:
                                                                "hidden",
                                                              "user-select":
                                                                "none",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              " " +
                                                                _vm._s(
                                                                  _vm.menuExpand
                                                                    ? formGroupItem.name
                                                                    : formGroupItem.name
                                                                        .substring(
                                                                          0,
                                                                          1
                                                                        )
                                                                        .toUpperCase()
                                                                ) +
                                                                " "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                  {
                                                    key: "list",
                                                    fn: function () {
                                                      return [
                                                        _c(
                                                          "draggable",
                                                          {
                                                            staticStyle: {
                                                              padding: "0px",
                                                              margin: "0px",
                                                              "min-height":
                                                                "30px",
                                                              "margin-bottom":
                                                                "0px",
                                                            },
                                                            attrs: {
                                                              options: {
                                                                group: {
                                                                  name: "formItem",
                                                                },
                                                                sort: true,
                                                                handle:
                                                                  ".inner-list-group,.form-icon",
                                                              },
                                                              animation: "300",
                                                              tag: "ul",
                                                              componentData: {
                                                                groupId:
                                                                  formGroupItem.formId,
                                                              },
                                                              disabled:
                                                                !_vm.loginUserId ||
                                                                _vm
                                                                  .projectDetail
                                                                  .userId !=
                                                                  _vm.loginUserId,
                                                              "ghost-class":
                                                                "ghost",
                                                            },
                                                            on: {
                                                              add: _vm.onDraggableAdd,
                                                              end: _vm.onDraggableEnd,
                                                            },
                                                            model: {
                                                              value:
                                                                formGroupItem.children,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    formGroupItem,
                                                                    "children",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "formGroupItem.children",
                                                            },
                                                          },
                                                          [
                                                            _vm._l(
                                                              formGroupItem.children,
                                                              function (
                                                                item,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "li",
                                                                  {
                                                                    key: index,
                                                                    ref:
                                                                      "scroller" +
                                                                      index,
                                                                    refInFor: true,
                                                                    staticClass:
                                                                      "dragItem",
                                                                    class: {
                                                                      active:
                                                                        _vm.cur ==
                                                                          index &&
                                                                        _vm.formGroupCur ==
                                                                          groupIndex,
                                                                      disabled:
                                                                        !_vm.loginUserId ||
                                                                        _vm
                                                                          .projectDetail
                                                                          .userId !=
                                                                          _vm.loginUserId
                                                                          ? "disabled"
                                                                          : "",
                                                                    },
                                                                    staticStyle:
                                                                      {
                                                                        height:
                                                                          "initial",
                                                                      },
                                                                    attrs: {
                                                                      index:
                                                                        index,
                                                                      "data-formId":
                                                                        item.formId,
                                                                      "data-groupId":
                                                                        formGroupItem.formId,
                                                                      "data-isGroup":
                                                                        "true",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "formGroupItemWrapper",
                                                                      {
                                                                        attrs: {
                                                                          isExpand: true,
                                                                          item: item,
                                                                          cur: index,
                                                                          formGroupCur:
                                                                            groupIndex,
                                                                          page: _vm.currentPage,
                                                                          projectDetail:
                                                                            _vm.projectDetail,
                                                                          loginUserId:
                                                                            _vm.loginUserId,
                                                                        },
                                                                        scopedSlots:
                                                                          _vm._u(
                                                                            [
                                                                              !item.icon
                                                                                ? {
                                                                                    key: "icon",
                                                                                    fn: function () {
                                                                                      return [
                                                                                        _c(
                                                                                          "svg-icon",
                                                                                          {
                                                                                            staticClass:
                                                                                              "form-icon",
                                                                                            style:
                                                                                              {
                                                                                                color:
                                                                                                  _vm.menuExpand
                                                                                                    ? "inherit"
                                                                                                    : "white",
                                                                                                cursor:
                                                                                                  !!_vm.loginUserId &&
                                                                                                  _vm
                                                                                                    .projectDetail
                                                                                                    .userId ==
                                                                                                    _vm.loginUserId
                                                                                                    ? "move"
                                                                                                    : "unset",
                                                                                              },
                                                                                            attrs:
                                                                                              {
                                                                                                "icon-class":
                                                                                                  "formO",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                    proxy: true,
                                                                                  }
                                                                                : item.icon
                                                                                ? {
                                                                                    key: "icon",
                                                                                    fn: function () {
                                                                                      return [
                                                                                        _c(
                                                                                          "div",
                                                                                          {
                                                                                            staticClass:
                                                                                              "form-icon element-form-icon",
                                                                                            class:
                                                                                              item.icon,
                                                                                            style:
                                                                                              {
                                                                                                color:
                                                                                                  _vm.menuExpand
                                                                                                    ? "inherit"
                                                                                                    : "inherit",
                                                                                                cursor:
                                                                                                  !!_vm.loginUserId &&
                                                                                                  _vm
                                                                                                    .projectDetail
                                                                                                    .userId ==
                                                                                                    _vm.loginUserId
                                                                                                    ? "move"
                                                                                                    : "unset",
                                                                                              },
                                                                                          }
                                                                                        ),
                                                                                      ]
                                                                                    },
                                                                                    proxy: true,
                                                                                  }
                                                                                : null,
                                                                              {
                                                                                key: "title",
                                                                                fn: function () {
                                                                                  return [
                                                                                    _c(
                                                                                      "div",
                                                                                      {
                                                                                        staticStyle:
                                                                                          {
                                                                                            height:
                                                                                              "30px",
                                                                                            "line-height":
                                                                                              "30px",
                                                                                            overflow:
                                                                                              "hidden",
                                                                                            "user-select":
                                                                                              "none",
                                                                                          },
                                                                                      },
                                                                                      [
                                                                                        _vm._v(
                                                                                          " " +
                                                                                            _vm._s(
                                                                                              item.name
                                                                                            ) +
                                                                                            " "
                                                                                        ),
                                                                                      ]
                                                                                    ),
                                                                                  ]
                                                                                },
                                                                                proxy: true,
                                                                              },
                                                                            ],
                                                                            null,
                                                                            true
                                                                          ),
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            !formGroupItem.children ||
                                                            formGroupItem
                                                              .children
                                                              .length == 0
                                                              ? _c(
                                                                  "li",
                                                                  {
                                                                    staticClass:
                                                                      "dragItem",
                                                                    class: {
                                                                      active:
                                                                        _vm.cur ==
                                                                          groupIndex &&
                                                                        _vm.formGroupCur ==
                                                                          0,
                                                                    },
                                                                    attrs: {
                                                                      "data-groupId":
                                                                        formGroupItem.formId,
                                                                      "data-isGroup":
                                                                        "true",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "formGroupItemEmpty formItem",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " 分组内暂无表单 "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            })
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _c(
                            "draggable",
                            {
                              staticStyle: {
                                padding: "0",
                                margin: "0px",
                                "min-height": "40px",
                              },
                              attrs: {
                                options: {
                                  group: { name: "formItem" },
                                  sort: true,
                                  handle: ".inner-list-group,.form-icon",
                                },
                                animation: "300",
                                tag: "ul",
                                disabled:
                                  !_vm.loginUserId ||
                                  _vm.projectDetail.userId != _vm.loginUserId,
                                "ghost-class": "ghost",
                              },
                              on: { end: _vm.onDraggableEnd },
                              model: {
                                value: _vm.formList,
                                callback: function ($$v) {
                                  _vm.formList = $$v
                                },
                                expression: "formList",
                              },
                            },
                            [
                              _vm._l(_vm.formList, function (item, index) {
                                return _c(
                                  "li",
                                  {
                                    key: index,
                                    ref: "scroller" + index,
                                    refInFor: true,
                                    staticClass: "dragItem",
                                    class: {
                                      active:
                                        _vm.cur == index &&
                                        _vm.formGroupCur == -1,
                                    },
                                    style: {
                                      width: _vm.menuExpand
                                        ? "initial"
                                        : "42px",
                                    },
                                    attrs: {
                                      index: index,
                                      "data-formId": item.formId,
                                      "data-isGroup": "false",
                                    },
                                  },
                                  [
                                    _c("formGroupItemWrapper", {
                                      attrs: {
                                        isExpand: _vm.menuExpand,
                                        item: item,
                                        cur: index,
                                        formGroupCur: -1,
                                        page: _vm.currentPage,
                                        projectDetail: _vm.projectDetail,
                                        loginUserId: _vm.loginUserId,
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          !item.icon
                                            ? {
                                                key: "icon",
                                                fn: function () {
                                                  return [
                                                    _c("div", {
                                                      staticClass:
                                                        "form-icon element-form-icon el-icon-document",
                                                      style: {
                                                        color: _vm.menuExpand
                                                          ? "#646D7A"
                                                          : "white",
                                                        cursor:
                                                          _vm.projectDetail
                                                            .userId ==
                                                          _vm.loginUserId
                                                            ? "move"
                                                            : "",
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              }
                                            : item.icon
                                            ? {
                                                key: "icon",
                                                fn: function () {
                                                  return [
                                                    _c("div", {
                                                      staticClass:
                                                        "form-icon element-form-icon",
                                                      class: item.icon,
                                                      style: {
                                                        color: _vm.menuExpand
                                                          ? "#646D7A"
                                                          : "white",
                                                        cursor:
                                                          _vm.projectDetail
                                                            .userId ==
                                                          _vm.loginUserId
                                                            ? "move"
                                                            : "",
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              }
                                            : null,
                                          _vm.menuExpand
                                            ? {
                                                key: "title",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          height: "30px",
                                                          "line-height": "30px",
                                                          overflow: "hidden",
                                                          "user-select": "none",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(item.name) +
                                                            " "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              }
                                            : !_vm.menuExpand
                                            ? {
                                                key: "title",
                                                fn: function () {
                                                  return [
                                                    item.icon
                                                      ? [
                                                          _c("div", {
                                                            staticClass:
                                                              "form-icon element-form-icon",
                                                            class: item.icon,
                                                            style: {
                                                              color:
                                                                _vm.menuExpand
                                                                  ? "#646D7A"
                                                                  : "white",
                                                              cursor:
                                                                _vm
                                                                  .projectDetail
                                                                  .userId ==
                                                                _vm.loginUserId
                                                                  ? "move"
                                                                  : "",
                                                            },
                                                          }),
                                                        ]
                                                      : [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                height: "30px",
                                                                "line-height":
                                                                  "30px",
                                                                overflow:
                                                                  "hidden",
                                                                "user-select":
                                                                  "none",
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.name
                                                                      .substring(
                                                                        0,
                                                                        1
                                                                      )
                                                                      .toUpperCase()
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                  ]
                                                },
                                                proxy: true,
                                              }
                                            : null,
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              }),
                              _vm.formList.length == 0
                                ? _c(
                                    "li",
                                    {
                                      staticClass: "dragItem",
                                      staticStyle: { height: "30px" },
                                      attrs: { "data-isGroup": "false" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "formGroupItemEmpty formItem",
                                        },
                                        [_vm._v(" 暂无表单 ")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "relate-form-middle",
                      class: _vm.menuExpand ? "" : "shrink",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "shrink-icon",
                          on: { click: _vm.shrinkMenuClickHandler },
                        },
                        [
                          _c("svg-icon", {
                            attrs: { "icon-class": "myRightArrow" },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.showUserGroup
                    ? _c(
                        "div",
                        {
                          staticClass: "relate-form-right fl",
                          class: _vm.menuExpand ? "" : "shrink",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _c("userGroup", {
                            attrs: { agencyId: _vm.projectDetail.agencyId },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "relate-form-right fl",
                          class: _vm.menuExpand ? "" : "shrink",
                          staticStyle: { position: "relative" },
                        },
                        [
                          _vm.cur == null || _vm.cur == -1
                            ? _c("info", {
                                attrs: {
                                  loginUserId: _vm.loginUserId,
                                  projectDetail: _vm.projectDetail,
                                  advanced: _vm.advanced,
                                },
                                on: {
                                  "update:advanced": function ($event) {
                                    _vm.advanced = $event
                                  },
                                },
                              })
                            : _c("formViews", {
                                ref: "formDetail",
                                style: [
                                  { width: _vm.formCur == 5 ? "100%" : "100%" },
                                ],
                                attrs: { standalone: false },
                              }),
                        ],
                        1
                      ),
                ]),
              ]
            ),
          ]
        : _vm.viewName == "webView"
        ? [
            _c("WebViewIndex", {
              attrs: {
                projectDetail: _vm.projectDetail,
                fileList: _vm.projectDetail.fileList,
                formGroupList: _vm.formGroupList,
              },
            }),
          ]
        : _vm._e(),
      _c("share", { ref: "share" }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "移动表单",
            visible: _vm.formMoveVisible,
            width: "600px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.formMoveVisible = $event
            },
            close: _vm.onCloseMoveClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formMove",
              attrs: {
                model: _vm.formMoveObj,
                rules: _vm.formMoveRules,
                "label-width": "100px",
                "label-position": "right",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "移动到应用", prop: "projectId" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "移动到...", filterable: "" },
                          model: {
                            value: _vm.formMoveObj.projectId,
                            callback: function ($$v) {
                              _vm.$set(_vm.formMoveObj, "projectId", $$v)
                            },
                            expression: "formMoveObj.projectId",
                          },
                        },
                        [
                          _c(
                            "el-option",
                            {
                              attrs: { value: "0", label: "移动成为独立表单" },
                            },
                            [_vm._v("移动成为独立表单")]
                          ),
                          _vm._l(_vm.moveFormProjectList, function (item) {
                            return _c("el-option", {
                              key: item.projectId,
                              attrs: {
                                label: item.name,
                                value: item.projectId,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmMoveClick },
                },
                [_vm._v("确 定")]
              ),
              _c("el-button", { on: { click: _vm.onCancelMoveClick } }, [
                _vm._v("取 消"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.showFormDialogCreate ? "创建分组" : "编辑分组",
            visible: _vm.showFormDialogFlag,
            width: "502px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFormDialogFlag = $event
            },
            close: _vm.onSaveFromGroupClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "createGroupForm",
              attrs: {
                model: _vm.formGroup,
                rules: _vm.formGroupRule,
                "label-width": "60px",
                "label-position": "right",
              },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "名称", prop: "name" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "" },
                        model: {
                          value: _vm.formGroup.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.formGroup, "name", $$v)
                          },
                          expression: "formGroup.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmCreateGroupClick },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showFormDialogFlag = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "移动到分组",
            visible: _vm.showMoveToGroupDialogFlag,
            width: "502px",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showMoveToGroupDialogFlag = $event
            },
            close: _vm.onSaveFromGroupClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "formGroupList",
              attrs: { "label-width": "100px", "label-position": "right" },
              nativeOn: {
                submit: function ($event) {
                  $event.preventDefault()
                },
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "移动到分组", prop: "name" } },
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "" },
                          model: {
                            value: _vm.moveToGroupId,
                            callback: function ($$v) {
                              _vm.moveToGroupId = $$v
                            },
                            expression: "moveToGroupId",
                          },
                        },
                        _vm._l(_vm.formGroupList, function (opt) {
                          return _c("el-option", {
                            key: opt.formId,
                            attrs: { value: opt.formId, label: opt.name },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.onConfirmMoveToGroupClick },
                },
                [_vm._v("确 定")]
              ),
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.showMoveToGroupDialogFlag = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("el-dialog", {
        attrs: {
          title: "这是xxx报表",
          visible: _vm.agGridDialogVisible,
          width: "80%",
          "destroy-on-close": true,
        },
        on: {
          "update:visible": function ($event) {
            _vm.agGridDialogVisible = $event
          },
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }