var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content-wrapper" },
    [
      _c("content-title", {
        attrs: { formDesign: _vm.formDesign },
        model: {
          value: _vm.value.title,
          callback: function ($$v) {
            _vm.$set(_vm.value, "title", $$v)
          },
          expression: "value.title",
        },
      }),
      _c(
        "div",
        { staticClass: "content-wrapper-content" },
        _vm._l(_vm.value.config.urls, function (file, index) {
          return _c("img", {
            key: index,
            attrs: { src: file.url, height: _vm.value.config.height },
          })
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }