<template>
  <div style="width:100%;position:relative" :style="{textAlign:isExpand?'':'left',paddingLeft:isExpand?'0px':'20px'}" @mouseenter="mouseEntryHandler" @mouseleave="showListWrapper=false&&!isExpand">
    <el-collapse v-model="activeName" v-if="isExpand" accordion class="collapse-title">
      <el-collapse-item class="form-group" :name="item.formId">
        <template slot="title">
          <div class="form-group-header" @mouseenter="showSettingIcon=true" @mouseleave="showSettingIcon=false">
            <div class="form-group-header-left">
              <slot name="icon"></slot>
              <slot name="title"></slot>
            </div>
            <formActionMenu type="form-group" :item="item" :page="page" :projectDetail="projectDetail" :loginUserId="loginUserId" :showSettingIcon="showSettingIcon" :isExpand="isExpand"></formActionMenu>
          </div>
          <draggable :options="{
                group: { name: 'formItem' },
                sort: true,
                handle: '.inner-list-group,.form-icon'
              }" class="drag-in-group-placeholder formItem"
               :componentData="{ groupId: item.formId }"
               animation="300" tag="div"
               :data-groupId="item.formId" 
               data-isGroup="true"              
               @end="onGroupDraggableEnd">
          </draggable>
        </template>
        <div>
          <slot name="list"></slot>
        </div>
      </el-collapse-item>
    </el-collapse>

    <div v-else class="form-group-shrink">
      <div class="form-name-wrapper form-name-group-wrapper" :class="[!loginUserId || projectDetail.userId != loginUserId?'disabled':'']">
        <slot name="title"></slot>
      </div>
      <div class="form-list form-group-header" style="color:black" :style="shrinkLeftMenuPosition" v-if="showListWrapper">
        <slot name="list"></slot>
      </div>
    </div>

  </div>
</template>
<script>

import formActionMenu from '../formGroup/formActionMenu'
export default {
  name: "formGroupWrapper",
  components: {
    formActionMenu
  },
  props: {
    type: String,
    item: Object,
    page: Object,
    isExpand: Boolean,
    expandName: String,
    projectDetail: Object,
    loginUserId: Number,
    // showSettingIcon: Boolean
  },
  watch: {
    expandName: function () {
      this.activeName = this.expandName;
    }
  },
  created () {
    this.activeName = this.item.formId;
  },
  data () {
    return {
      showSettingIcon: false,
      showListWrapper: false,
      activeName: "",
      shrinkLeftMenuPosition: {
        left: '0px',
        right: '0px'
      }
    }
  },
  methods: {
    mouseEntryHandler (e) {
      this.showListWrapper = true && !this.isExpand


      const rect = e.target.getBoundingClientRect();
      this.shrinkLeftMenuPosition.left = (rect.left + 60) + 'px';
      this.shrinkLeftMenuPosition.top = (rect.top) + 'px';
    },
    onGroupDraggableEnd(e){
      console.log('dragInto',e)
    }
  }
}
</script>
<style lang="scss" scoped>
.collapse-title {
  border-top: none;
  border-bottom: dash 1px #fcfcfc;
  ::v-deep .el-collapse-item__arrow {
    display: none;
  }
}

.form-icon {
  width: 15px;
  height: 15px;
  font-size: 15px;
}

.form-group {
  width: 100%;
  .form-group-header {
    width: 100%;
    // height: 40px;
    line-height: 35px;
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2px;

    .form-group-header-left {
      // height: 40px;
      line-height: 40px;
      display: inline-flex;
      align-items: center;
    }
  }

  ::v-deep .el-collapse-item__header {
    width: 100%;
    padding-left: 5px;
    padding-right: 10px;
    display: block;

    // height: 40px;
    height: initial;
    line-height: 40px;
    background-color: transparent;

    &:hover {
      background-color: #f0f2f5;
      color: #646d7a;
    }
  }
  ::v-deep .el-collapse-item__content {
    padding-bottom: 0px;
  }
  .drag-in-group-placeholder {
    width: 100%;
    height: 2px;
  }
}

.form-group-shrink {
  width: 30px;
  // height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: unset;
  color: #fff;
  background-color: #ffc678;
  text-align: center;
  padding-left: 0px;
  margin-left: 0px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 5px;
  display: inline-block;
  text-align: center;

  .form-name-wrapper:not(.disabled) {
    cursor: pointer;

  }

  .form-name-group-wrapper {
    background-color: #c5c6c7;
    // padding-left: 7px;
  }
}

.form-list {
  width: 260px;

  position: fixed;
  left: 15%;
  top: 0px;
  z-index: 1997;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border: 1px solid #e6ebf5;

  &:before {
    content: '';
    border-width: 7px;
    top: 14px;
    left: -7px;
    border-color: #0000;
    border-right-color: #e6ebf5;
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-left-width: 0;
  }

  &:after {
    content: '';
    border-width: 6px;
    top: 15px;
    left: 0px;
    margin-left: -6px;
    border-color: transparent;
    border-right-color: #fff;

    position: absolute;
    display: block;
    width: 0;
    height: 0;

    border-style: solid;
    border-left-width: 0;
  }
}
</style>
