<template>
  <div class="content-menu" v-if="isExpand" @mouseenter="showMenuHandler" @mouseleave="showMenu=false" @click.stop="">
    <div class="menu-icon" :class="{ 'show-menu': showSettingIcon }" :style="{marginRight:(type=='form-group'?'5px':'')}" v-if="isAdminShowSettingIcon">
      <!-- <svg-icon icon-class="formEdit" placement="top-start" class="icon-set" /> -->
      <div class="form-icon element-form-icon el-icon-s-tools" style="color:#3476F0; margin: 0 auto"></div>
      <div ref="menu_dropdown" class="menu-dropdown" :class="{ 'show-menu' : showMenu,'show-menu-overflow': showMenuOverflow }" :style="menuPosition">
        <div class="menu-dropdown-item" @mouseenter="showIconSelect=true" @mouseleave="showIconSelect=false" v-if="!isFormGroup">
          <div>修改图标</div>
          <formIconSelector :value="item.icon" :showIconSelect="showIconSelect" @on-selected="onEditFormIcon"></formIconSelector>
        </div>
        <template v-if="!isFormGroup">
          <div class="menu-dropdown-item" @click="page.onEditFormClick(item)">编辑</div>
          <div class="menu-dropdown-item" @click="page.onCopyFormClick(item)">复制</div>
          <div class="menu-dropdown-item" @click="page.onHideFormClick(item.formId)" v-if="false&&!item.isHide">隐藏</div>
          <div class="menu-dropdown-item" @click="page.onShowFormClick(item.formId)" v-if="false&& item.isHide">显示</div>
          <div class="menu-dropdown-item" @click="page.onMoveFormClick(item.formId)">移动</div>
          <div class="menu-dropdown-item" @click="page.onMoveToGroupFormClick(item)" v-if="!isInFormGroup">移到分组</div>
          <div class="menu-dropdown-item" @click="page.onMoveOutGroupFormClick(item.formId)" v-if="isInFormGroup">移出分组</div>
          <div class="menu-dropdown-item" @click="page.onDeleteFormClick(item)">删除</div>
        </template>
        <template v-else>
          <div class="menu-dropdown-item" @click="page.onAddGroupFormClick(item)">新建表单</div>
          <div class="menu-dropdown-item" @click="page.onEditGroupClick(item)">编辑</div>
          <div class="menu-dropdown-item" @click="page.onDeleteGroupClick(item)">删除</div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import formIconSelector from './formIconSelector.vue'
export default {
  components: {
    formIconSelector
  },
  props: {
    type: String,
    item: Object,
    page: Object,
    isExpand: Boolean,
    projectDetail: {
      type: Object,
      default: () => { }
    },
    loginUserId: {
      type: Number,
      default: null
    },
    showSettingIcon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showMenu: false,
      showIconSelect: false,
      showMenuOverflow: false,

      menuPosition: {
        left: 0,
        top: 0
      }
    }
  },
  computed: {
    isFormGroup: function () {
      return this.type == 'form-group'
    },
    isInFormGroup: function () {
      return this.checkParentIsGroupWrapper(this.$parent);
    },
    isAdminShowSettingIcon: function () {
      if (!this.projectDetail) return false;
      return this.projectDetail.userId == this.loginUserId
    }
  },
  created () {

  },
  methods: {
    checkParentIsGroupWrapper (comp) {
      if (comp.$options._componentTag == "formGroupWrapper") {
        return true;
      }
      if (comp.$parent)
        return this.checkParentIsGroupWrapper(comp.$parent)
      else
        return false;
    },
    onEditFormIconClick (item) {
      if (isFormGroup) {
        this.page.onEditGroupIconClick(item);
      } else {
        this.page.onEditFormIconClick(item);
      }
    },
    onEditFormIcon (iconName) {
      this.page.onEditFormIcon(this.item, iconName)
    },
    showMenuHandler (e) {
      this.showMenu = true;

      const rect = e.target.getBoundingClientRect();
      this.menuPosition.left = (rect.right + 5) + 'px';
      this.menuPosition.top = (rect.top - 10) + 'px';

      var screenHeight = window.innerHeight;

      this.$nextTick(() => {
        let childNodes = 0
        let childNodeHeight = 0;
        if (this.$refs.menu_dropdown && this.$refs.menu_dropdown.childNodes) {
          for (let i = 0; i < this.$refs.menu_dropdown.childNodes.length; i++) {

            let item = this.$refs.menu_dropdown.childNodes[i];
            if (childNodeHeight == 0 && item) {
              childNodeHeight = item.offsetHeight;
            }
            if (item.tagName == 'DIV') {
              childNodes++;
            }
          }
          if (rect.top + childNodes * childNodeHeight > screenHeight) {
            this.showMenuOverflow = true;
            this.menuPosition.top = (rect.top) - (childNodes * childNodeHeight) + 30 + 'px';
          } else {
            this.showMenuOverflow = false;
          }
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.content-menu {
  float: right;
  cursor: pointer;
}
.content-menu {
  width: 40px;
  height: 40px;
  line-height: 40px;
  flex-shrink: 0;
  flex-grow: 0;
  text-align: center;
  display: inline-block;
  position: relative;

  .menu-icon {
    display: none;
    width: 0px;

    .menu-dropdown {
      display: none;
      width: 140px;
      background-color: #fff;
      position: fixed;
      top: 35px;
      z-index: 2000;
      left: 8px;
      text-align: left;
      padding: 5px 0;

      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #e6ebf5;

      &-item {
        line-height: 32px;
        padding: 0 20px;
        position: relative;
        color: black;

        &:hover {
          background-color: #f0f2f5;
          color: #646d7a;
        }
      }

      &:before {
        content: '';
        border-width: 7px;
        top: 21px;
        left: -15px;
        border-color: transparent;
        margin-right: -1px;
        border-right-color: #e6ebf5;

        position: absolute;
        display: block;
        width: 0;
        height: 0;

        border-style: solid;
        // border-left-width: 0;
      }

      &:after {
        content: '';
        border-width: 6px;
        top: 22px;
        left: -12px;
        border-color: transparent;
        margin-right: -0px;
        border-right-color: #fff;

        position: absolute;
        display: block;
        width: 0;
        height: 0;

        border-style: solid;
        // border-left-width: 0;
      }
    }

    .edit-icon-wrapper {
      position: absolute;
      left: calc(100% - 2px);
      top: -10px;
      background-color: #fff;
      display: none;

      z-index: 2010;
      text-align: left;
      padding: 15px;
      padding-top: 8px;
      box-sizing: border-box;
      color: black;

      border-radius: 4px;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      border: 1px solid #e6ebf5;

      &:before {
        content: '';
        border-width: 7px;
        top: 14px;
        left: -7px;
        border-color: #0000;
        border-right-color: #e6ebf5;
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-style: solid;
        border-left-width: 0;
      }

      &:after {
        content: '';
        border-width: 6px;
        top: 15px;
        left: -6px;
        border-color: transparent;
        border-right-color: #fff;

        position: absolute;
        display: block;
        width: 0;
        height: 0;

        border-style: solid;
        border-left-width: 0;
      }
    }
  }

  .show-menu {
    display: inline-block !important;
  }

  .show-menu-overflow {
    &::before {
      top: initial !important;
      bottom: 15px;
    }
    &::after {
      top: initial !important;
      bottom: 16px;
    }
  }
}

// &:hover {
//   background-color: #ecf5ff;
//   color: #66b1ff;
// }
// }
</style>
