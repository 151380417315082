<template>
  <div class="form-view-table">
    <el-table :data="formList" size="small" :row-class-name="isShowExpend" style="width:100%" :cell-style="{padding:'0px;'}" @expand-change="handleExpandChange">
      <el-table-column type="expand" width="30px">
        <template slot-scope="props">
          <el-table :data="props.row.children" :show-header="false" :cell-style="{padding: '0px'}">
            <el-table-column label="查看" width="60px" align="right">
              <template slot-scope="props">
                <div class="table-cell-right">
                  <el-checkbox v-model="props.row.enable"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="name">
              <template slot-scope="props">
                <div class="table-cell-left">{{props.row.name}}</div>
              </template>
            </el-table-column>
            <el-table-column label="查看" width="80px" align="center">
              <template slot-scope="props">
                <div class="table-cell-center">
                  <el-checkbox v-model="props.row.view"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="修改" width="80px" align="center">
              <template slot-scope="props">
                <div class="table-cell-center">
                  <el-checkbox v-model="props.row.edit"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="删除" width="80px" align="center">
              <template slot-scope="props">
                <div class="table-cell-center">
                  <el-checkbox v-model="props.row.delete"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="审核" width="80px" align="center">
              <template slot-scope="props">
                <div class="table-cell-center">
                  <el-checkbox v-model="props.row.auth"></el-checkbox>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="字段" width="80px" align="center">
              <template slot-scope="props" v-if="props.row.editableFiles!==undefined">
                <el-button type="text" slot="reference" @click="showSetFields(props.row)">设置</el-button>
              </template>
            </el-table-column>
          </el-table>
        </template>
      </el-table-column>
      <el-table-column label="名称" prop="name"></el-table-column>
      <el-table-column label="查看" width="80px" align="center"></el-table-column>
      <el-table-column label="修改" width="80px" align="center"></el-table-column>
      <el-table-column label="删除" width="80px" align="center"></el-table-column>
      <el-table-column label="审核" width="80px" align="center"></el-table-column>
      <el-table-column label="字段" width="80px" align="center"></el-table-column>
    </el-table>

    <el-drawer title="设置表单字段" :visible.sync="showDrawer" :append-to-body="true" :with-header="true">
      <div class="drawer-content">
        <el-table :data="viewShowFields">
          <el-table-column label="字段名" prop="headerName">
          </el-table-column>
          <el-table-column label="修改" width="80px" align="center">
            <template slot-scope="props">
              <div class="table-cell-center">
                <el-checkbox v-model="props.row.edit"></el-checkbox>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div class="drawer-footer">
          <el-button type="default" @click="onCancelSaveEditableFields">关闭</el-button>
          <el-button type="primary" @click="onConfirmEditableFields">确定</el-button>
        </div>
      </div>
    </el-drawer>

  </div>
</template>

<script>
import { projectGroup } from "@/api/system/form"
import { listViews, viewDetail, deleteView } from '@/api/system/views.js'
export default {
  props: {
    agencyId: {
      type: Number,
      default: 0
    },
    userGroupId: {
      type: Number | String,
      default: 0
    },
    projectId: {
      type: String,
      default: ''
    },
    value: {
      type: Array,
      default: () => []
    },
    formList:{
      type: Array,
      default: () => []
    }
  },
  components: {
  },
  data () {
    return {
      search: "",
      currentPage: 1,
      total: 10,
      // 分页相关属性
      pageNum: 1,
      pageSize: 10,

      showDrawer: false,
      editView: {},
      viewShowFields: [],
    };
  },
  methods: {
    isShowExpend ({ row }, index) {
      if (!row.children) {
        return 'hide-expend'
      }
      return ''
    },
    clearSearch () {
      this.search = "";
    },
    handleSizeChange (newSize) {
      this.pageSize = newSize;
    },
    handleCurrentChange (newPage) {
      this.currentPage = newPage;
    },
    openUserDialog () {
      this.$refs.userSelect.showDialog(this.userList, {});
    },
    closeDialog () {
      this.$emit("update:visible", false)
    },
    removeUser (user) {
      // 在这里添加移除用户的逻辑
      console.log("移除用户", user);
    },
    moveUser (user) {
      // 在这里添加移动用户的逻辑
      console.log("移动用户", user);
    },
    
    onHandleQuery () {
      this.getUserList();
    },
    showSetFields (row) {
      this.editView = row;
      viewDetail(row.id).then(res => {
        this.$set(this, 'viewShowFields', res.data.showFields.filter(x => !x.hide));
        this.showDrawer = true
      })
    },
    handleExpandChange (row, expandedRows) {
      let isExpanded = expandedRows.find(x => x.id === row.id);

      if (isExpanded) {
        row.children.splice(0)
        const newViewData = {
          name: '全部数据',
          id: 'viewData',
          delete: false,
          auth: false,
          edit: false,
          view: false,
          enable: false,
        }

        if (this.formList && Array.isArray(this.formList)) {
          // 表单信息
          let formInfo = this.formList.find(x => x.id === row.id);
          // 查看全部数据的视图
          let viewInfoAllData = formInfo.children.find(x => x.id === 'viewData');
          if (viewInfoAllData) {
            newViewData.view = viewInfoAllData.view;
            newViewData.edit = viewInfoAllData.edit;
            newViewData.auth = viewInfoAllData.auth;
            newViewData.delete = viewInfoAllData.delete;
            newViewData.enable = viewInfoAllData.enable;
          }
        }
        row.children.push(newViewData)

        
        if (this.value && Array.isArray(this.value)) {
          let formId = row.id;
          let formInfo = this.value.find(x=>x.id === formId);
          if(!formInfo) { return; }
          
          let updateValue = formInfo.children.find(x=> x.id ==='viewData')

          if(updateValue){
            newViewData.view = updateValue.view;
            newViewData.edit = updateValue.edit;
            newViewData.auth = updateValue.auth;
            newViewData.delete = updateValue.delete;
            newViewData.enable = updateValue.enable;
          }
        }


        listViews(row.id).then(res => {
          res.data.forEach(view => {
            view.isView = true;
            const rowViewData = {
              name: view.name,
              id: view.id,
              delete: false,
              auth: false,
              edit: false,
              enable: false,
              view: false,
              editableFiles: view.editableFiles,
            }
            if (this.formList && Array.isArray(this.formList)) {
              let formInfo = this.formList.find(x => x.id === row.id);
              let viewInfo = formInfo.children.find(x => x.id === view.id);
              rowViewData.view = viewInfo.view;
              rowViewData.edit = viewInfo.edit;
              rowViewData.auth = viewInfo.auth;
              rowViewData.delete = viewInfo.delete;
              rowViewData.enable = viewInfo.enable;
            }

            row.children.push(rowViewData)
          })
        })
        this.$forceUpdate();


      }
    },
    onCancelSaveEditableFields () {
      this.showDrawer = false
    },
    onConfirmEditableFields () {
      this.editView.editableFiles = this.viewShowFields;
      this.showDrawer = false

    },
  },
  mounted () {
  },
  watch: {
    formList: {
      deep: true,
      handler (val) {
        this.$emit("input", val);
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .table-cell-right {
  text-align: right;
  .el-checkbox {
    display: inline-block;
  }
}
::v-deep .table-cell-left {
  text-align: left;
  .el-checkbox {
    display: inline-block;
  }
}
::v-deep .table-cell-center {
  text-align: center;
  .el-checkbox {
    display: inline-block;
  }
  &.project {
    display: none;
  }
}

::v-deep .hide-expend .el-table__expand-icon {
  display: none;
}

.drawer-content {
  padding: 20px;
  height: 100%;
}

.drawer-footer {
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  padding: 5px 10px;
  display: flex;
  justify-content: flex-end;
  .el-button {
    margin: 0px 5px;
  }
}
</style>