var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticStyle: { width: "100%" } }, [
    _vm.isExpand
      ? _c(
          "div",
          {
            staticStyle: { width: "100%" },
            style: { textAlign: _vm.isExpand ? "" : "center" },
          },
          [
            _c(
              "div",
              {
                staticClass: "form-group-header",
                on: {
                  click: function ($event) {
                    return _vm.page.onFormClick(
                      _vm.item,
                      _vm.cur,
                      _vm.formGroupCur
                    )
                  },
                  mouseenter: function ($event) {
                    _vm.showSettingIcon = true
                  },
                  mouseleave: function ($event) {
                    _vm.showSettingIcon = false
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "form-group-header-left" },
                  [_vm._t("icon"), _vm._t("title")],
                  2
                ),
                _c("formActionMenu", {
                  attrs: {
                    type: _vm.type,
                    item: _vm.item,
                    page: _vm.page,
                    projectDetail: _vm.projectDetail,
                    loginUserId: _vm.loginUserId,
                    showSettingIcon: _vm.showSettingIcon,
                    isExpand: _vm.isExpand,
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _c("div", { staticClass: "form-group-shrink" }, [
          _c(
            "div",
            {
              staticClass: "form-name-wrapper form-name-group-wrapper",
              on: {
                click: function ($event) {
                  return _vm.page.onFormClick(
                    _vm.item,
                    _vm.cur,
                    _vm.formGroupCur
                  )
                },
              },
            },
            [_vm._t("title")],
            2
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }