<template>
  <div>
    <div ref="chartsDOM" style="width: 100%;height: 505px;"></div>
  </div>
</template>
<script>
  import * as echarts from "echarts";
  import china from "./china.json";
  import "echarts/theme/macarons.js";

  export default {
    name: "geoMapChart",
    props: {
      data: {
        type: Array,
        default: undefined,
      },
    },
    data() {
      return {};
    },
    created() {},
    mounted() {
      this.initChart();
      //监听页面变化。
      window.addEventListener("resize", this.resizeHandler, false);
    },
    methods: {
      initChart() {
        // 初始化统计图对象
        var myChart = echarts.init(this.$refs["chartsDOM"], "macarons");
        // 显示 loading 动画
        myChart.showLoading();
        // 得到结果后，关闭动画
        myChart.hideLoading();
        // 注册地图(数据放在axios返回对象的data中哦)
        echarts.registerMap("China", china);

        var option = {
          title: {
            text: "全国普通高校统计",
          },
          series: [
            {
              name: "中国地图",
              type: "map",
              zoom: 1.5, //缩放比例
              roam: true,
              layoutCenter: ["50%", "60%"],
              // 如果宽高比大于 1 则宽度为 100，如果小于 1 则高度为 100，保证了不超过 100x100 的区域
              layoutSize: "80%",
              map: "China", // 这个是上面注册时的名字哦，registerMap（'这个名字保持一致'）
              label: {
                show: true,
              },
              data: this.data,
            },
          ],
          tooltip: {}, // 配置提示框，有这个配置项即可
          visualMap: [
            {
              type: "continuous",
              min: 1,
              max: 1000,
              realtime: false,
              calculable: true,
              inRange: {
                color: ["#B8E1FF", "#7DAAFF", "#3D76DD", "#0047A5", "#001D70"],
              },
            },
          ],
        };
        myChart.setOption(option);
      },
      resizeHandler() {
        this.myChart.resize();
      },
    },
    destroyed() {
      window.removeEventListener("resize", this.resizeHandler, false);
    },
  };
</script>
<style lang="scss" scoped>
  .chart-province {
  }
</style>
