<template>
  <div class="form-info-content" style="width:100%">
    <div style="padding:20px 20px 0 20px;background-color:white;border-radius:4px;">
      <el-row :gutter="20" class="el-row" type="flex" style="margin: 0; padding-bottom: 20px;">
        <el-col :span="4" :xs="24" style="padding: 0; width: 200px; height: 120px;">
          <div class="project-detail-img">
            <img :src="projectDetail.cover" v-if="projectDetail && projectDetail.cover" />
          </div>
        </el-col>
        <el-col :span="8" :xs="24" style="padding: 0 0 0 20px; position: relative; box-sizing: border-box; flex: 1; -webkit-flex: 1;">
          <div class="project-detail-info">
            <h1 class="clearfix project-detail-title">
              <span class="title-text">{{ projectDetail.name }}</span>
            </h1>
            <div class="project-detail-top-bottom">
              <div class="clearfix project-detail-date">
                <p class="create-date">
                  <span style="width: 70px; display: inline-block;">创建时间</span>
                  <span style="margin: 0 10px">:</span>
                  <span>{{ projectDetail.createTime }}</span>
                </p>
              </div>
              <div class="clearfix project-detail-date">
                <p class="create-date">
                  <span style="width: 70px; display: inline-block;">类<i style="opacity: 0;">类型</i>型</span>
                  <span style="margin: 0 10px">:</span>
                  <span>应用</span>
                </p>
              </div>
              <div class="project-detail-creater">
                <p class="creater">
                  <span style="width: 70px; display: inline-block;">
                    <i style="font-style: normal">创</i>
                    <i style="font-style: normal;margin: 0 6px;">建</i>
                    <i style="font-style: normal">人</i>
                  </span>
                  <span style="margin: 0 10px">:</span>
                  <span>{{ projectDetail.createName }}</span>
                  <img src="https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png" class="is-agency-icon" v-if="projectDetail.agencyId" />
                </p>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="text-align: right;">
            <el-button size="mini" class="collect-btn" @click="onCollectClick" :class="{ active: projectDetail.favorite }">
              <svg-icon class="btn-icon" :icon-class="
                                projectDetail.favorite
                                  ? 'formListCollect'
                                  : 'formListUnCollect'
                              " />
              <span>{{
                              projectDetail.favorite ? "已收藏" : "收藏"
                            }}</span>
            </el-button>
            <el-button size="mini" class="btn" @click="onShareProjectClick">
              <svg-icon class="btn-icon" icon-class="formListShare" />
              <span>分享</span>
            </el-button>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="form-info-tab-bottom">
      <div class="tab-content-nav" style="display:block">
        <div class="content-nav-title" @click="appInfoCur = 1" :class="[appInfoCur == 1 ? 'active' : '']">
          <svg-icon icon-class="formDetailInfo" />应用介绍
        </div>
        <div class="content-nav-title" @click="appInfoCur = 2" :class="[appInfoCur == 2 ? 'active' : '']">
          <svg-icon icon-class="formDetailFile" />相关附件
        </div>
        <!-- <div style="float: right;">
          <span @click="toggleAdvanced">{{ advanced ? '收起' : '展开' }} <i :class="advanced ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"></i></span>
        </div> -->
      </div>
      <div style="padding:10px 0">
        <div class="info-tab-content" v-if="appInfoCur === 1">
          <el-row class="el-row" v-if="projectDetail && projectDetail.details != ''">
            <div class="project-intro-con" :style1="{height: advanced ? '100%' : '200px'}" vhtml="projectDetail.details"><pre v-html="projectDetail.details"></pre></div>
          </el-row>
          <el-row v-else>
            <div :style="`margin: 0px;padding: 20px 20px 10px 15px;${projectDetail.userId === loginUserId?'height:340px;padding-top:0px;margin-top:0px;':'height:calc(100vh - 352px);margin-top:20px;'};background-color: white;border-radius: 4px;box-sizing: border-box;`">
              <el-empty :image-size="200" description="暂无内容"></el-empty>
            </div>
          </el-row>
        </div>
        <div class="info-tab-content" v-if="appInfoCur === 2">
          <div :style="`margin: 0px;padding: 0px;${projectDetail.userId === loginUserId?'height:340px;':'height:calc(100vh - 342px);'};background-color: white;border-radius: 4px;box-sizing: border-box;`">
          <Download :fileList="projectDetail.fileList"></Download>
        </div>
          <!-- <div class="details-file">
            <a class="file-wrapper" :href="item.url" target="_blank" v-for="(item,index) in projectDetail.fileList" click="onDownloadFormFileClick(item)" :key="index">
              <svg-icon v-if="item.suffixType === 'image'" icon-class="annex_image" style="width: 22px; height: 22px;vertical-align: middle;" />
              <svg-icon v-if="item.suffixType === 'word'" icon-class="annex_word" style="width: 22px; height: 22px;vertical-align: middle;" />
              <svg-icon v-if="item.suffixType === 'excel'" icon-class="annex_excel" style="width: 22px; height: 22px;vertical-align: middle;" />
              <svg-icon v-if="item.suffixType === 'pdf'" icon-class="annex_pdf" style="width: 22px; height: 22px;vertical-align: middle;" />
              <svg-icon v-if="item.suffixType === 'audio'" icon-class="annex_audio" style="width: 22px; height: 22px;vertical-align: middle;" />
              <svg-icon v-if="item.suffixType === 'video'" icon-class="annex_video" style="width: 22px; height: 22px;vertical-align: middle;" />
              <svg-icon v-if="item.suffixType === 'zip'" icon-class="annex_zip" style="width: 22px; height: 22px;vertical-align: middle;" />
              <svg-icon v-if="item.suffixType === 'ppt'" icon-class="annex_ppt" style="width: 22px; height: 22px;vertical-align: middle;" />
              <svg-icon v-if="item.suffixType === 'other'" icon-class="annex_other" style="width: 22px; height: 22px;vertical-align: middle;" />
              <svg-icon class="down-icon" icon-class="download2" />
              <span>{{ item.name }}</span>
            </a>
          </div> -->
        </div>
      </div>
    </div>
    <div style="margin-top: 20px;" v-if="projectDetail.userId === loginUserId">
      <div v-if="projectDetail.projectId === '1695831421140074496'">
        <el-row :gutter="20">
          <el-col :span="16">
            <el-card class="box-card" shadow="hover" style="border:none;">
              <div slot="header" class="clearfix">
                <span>统计<span style="font-size: 12px;color: rgba(0, 0, 0, 0.45);">（下方内容仅应用创建者可见，实时统计）</span></span>
                <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
              </div>
              <div class="extra-content">
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="投资融资"></svg-icon> 已填报人数</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.fillUserNum }}</div>
                </div>
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="定制"></svg-icon> 提交数据总量</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.totalFillNum }}</div>
                </div>
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="记事本"></svg-icon> 今日新增</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.todayFillNum }}</div>
                </div>
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="卡包"></svg-icon> 本周新增</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.weekFillNum }}</div>
                </div>
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="审核"></svg-icon> 本月新增</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.monthFillNum }}</div>
                </div>
              </div>
            </el-card>
            <el-card class="box-card" shadow="hover" style="border:none;margin-top: 20px;">
              <div slot="header" class="clearfix">
                <span>图表展示 <span style="font-size: 12px;color: rgba(0, 0, 0, 0.45);">（此区域可根据学校需求自定义设置，未设置则只显示下方样例图表，有统计需求请直接联系我们）</span></span>
                <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
              </div>
              <div>
                <el-row :gutter="10">
                  <el-col :span="12">
                    <div>
                      <pie></pie>
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div>
                      <bar></bar>
                    </div>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24" style="margin-top: 20px;">
                    <wordcloud></wordcloud>
                  </el-col>
                </el-row>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card" shadow="hover" style="border:none">
              <div slot="header" class="clearfix">
                <span>文件统计</span>
                <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
              </div>
              <div>
                <el-row :gutter="40" class="extra-content">
                  <el-col :span="12">
                    <div>
                      <div class="ant-statistic-title"><svg-icon icon-class="档案"></svg-icon> 文件数量</div>
                      <div class="ant-statistic-content">{{ this.projectStatistics.fileNum }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12" style="border-left: 1px solid #e8e8e8;">
                    <div>
                      <div class="ant-statistic-title"><svg-icon icon-class="文件资料"></svg-icon> 文件大小（GB）</div>
                      <div class="ant-statistic-content">{{ this.projectStatistics.fileSize }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-card>
            <el-card class="box-card" shadow="hover" style="border:none;margin-top: 20px;" :body-style="{padding: '0px'}">
              <div>
                <svg-icon icon-class="数据分析" style="width: 100%;height: 200px;"></svg-icon>
              </div>
            </el-card>
            <el-card class="box-card" shadow="hover" style="border:none;margin-top: 20px;" :body-style="{padding: '0px 5px'}">
              <div slot="header" class="clearfix">
                <span>报表展示</span>
                <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
              </div>
              <div>
                <div class="ant-list">
                  <ul class="ant-list-items">
                    <li class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="agGridDialogVisible = true">（一）人文、社会科学活动人员情况表
                          </div>
                          <div class="ant-list-item-meta-description">
                            各种统计类报表
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="表格" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="agGridDialogVisible = true">（二）人文、社会科学R＆D人员情况表</div>
                          <div class="ant-list-item-meta-description">
                            实现更多的功能
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="agGridDialogVisible = true">（三）人文、社会科学R＆D经费情况表</div>
                          <div class="ant-list-item-meta-description">
                            实现各种统计报表的展示
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="agGridDialogVisible = true">（四）人文、社会科学研究机构一览表</div>
                          <div class="ant-list-item-meta-description">
                            实现各种统计报表的展示
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="agGridDialogVisible = true">（五）人文、社会科学R＆D课题情况表1</div>
                          <div class="ant-list-item-meta-description">
                            实现各种统计报表的展示
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="agGridDialogVisible = true">（六）人文、社会科学R＆D课题情况表2</div>
                          <div class="ant-list-item-meta-description">
                            实现各种统计报表的展示
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
      <div v-else>
        <el-row :gutter="20">
          <el-col :span="16">
            <el-card class="box-card" shadow="hover" style="border:none;">
              <div slot="header" class="clearfix">
                <span>统计<span style="font-size: 12px;color: rgba(0, 0, 0, 0.45);">（下方内容仅应用创建者可见，实时统计）</span></span>
                <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
              </div>
              <div class="extra-content">
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="投资融资"></svg-icon> 已填报人数</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.fillUserNum }}</div>
                </div>
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="定制"></svg-icon> 提交数据总量</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.totalFillNum }}</div>
                </div>
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="记事本"></svg-icon> 今日新增</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.todayFillNum }}</div>
                </div>
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="卡包"></svg-icon> 本周新增</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.weekFillNum }}</div>
                </div>
                <div class="stat-item">
                  <div class="ant-statistic-title"><svg-icon icon-class="审核"></svg-icon> 本月新增</div>
                  <div class="ant-statistic-content">{{ this.projectStatistics.monthFillNum }}</div>
                </div>
              </div>
            </el-card>
            <el-card class="box-card" shadow="hover" style="border:none;margin-top: 20px;">
              <div slot="header" class="clearfix">
                <span>图表展示 <span style="font-size: 12px;color: rgba(0, 0, 0, 0.45);">（此区域可根据学校需求自定义设置，未设置则只显示下方样例图表，有统计需求请直接联系我们）</span></span>
                <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
              </div>
              <div>
                <chart></chart>
                <el-divider></el-divider>
                <geo-map-chart :data="collegesData"></geo-map-chart>
              </div>
            </el-card>
          </el-col>
          <el-col :span="8">
            <el-card class="box-card" shadow="hover" style="border:none">
              <div slot="header" class="clearfix">
                <span>文件统计</span>
                <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
              </div>
              <div>
                <el-row :gutter="40" class="extra-content">
                  <el-col :span="12">
                    <div>
                      <div class="ant-statistic-title"><svg-icon icon-class="档案"></svg-icon> 文件数量</div>
                      <div class="ant-statistic-content">{{ this.projectStatistics.fileNum }}</div>
                    </div>
                  </el-col>
                  <el-col :span="12" style="border-left: 1px solid #e8e8e8;">
                    <div>
                      <div class="ant-statistic-title"><svg-icon icon-class="文件资料"></svg-icon> 文件大小（GB）</div>
                      <div class="ant-statistic-content">{{ this.projectStatistics.fileSize }}</div>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-card>
            <el-card class="box-card" shadow="hover" style="border:none;margin-top: 20px;" :body-style="{padding: '0px'}">
              <div>
                <svg-icon icon-class="数据分析" style="width: 100%;height: 200px;"></svg-icon>
              </div>
            </el-card>
            <el-card class="box-card" shadow="hover" style="border:none;margin-top: 20px;" :body-style="{padding: '0px'}">
              <div slot="header" class="clearfix">
                <span>项目申报统计</span>
              </div>
              <pie :height="'200px'"></pie>
            </el-card>
            <el-card class="box-card" shadow="hover" style="border:none;margin-top: 20px;" :body-style="{padding: '0px'}">
              <div slot="header" class="clearfix">
                <span>项目词云统计</span>
              </div>
              <wordcloud :height="'280px'" :sizeRange="[12, 30]"></wordcloud>
            </el-card>
            <el-card class="box-card" shadow="hover" style="border:none;margin-top: 20px;" :body-style="{padding: '0px 5px'}">
              <div slot="header" class="clearfix">
                <span>报表展示</span>
                <el-button style="float: right; padding: 3px 0" type="text">更多</el-button>
              </div>
              <div>
                <div class="ant-list">
                  <ul class="ant-list-items" v-if="reportList.length > 0">
                    <li class="ant-list-item" v-for="item in reportList" :key="item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon :icon-class="item.icon ? item.icon : '数据'" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="getReportAgGridData(item.url)">{{ item.name }}
                          </div>
                          <div class="ant-list-item-meta-description">
                            {{ item.description }}
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul v-else class="ant-list-items">
                    <li v-if="projectDetail.projectId === '1722176593545134080'" class="ant-list-item">
                      <!-- <li class="ant-list-item"> -->
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="loadReportData('1722176593545134080_综合测评')">综合测评
                          </div>
                          <div class="ant-list-item-meta-description">
                            各学院总体状态统计表
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-if="projectDetail.projectId === '1722176593545134080'" class="ant-list-item">
                      <!-- <li class="ant-list-item"> -->
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="loadReportData('1722176593545134080_各学院总体状态统计表')">各学院总体状态统计表
                          </div>
                          <div class="ant-list-item-meta-description">
                            各学院总体状态统计表
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-if="projectDetail.projectId === '1719614615022731264'" class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="loadReportData('1719745623990730752_北方工业大学大创项目预算及绩效申报统计','1719745623990730752')">北方工业大学大创项目预算及绩效申报统计
                          </div>
                          <div class="ant-list-item-meta-description">
                            北方工业大学大创项目预算及绩效申报统计
                          </div>
                        </div>
                      </div>
                    </li>
                    <li v-if="projectDetail.projectId === '1719614615022731264'" class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="loadReportData('1719614615022731264_北方工业大学大学生创新创业训练计划项目研究成果汇总表','1719748254993743872')">大学生创新创业训练计划项目研究成果汇总表
                          </div>
                          <div class="ant-list-item-meta-description">
                            大学生创新创业训练计划项目研究成果汇总表
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="agGridDialogVisible = true">专家评审汇总表1
                          </div>
                          <div class="ant-list-item-meta-description">
                            各种统计类报表
                          </div>
                        </div>
                      </div>
                    </li>
                    <li class="ant-list-item">
                      <div class="ant-list-item-meta">
                        <div class="ant-list-item-meta-avatar">
                          <svg-icon icon-class="数据" style="width: 30px;height: 30px;"></svg-icon>
                        </div>
                        <div class="ant-list-item-meta-content">
                          <div class="ant-list-item-meta-title" @click="agGridDialogVisible = true">专家评审汇总表2
                          </div>
                          <div class="ant-list-item-meta-description">
                            各种统计类报表
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
      </div>
    </div>

    <el-dialog :title="'这是xxx报表'" :visible.sync="agGridDialogVisible" width="80%" :destroy-on-close="true">
      <ag-grid-table :columnDefs="columnDefs" :rowData="rowData" :autoSizeStrategy="autoSizeStrategy" :defaultExcelExportParams="defaultExcelExportParams" height="800px"></ag-grid-table>
    </el-dialog>
  </div>
</template>

<script>

import Wordcloud from "../statistics/charts/wordcloud.vue";
import GeoMapChart from "../statistics/charts/geoMapChart";
import Chart from "../statistics/charts/chart.vue";
import Pie from "../statistics/charts/pie.vue";
import Bar from "../statistics/charts/bar.vue";
import {
  getRowDataByGbd,
  getRowDataByGbdYsjx
} from "@/api/system/statistics";
import {
  agGridColumnDefs_bgd,
  agGridColumnDefs_bgd_ysjx
} from "./statisAgGridFlieds"; // 引入表头配置
import AgGridTable from "@/components/AgGridTable";
import { getFinalColumnDefs } from "@/utils/agGrid.js";

import Download from '@/components/FileDownload/download.vue'
export default {
  components: {
    AgGridTable,
    Chart,
    GeoMapChart,
    Pie,
    Bar,
    Wordcloud,
    Download
  },
  props: {
    projectDetail: {
      type: Object,
      default: {}
    },
    loginUserId: {
      type: Number
    },
    advanced:{
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      context: {},
      appInfoCur: 1,
      defaultExcelExportParams: {},
      projectStatistics: {
        totalFillNum: 0,
        todayFillNum: 0,
        weekFillNum: 0,
        monthFillNum: 0,
        fileNum: 0,
        fileSize: 0,
        fillUserNum: 0,
      },
      collegesData: [],
      reportList: [],
      agGridDialogVisible: false,
      columnDefs: [],
      rowData: [],
      autoSizeStrategy: null
    };
  },
  methods: {
    onCollectClick () {
      if (this.cur == null) {
        this.$refs.share.favorite(this.projectDetail);
      } else {
        this.$refs.share.favorite(this.formDetail);
      }
    },
    // 新版---导航分享(分享应用)
    onShareProjectClick () {
      this.$parent.$refs.share.showShare(this.projectDetail);
    },

    toggleAdvanced () {
      this.$emit("update:advanced", !this.advanced)
    },

    loadReportData (name, formId) {
      this.agGridDialogVisible = true
      if (name === '1719614615022731264_北方工业大学大学生创新创业训练计划项目研究成果汇总表') {
        this.defaultExcelExportParams = {
          author: "北方工业大学",
          fileName: '北方工业大学大学生创新创业训练计划项目研究成果汇总表',
          sheetName: 'sheet1'
        }
        this.columnDefs = agGridColumnDefs_bgd
        if(this.columnDefs.length > 12){
          this.autoSizeStrategy = {
            type: 'fitCellContents'
          }
        }

        getRowDataByGbd(formId).then((result) => {
          if (result.code === 200) {
            this.rowData = result.data
          }
        }).catch((err) => {});

      }

      if(name === '1719745623990730752_北方工业大学大创项目预算及绩效申报统计'){
        this.defaultExcelExportParams = {
          author: "北方工业大学",
          fileName: '北方工业大学大创项目预算及绩效申报统计',
          sheetName: 'sheet1'
        }
        this.columnDefs = agGridColumnDefs_bgd_ysjx
        if(this.columnDefs.length > 12){
          this.autoSizeStrategy = {
            type: 'fitCellContents'
          }
        }

        getRowDataByGbdYsjx(formId).then((result) => {
          if (result.code === 200) {
            this.rowData = result.data
          }
        }).catch((err) => {});
      }
    },
    
    onDownloadFormFileClick(item) {
      const link = document.createElement("a");
      link.href = item.url;
      link.download = this.getSuffName(item.url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    // 数据查看---上传附件---截取附件名
    getSuffName(url) {
      return !this.isBlank(url)
        ? decodeURIComponent(url).substring(url.indexOf("_") + 1)
        : "";
    },
  }
};
</script>

<style lang="scss" scoped>
.project-detail-info {
  .project-detail-title {
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    color: #404040;
    margin: 0;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-text {
      flex: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .project-detail-title-btn-wrapper {
      width: 170px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: flex-end;
      .btn-wrapper {
        cursor: pointer;
        display: inline-block;
        margin-left: 8px;
        .btn {
          // width: 58px;
          // height: 26px;
          // font-size: 12px;
          // font-weight: 400;
          // color: #646d7a;
          // line-height: 22px;
          // padding: 0;
          // display: flex;
          // flex-direction: row;
          // align-items: center;
          // justify-content: center;
        }
        .collect-btn {
          color: #646d7a;
          border-color: #dbdde7;
          background: transparent;
          &.active {
            color: #f09f35;
            // border-color: #F09F35;
          }
          &:hover {
            border-color: #f09f35;
            background-color: rgba(255, 165, 0, 0.1);
          }
          .collect-icon {
            margin-right: 8px;
            font-size: 14px;
          }
        }
        .btn-icon {
          margin-right: 4px;
          font-size: 14px;
        }
      }
    }
  }
  .project-detail-top-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    .project-detail-date {
      width: 100%;
      font-size: 12px;
      height: 22px;
      line-height: 22px;
      margin: 0;
      color: #a5abb1;
      .create-date {
        margin: 0;
        padding: 0;
      }
    }
    .project-detail-creater {
      width: 100%;
      font-size: 12px;
      height: 22px;
      line-height: 22px;
      margin: 0;
      color: #a5abb1;
      .creater {
        margin: 0;
        padding: 0;
        .is-agency-icon {
          display: inline-block;
          width: 40px;
          height: 16px;
          margin-left: 5px;
          vertical-align: -4px;
        }
      }
    }
  }
}
.project-intro-title {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0;
  .title-span {
    font-size: 14px;
    color: #404040;
    line-height: 20px;
  }
  .title-line {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    -o-flex: 1;
    -moz-flex: 1;
    flex: 1;
    height: 1px;
    background: #e5e5e5;
    margin-left: 20px;
  }
}
.project-intro-con {
  width: 100%;
  font-size: 14px;
  color: #394349;
  word-break: break-all;
  overflow-y: hidden;
  p {
    word-break: break-all;
    white-space: break-spaces;
    line-height: 25px;
  }
}
.project-relate-form {
  width: 100%;
  margin: 0px auto;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;

  .relate-form-left {
    width: 240px;
    margin: 0;
    padding: 0 0;
    flex-shrink: 0;
    box-sizing: border-box;
    height: calc(100vh - 80px);
    position: relative;
    background: #ffffff;
    overflow: hidden;
    border-radius: 4px;
    transition: width 0.3s;
    display: flex;
    flex-direction: column;

    /* css滚动条美化，宽度为2px */
    ::-webkit-scrollbar {
      width: 4px;
    }

    ::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }

    &.shrink {
      // background-color: #fafafa;
    }

    .menu-tool {
      width: 100%;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 15px;
      box-sizing: border-box;
      .nameSearch {
        flex: 1;
        -webkit-flex: 1;
        transition: width 2s;
      }
      &.menu-title {
        font-size: 16px;
        font-weight: bold;
        display: inline-flex;
        justify-content: space-between;
        border-bottom: solid 1px #eff1f8;

        .menu-title-text {
          width: 80px;
          height: 20px;
          line-height: 20px;
          display: inline-block;
          overflow: hidden;
          position: relative;
          padding-left: 10px;
          color: #232425;
          &::after {
            content: '';
            position: absolute;
            width: 4px;
            height: 15px;
            top: 50%;
            left: 0;
            margin-top: -7.5px;
            background: #3476f0;
            border-radius: 0 3px 3px 0;
          }
        }
        .menu-title-action {
          height: 20px;
          line-height: 20px;
          display: flex;

          &-icon {
            font-size: 18px;
            margin-left: 10px;
            cursor: pointer;
            color: #646d7a;
          }
        }
      }
    }
    .relate-form-list-title {
      height: 40px;
      line-height: 40px;
      margin: 0 0 10px;
      padding: 0;
      background: #f0f2f5;
      font-size: 14px;
      color: #404040;
      text-align: center;
    }
    .dragItem {
      // width: 210px;
      cursor: pointer;
      height: 40px;
      // line-height: 40px;
      list-style: none;
      color: #1f2d3d;
      font-size: 14px;
      font-weight: 400;
      margin: 5px 15px 5px 15px;
      box-sizing: border-box;
      display: flex;
      display: -webkit-flex;
      position: relative;
      border-radius: 4px;
      .form-icon {
        width: 15px !important;
        height: 15px !important;
        line-height: 15px !important;
        font-size: 15px !important;
        margin-left: 10px;
        margin-right: 8px;
        display: inline-block !important;
        flex-shrink: 0;
        background-size: cover;
        background-position: center center;
      }
      .element-form-icon {
        width: 15px;
        height: 15px;
        line-height: 15px;
        margin-left: 10px;
        margin-right: 8px;
        color: #c5c6c7;
        cursor: move;
        color: #646d7a;
        font-size: 15px;
      }
      .form-name {
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 10px;
        width: 238px;
      }
      &.active {
        background: rgba(0, 92, 238, 0.08);
        color: #3476f0;
        position: relative;
        .form-name {
          font-weight: 500;
        }
        .form-icon {
          color: #3476f0 !important;
        }
        &::after {
          content: '';
          position: absolute;
          width: 4px;
          height: 30px;
          top: 50%;
          left: 0;
          margin-top: -15px;
          background: rgba(0, 92, 238, 0.4);
          border-radius: 0 3px 3px 0;
        }
      }
      &:hover {
        background: rgba(0, 92, 238, 0.08);
        color: #3476f0;
        .form-more-icon {
          .el-dropdown-link {
            display: block;
          }
        }
      }
      &:hover .form-icon {
        color: #3476f0 !important;
      }
      &:last-child {
        // margin-bottom: 0;
      }
    }

    .more-dialog {
      position: absolute;
      right: -45px;
      min-width: 140px;
      max-width: 140px;
      background: #fff;
      padding: 5px;
      -webkit-box-shadow: 0 1px 10px 0 rgba(226, 226, 226, 0.5);
      box-shadow: 0 1px 10px 0 rgba(226, 226, 226, 0.5);
      -webkit-transition: 0.3s all ease;
      transition: 0.3s all ease;
      cursor: pointer;
      z-index: 101;
      margin: 0 0 0 -70px;
      list-style: none;
      .x-menu-item {
        color: #1f2d3d;
        font-size: 14px;
        text-align: center;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background: #e8f4ff;
          border-radius: 3px;
        }
      }
      &.offsetBottom {
        bottom: 40px;
      }
      &.offsetTop {
        top: 40px;
      }
    }

    &.shrink {
      width: 68px;
      transition: width 0.3s;
      .nameSearch {
        display: none;
      }
      .svg-icon-form {
        display: none;
      }
      .form-name {
        margin-left: 0px;
        height: 40px;
        cursor: pointer;
      }
      .form-name-wrapper {
        // width: 30px;
        // height: 30px;
        // line-height: 30px;
        // overflow: hidden;
        // text-overflow: unset;
        // letter-spacing: 20px;
        // background-color: #ffc678;
        // text-align: center;
        // padding-left: 0px;
        // margin-left: 0px;
        // text-indent: 7px;
        // font-size: 16px;
        // color: #fff;
        // border-radius: 5px;
        // margin-top:5px;
        // display: inline-block;
        // transition: 0.3s;
      }

      .form-name-wrapper-shrink {
        transition: 0.3s;
        padding-left: 0px;
      }
      // .form-more-icon{
      //     display:none!important;
      // }

      .form-group-shrink {
        background-color: #f3f4f5;
        border-bottom: solid 1px #e5e6e7;
      }

      .shrink-icon {
        svg {
          transform: rotate(0deg);
        }
      }
    }

    .shrink-icon {
      width: 15px;
      height: 40px;
      line-height: 36px;
      margin-top: -10px;
      position: absolute;
      right: -15px;
      top: 15px;
      padding-top: 1px;
      background-color: #fafafa;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-align: center;
      border: solid 1px #f3f4f5;
      border-left: none 0px;
      border-radius: 0px 4px 4px 0px;
      cursor: pointer;
      z-index: 100;
      box-shadow: 2 2px 2px 0px rgba(51, 45, 64, 0.1);
      svg {
        margin-left: -2px;
        transition: 0.3s;
        transform: rotate(180deg);
      }

      &:hover {
        background-color: #f3f3f3;
      }
    }
  }
  .relate-form-middle {
    width: 16px;
    flex-shrink: 0;

    .shrink-icon {
      width: 15px;
      height: 40px;
      line-height: 36px;
      padding-top: 1px;
      background-color: #f4f8fe;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      text-align: center;
      border: solid 1px #f3f4f5;
      border-left: none 0px;
      border-radius: 0px 4px 4px 0px;
      cursor: pointer;
      z-index: 100;
      -webkit-box-shadow: 2 2px 2px 0px rgba(51, 45, 64, 0.1);
      box-shadow: 2 2px 2px 0px rgba(51, 45, 64, 0.1);

      &:hover {
        background-color: #efefef;
      }

      svg {
        width: 14px;
        margin: 0px;
        transition: 0.3s;
        transform: rotate(180deg);
      }
    }

    &.shrink {
      transition: width 0.3s;

      .shrink-icon {
        svg {
          transform: rotate(0deg);
        }
      }
    }
  }
  .relate-form-right {
    width: auto;
    height: 100%;

    overflow-y: auto;
    overflow-x: hidden;
    flex-grow: 1;
    flex-shrink: 1;
    // min-width: calc(100% - 300px);
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // min-height: 500px;
    // height: max-content;
    border-radius: 4px;
    transition: width 0.3s;

    &.shrink {
      width: calc(100% - 68px);
      // min-width: calc(100% - 68px);
    }

    /* css滚动条美化，宽度为2px */
    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: #f5f5f5;
    }
  }
}
.form-info-tab-bottom {
  // height: max-content;
  margin: 20px auto;
  padding: 0px 15px;
  background-color: white;
  border-radius: 4px;
  border-top: 1px solid rgb(239, 241, 248);
}

.info-tab-content {
  min-height: 100px;
}
//应用信息的切换
.tab-content-nav {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid #f2f3f4;
  // padding: 0 20px;
  box-sizing: border-box;
  cursor: pointer;

  .content-nav-title {
    color: #333;
    font-size: 15px;
    line-height: 20px;
    // font-weight: 500;
    margin-right: 20px;
    display: inline-block;
    position: relative;
    &.active {
      line-height: 48px;
      color: #3476f0;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 4px;
        bottom: 0;
        left: 0;
        background: #3476f0;
        border-radius: 3px 3px 0 0;
      }
    }
    svg {
      // font-size: 18px;
      margin-right: 4px;
    }
  }
}
.details-file {
  width: 100%;
  padding: 0 20px;
  box-sizing: border-box;
  margin-top: 10px;
  .file-wrapper {
    position: relative;
    width: 752px;
    height: 40px;
    cursor: pointer;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 10px;
    color: #646d7a;
    display: flex;
    align-items: center;
    span {
      margin-left: 8px;
    }
    .down-icon {
      font-size: 16px;
      color: #3476f0;
      font-weight: bold;
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -8px;
      display: none;
    }
    &:hover {
      background: rgba(0, 92, 238, 0.07);
    }
    &:hover .down-icon {
      display: block;
    }
  }
}

.extra-content {
  // float: right;
  white-space: nowrap;

  .stat-item {
    position: relative;
    display: inline-block;
    padding: 0 20px;
    width: 20%;
    &:after {
      position: absolute;
      top: 8px;
      right: 0;
      width: 1px;
      height: 40px;
      background-color: #e8e8e8;
      content: '';
    }
  }
  .stat-item:last-child {
    &:after {
      width: 0px;
    }
  }

  .ant-statistic {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5;
    list-style: none;
    -webkit-font-feature-settings: 'tnum';
    font-feature-settings: 'tnum';
  }
  .ant-statistic-title {
    margin-bottom: 10px;
    color: rgba(0, 0, 0, 0.45);
    font-size: 14px;
    text-align: right;
  }
  .ant-statistic-content {
    color: rgba(0, 0, 0, 0.85);
    font-size: 22px;
    text-align: right;
  }
}

.ant-list {
  .ant-list-items {
    margin: 0;
    padding: 0;
    list-style: none;

    .ant-list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 24px;
      color: rgba(0, 0, 0, 0.88);
      border-block-end: 1px solid rgba(5, 5, 5, 0.06);

      .ant-list-item-meta {
        display: flex;
        flex: 1;
        align-items: flex-start;
        max-width: 100%;
        .ant-list-item-meta-avatar {
          margin-inline-end: 16px;
        }
        .ant-list-item-meta-content {
          flex: 1 0;
          width: 0;
          color: rgba(0, 0, 0, 0.88);
        }

        .ant-list-item-meta-title {
          margin-bottom: 4px;
          color: rgba(0, 0, 0, 0.88);
          font-size: 14px;
          line-height: 1.5714285714285714;
          // color: #3476f0;
          font-weight: bold;
          cursor: pointer;
        }

        .ant-list-item-meta-description {
          color: rgba(0, 0, 0, 0.45);
          font-size: 14px;
          line-height: 1.5714285714285714;
        }
      }
    }
  }
}
</style>
