var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "组内用户", visible: _vm.visible },
      on: { close: _vm.closeDialog },
    },
    [
      _c(
        "div",
        { staticStyle: { "margin-bottom": "15px" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "搜索用户", clearable: "" },
                    on: { clear: _vm.clearSearch },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 12 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.openUserDialog },
                    },
                    [_vm._v("添加用户")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "el-table",
            { attrs: { data: _vm.filteredUsers } },
            [
              _c("el-table-column", {
                attrs: { prop: "name", label: "用户名" },
              }),
              _c("el-table-column", {
                attrs: { label: "类型" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var row = ref.row
                      return [
                        row.type === "member"
                          ? _c("div", [_vm._v("用户")])
                          : _vm._e(),
                        row.type === "dept"
                          ? _c("div", [_vm._v("部门")])
                          : _vm._e(),
                        row.type === "role"
                          ? _c("div", [_vm._v("角色")])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { label: "操作", width: "190", align: "center" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            staticStyle: { "margin-right": "10px" },
                            attrs: { type: "text" },
                            on: {
                              click: function ($event) {
                                return _vm.removeUser(scope.row)
                              },
                            },
                          },
                          [_vm._v("移除")]
                        ),
                        _c(
                          "el-popover",
                          {
                            attrs: {
                              placement: "bottom-end",
                              title: "移动到分组",
                              width: "200",
                              trigger: "click",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "reference",
                                  fn: function () {
                                    return [
                                      _c(
                                        "el-button",
                                        { attrs: { type: "text" } },
                                        [_vm._v("移动到其他组")]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: { size: "small", placeholder: "请选择" },
                                model: {
                                  value: _vm.moveToUserGroup,
                                  callback: function ($$v) {
                                    _vm.moveToUserGroup = $$v
                                  },
                                  expression: "moveToUserGroup",
                                },
                              },
                              _vm._l(_vm.userGroupList, function (item, index) {
                                return _c("el-option", {
                                  key: index,
                                  attrs: {
                                    disabled: item.id === _vm.userGroupId,
                                    label: item.groupName,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticStyle: {
                                  "text-align": "right",
                                  "margin-top": "8px",
                                },
                              },
                              [
                                _vm._v(
                                  " 当前：" +
                                    _vm._s(_vm.userGroupId) +
                                    " 移动到: " +
                                    _vm._s(_vm.moveToUserGroup) +
                                    " "
                                ),
                                _c(
                                  "el-button",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.handleMoveUser(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("确定")]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.closeDialog } }, [_vm._v("关闭")])],
        1
      ),
      _c("UserSelect", {
        ref: "userSelect",
        attrs: {
          agencyId: _vm.agencyId,
          listUserType: "2",
          showType: [1, 2, 3],
        },
        model: {
          value: _vm.userList,
          callback: function ($$v) {
            _vm.userList = $$v
          },
          expression: "userList",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }