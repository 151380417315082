var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isExpand
    ? _c(
        "div",
        {
          staticClass: "content-menu",
          on: {
            mouseenter: _vm.showMenuHandler,
            mouseleave: function ($event) {
              _vm.showMenu = false
            },
            click: function ($event) {
              $event.stopPropagation()
            },
          },
        },
        [
          _vm.isAdminShowSettingIcon
            ? _c(
                "div",
                {
                  staticClass: "menu-icon",
                  class: { "show-menu": _vm.showSettingIcon },
                  style: { marginRight: _vm.type == "form-group" ? "5px" : "" },
                },
                [
                  _c("div", {
                    staticClass: "form-icon element-form-icon el-icon-s-tools",
                    staticStyle: { color: "#3476F0", margin: "0 auto" },
                  }),
                  _c(
                    "div",
                    {
                      ref: "menu_dropdown",
                      staticClass: "menu-dropdown",
                      class: {
                        "show-menu": _vm.showMenu,
                        "show-menu-overflow": _vm.showMenuOverflow,
                      },
                      style: _vm.menuPosition,
                    },
                    [
                      !_vm.isFormGroup
                        ? _c(
                            "div",
                            {
                              staticClass: "menu-dropdown-item",
                              on: {
                                mouseenter: function ($event) {
                                  _vm.showIconSelect = true
                                },
                                mouseleave: function ($event) {
                                  _vm.showIconSelect = false
                                },
                              },
                            },
                            [
                              _c("div", [_vm._v("修改图标")]),
                              _c("formIconSelector", {
                                attrs: {
                                  value: _vm.item.icon,
                                  showIconSelect: _vm.showIconSelect,
                                },
                                on: { "on-selected": _vm.onEditFormIcon },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      !_vm.isFormGroup
                        ? [
                            _c(
                              "div",
                              {
                                staticClass: "menu-dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.page.onEditFormClick(_vm.item)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "menu-dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.page.onCopyFormClick(_vm.item)
                                  },
                                },
                              },
                              [_vm._v("复制")]
                            ),
                            false && !_vm.item.isHide
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "menu-dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.page.onHideFormClick(
                                          _vm.item.formId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("隐藏")]
                                )
                              : _vm._e(),
                            false && _vm.item.isHide
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "menu-dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.page.onShowFormClick(
                                          _vm.item.formId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("显示")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "menu-dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.page.onMoveFormClick(
                                      _vm.item.formId
                                    )
                                  },
                                },
                              },
                              [_vm._v("移动")]
                            ),
                            !_vm.isInFormGroup
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "menu-dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.page.onMoveToGroupFormClick(
                                          _vm.item
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("移到分组")]
                                )
                              : _vm._e(),
                            _vm.isInFormGroup
                              ? _c(
                                  "div",
                                  {
                                    staticClass: "menu-dropdown-item",
                                    on: {
                                      click: function ($event) {
                                        return _vm.page.onMoveOutGroupFormClick(
                                          _vm.item.formId
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("移出分组")]
                                )
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "menu-dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.page.onDeleteFormClick(_vm.item)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ]
                        : [
                            _c(
                              "div",
                              {
                                staticClass: "menu-dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.page.onAddGroupFormClick(
                                      _vm.item
                                    )
                                  },
                                },
                              },
                              [_vm._v("新建表单")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "menu-dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.page.onEditGroupClick(_vm.item)
                                  },
                                },
                              },
                              [_vm._v("编辑")]
                            ),
                            _c(
                              "div",
                              {
                                staticClass: "menu-dropdown-item",
                                on: {
                                  click: function ($event) {
                                    return _vm.page.onDeleteGroupClick(_vm.item)
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            ),
                          ],
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }