<template>
  <div class="navbar">
    <div class="menu" v-for="(menu, index) in formGroupList" :key="index">
      <span class="menu-text">{{ menu.name }}</span>
      <div class="menu-content">
        <div
          class="menu-content-item"
          v-for="(smenu, sindex) in menu.children"
          :key="sindex"
          @click="naviToForm(smenu.formId)"
        >
          <div>{{ smenu.name }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import emitter from "@/utils/emitter";
export default {
  mixins: [emitter],
  props: {
    formGroupList: {
      type: Array,
      default: () => [],
    },
    isLogin:{
      type: Boolean,
      default: () => true,
    }
  },
  methods: {
    naviToForm(formId) {
      if(this.isLogin){
        this.$router.push({
        path: "/form/detail/" + this.encode(formId),
      });
      }else{
        this.$emit('gotoLogin')
      }
      
    },
    mouseEnter(item) {},
    mouseOut(item) {
 
    },
  },
};
</script>
<style lang="scss" scoped>
/* 基本导航栏样式 */
.navbar {
  width:100%;
  display: flex;
  justify-content: center;
  background-color:#e1e1df;
}
.menu {
  height:50px;
  line-height:50px;
  width:200px;
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  cursor: pointer;
}
.menu-text{
  height:50px;
  line-height:50px;
  width:100%;
  display:inline-block;
  text-align: center;
  font-size:16px;
  color:#333;
}

.menu-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  left:50%;
  width: 95%;
  transform: translateX(-50%);
}

.menu:hover .menu-content {
  display: block;
}
.menu-content-item{
  padding: 15px 16px;
  font-size:16px;
  box-sizing: border-box;
  line-height:20px;
}

.menu-content-item:hover {
  background-color:#efefef;
}
</style>
