var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%", "text-align": "center" } },
    [
      _c("WebViewHeader", {
        attrs: { formDesign: _vm.formDesign, isLogin: _vm.isLogin },
        on: { "go-login": _vm.gotoLogin },
      }),
      _c("WebViewBanner", { attrs: { formDesign: _vm.formDesign } }),
      _c("WebViewMenu", {
        attrs: {
          formDesign: _vm.formDesign,
          formGroupList: _vm.formGroupList,
          isLogin: _vm.isLogin,
        },
        on: { gotoLogin: _vm.gotoLogin },
      }),
      _c("WebViewIntroduce", {
        attrs: {
          formDesign: _vm.formDesign,
          fileList: _vm.fileList,
          projectDetail: _vm.projectDetail,
        },
      }),
      _vm._l(_vm.formDesign.extra, function (item, index) {
        return [
          item.type == "richtext"
            ? _c("WebViewRich", {
                directives: [
                  {
                    name: "key",
                    rawName: "v-key",
                    value: index,
                    expression: "index",
                  },
                ],
                attrs: {
                  formDesign: _vm.formDesign,
                  projectDetail: _vm.projectDetail,
                  value: item,
                },
              })
            : _vm._e(),
          item.type == "pictures"
            ? _c("WebViewPicture", {
                directives: [
                  {
                    name: "key",
                    rawName: "v-key",
                    value: index,
                    expression: "index",
                  },
                ],
                attrs: {
                  formDesign: _vm.formDesign,
                  projectDetail: _vm.projectDetail,
                  value: item,
                },
              })
            : _vm._e(),
          item.type == "video"
            ? _c("WebViewVideo", {
                directives: [
                  {
                    name: "key",
                    rawName: "v-key",
                    value: index,
                    expression: "index",
                  },
                ],
                attrs: {
                  formDesign: _vm.formDesign,
                  projectDetail: _vm.projectDetail,
                  value: item,
                },
              })
            : _vm._e(),
        ]
      }),
      _c("WebViewFooter", { attrs: { formDesign: _vm.formDesign } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }