var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: { title: "视图", visible: _vm.visible },
      on: { close: _vm.closeDialog },
    },
    [
      _c(
        "div",
        [
          _c(
            "el-table",
            {
              attrs: {
                data: _vm.groupList,
                size: "small",
                "row-class-name": _vm.isShowExpend,
              },
            },
            [
              _c("el-table-column", {
                attrs: { type: "expand" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (props) {
                      return [
                        _vm._v(" " + _vm._s(props.row.children) + " "),
                        _c(
                          "el-table",
                          {
                            attrs: {
                              data: props.row.children,
                              "show-header": false,
                            },
                          },
                          [
                            _c("el-table-column", {
                              attrs: { label: "必填", width: "80" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c("el-checkbox", {
                                          attrs: {
                                            disabled: scope.row.disabled,
                                          },
                                          model: {
                                            value: scope.row.isCheck,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                scope.row,
                                                "isCheck",
                                                $$v
                                              )
                                            },
                                            expression: "scope.row.isCheck",
                                          },
                                        }),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                            _c("el-table-column", {
                              attrs: { label: "查看", prop: "name" },
                            }),
                            _c("el-table-column", {
                              attrs: { label: "说明", prop: "desc" },
                            }),
                            _c("el-table-column", {
                              attrs: { label: "类型", prop: "type" },
                            }),
                            _c("el-table-column", { attrs: { label: "值" } }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", { attrs: { label: "查看" } }),
              _c("el-table-column", { attrs: { label: "修改" } }),
              _c("el-table-column", { attrs: { label: "删除" } }),
              _c("el-table-column", { attrs: { label: "删除" } }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [_c("el-button", { on: { click: _vm.closeDialog } }, [_vm._v("关闭")])],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }