<template>
  <div>
    <div id="wordcloud-container" :style="{height, width: '100%'}"></div>
  </div>
</template>
<script>
  import * as echarts from "echarts";

  import "echarts-wordcloud/dist/echarts-wordcloud";
  import "echarts-wordcloud/dist/echarts-wordcloud.min";
  export default {
    name: "BarChart",
    props: {
      height: {
        type: String,
        default: '405px'
      },
      sizeRange: {
        type: Array,
        default: [14,40]
      }
    },
    data() {
      return {
        worddata: [
          {
            name: "生物技术",
            value: 15,
          },
          {
            name: "生物育种",
            value: 17,
          },
          {
            name: "绿色环保",
            value: 49,
          },
          {
            name: "千兆光网",
            value: 99,
          },
          {
            name: "生物医学",
            value: 43,
          },
          {
            name: "精准医学",
            value: 57,
          },
          {
            name: "类脑计算",
            value: 76,
          },
          {
            name: "城乡治理",
            value: 87,
          },
          {
            name: "乡村振兴",
            value: 36,
          },
          {
            name: "社会事业",
            value: 33,
          },
          {
            name: "文化传承",
            value: 89,
          },
          {
            name: "泛终端芯片",
            value: 88,
          },
          {
            name: "云计算",
            value: 87,
          },
          {
            name: "人工智能",
            value: 23,
          },
          {
            name: "无人驾驶",
            value: 159,
          },
          {
            name: "新材料",
            value: 999,
          },
          {
            name: "新能源",
            value: 555,
          },
          {
            name: "生物技术",
            value: 333,
          },
          {
            name: "生物育种",
            value: 222,
          },

        ],
      };
    },
    created() {},
    mounted() {
      this.initChart();
    },
    methods: {
      initChart() {
        var myChart = echarts.init(
          document.getElementById("wordcloud-container")
        );

        const option = {
          // title: {
          //   text: "项目名称词云统计",
          //   x: "center",
          // },
          backgroundColor: "#fff",
          // tooltip: {
          //   pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>"
          // },
          series: [
            {
              type: "wordCloud",
              //用来调整词之间的距离
              gridSize: 10,
              //用来调整字的大小范围
              // Text size range which the value in data will be mapped to.
              // Default to have minimum 12px and maximum 60px size.
              sizeRange: this.sizeRange,
              // Text rotation range and step in degree. Text will be rotated randomly in range [-90,                                                                             90] by rotationStep 45
              //用来调整词的旋转方向，，[0,0]--代表着没有角度，也就是词为水平方向，需要设置角度参考注释内容
              // rotationRange: [-45, 0, 45, 90],
              // rotationRange: [ 0,90],
              rotationRange: [-45, 0, 45, 90],
              //随机生成字体颜色
              // maskImage: maskImage,
              textStyle: {
                normal: {
                  color: function () {
                    return (
                      "rgb(" +
                      Math.round(Math.random() * 255) +
                      ", " +
                      Math.round(Math.random() * 255) +
                      ", " +
                      Math.round(Math.random() * 255) +
                      ")"
                    );
                  },
                },
              },
              //位置相关设置
              // Folllowing left/top/width/height/right/bottom are used for positioning the word cloud
              // Default to be put in the center and has 75% x 80% size.
              left: "center",
              top: "center",
              right: null,
              bottom: null,
              width: "100%",
              height: "100%",
              //数据
              data: this.worddata,
            },
          ],
        };

        myChart.setOption(option);
      },
    },
  };
</script>
