<template>
    <el-dialog title="填报地址" :visible.sync="shareOpen" width="1000px" append-to-body @close="handleFillDiaClose">
        <div class="share-container">
            <h5 style="font-size: 20px; color: #404040;  margin: 20px 0 10px;">链接分享</h5>
            <p style="font-size: 12px; color: #909090; margin-bottom: 10px;">将填报地址粘贴到浏览器访问，或者直接发给填报者</p>
            <div class="" style="width: 100%;height: auto; ">
                <el-input type="text" :value="shareFillUrl" readonly class="fl" style="width: 60%; margin-right: 40px"></el-input>
                <p>
                    <el-button type="primary" @click="onCopyFillClick">复制链接</el-button>
                    <el-button type="primary" style="margin-left: 20px;" @click="onToClick">直接打开</el-button>
                </p>
            </div>
            <h5 style="font-size: 20px; color: #404040;  margin: 20px 0 10px;">小程序码分享</h5>
            <p style="font-size: 12px; color: #909090; margin-bottom: 10px;">选择小程序码样式，保存小程序码直接发给填报者</p>
            <img :src="shareImageUrl" class="list-share-image">
            <div style=" width: 398px; margin: auto; text-align: center;">
                <el-button type="primary" @click="onSaveClick" style="margin: 20px auto 0;">保存小程序码</el-button>
            </div>
        </div>

    </el-dialog>
</template>
<script>
import { shareImg,httpShareNum,shortLink } from '@/api/system/form'
import { favorite } from '@/api/system/user'
export default {
  data() {
    return {
      shareOpen: false,
      shareFillUrl: '',
      shareImageUrl: '',
      shareItemInfo: {}
    }
  },
  methods: {
    showShare(shareItem) {
      const locationUrl = window.location.origin
      const shareId = shareItem.projectId ? shareItem.projectId : shareItem.formId
      const shareType = shareItem.projectId ? 'project' : 'form'

      this.shareFillUrl = locationUrl + '/share/' + this.encode(shareId) + '?type=' + this.encode(shareType)

      shortLink(this.shareFillUrl).then(res=>{
        this.shareFillUrl=res.data;
      })
      this.shareItemInfo = shareItem

      shareImg(shareId, shareType).then((res) => {
        if (res.code == 200) {
          this.shareImageUrl = 'data:image/jpg;base64,' + res.data
          this.shareOpen = true
          if(shareType=='form') this.getShareNum();
        }
      })
    },
    // 新版---添加分享量
    async getShareNum() {
      const _this = this
      const formId = _this.formId
      httpShareNum(formId).then((response) => {})
    },
    // 复制填报地址
    async onCopyFillClick() {
      const oInput = document.createElement('input') // 创建input节点
      oInput.value = this.shareFillUrl // 给input的value赋值
      document.body.appendChild(oInput) // 向页面插入input节点
      oInput.select() // 选中input
      try {
        await document.execCommand('Copy') // 执行浏览器复制命令
        this.$message({
          type: 'success',
          message: '复制成功'
        })
      } catch {
        this.$message({
          type: 'success',
          message: '复制失败'
        })
      }
      document.body.removeChild(oInput)
    },
    // 直接打开填报地址页面
    onToClick() {
      const tempWindow = window.open('_blank')
      tempWindow.location.href = this.shareFillUrl
    },
    // 保存小程序码
    onSaveClick() {
      const self = this
      if (this.isBlank(this.shareItemInfo.shareQr)) {
        this.$message({
          message: '小程序码缺失，暂无法保存',
          type: 'warning'
        })
        return
      }
      this.downloadFile(this.shareItemInfo.name + '.jpg', self.shareImageUrl)
    },
    downloadFile(fileName, content) {
      const aLink = document.createElement('a')
      const blob = this.base64ToBlob(content) // new Blob([content]);

      const evt = document.createEvent('HTMLEvents')
      evt.initEvent('click', true, true) // initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
      aLink.download = fileName
      aLink.href = URL.createObjectURL(blob)
      aLink.dispatchEvent(
        new MouseEvent('click', {
          bubbles: true,
          cancelable: true,
          view: window
        })
      ) // 兼容火狐
    },
    // base64转blob
    base64ToBlob(code) {
      const parts = code.split(';base64,')
      const contentType = parts[0].split(':')[1]
      const raw = window.atob(parts[1])
      const rawLength = raw.length

      const uInt8Array = new Uint8Array(rawLength)

      for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
      }
      return new Blob([uInt8Array], { type: contentType })
    },
    favorite(favorItem) {
      const param = {
        type: '',
        typeId: '',
        operation: ''
      }
      if (favorItem.projectId) {
        param.type = 'project'
        param.typeId = favorItem.projectId
      } else {
        param.type = 'form'
        param.typeId = favorItem.formId
      }
      param.operation = favorItem.favorite ? 'cancel' : 'favorite'

      return new Promise((resolve, reject) => {
        favorite(param).then((response) => {
          if (response.code == 200) {
            let msg = ''
            msg = favorItem.favorite ? '已取消收藏' : '收藏成功'
            this.msgSuccess(msg)
            favorItem.favorite = !favorItem.favorite
            resolve()
          } else {
            reject()
          }
        })
      })
    },
    // 监听分享弹框关闭
    handleFillDiaClose() {
      this.shareFillUrl = ''
    }
  }
}
</script>
<style scoped lang="scss">
.share-container {
    margin-top: -30px;
    width: 100%;
    p {
        padding: 0;
        margin: 0 auto;
    }
    .shareFillImg {
        display: block;
        width: 200px;
        height: 200px;
        margin-top: 20px;
    }
    .share-lite-contain {
        width: 380px;
        height: 550px;
        background: url('https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/canvas_default.png') no-repeat top center;
        background-size: 380px 550px;
        padding-top: 63px;
        margin: auto;
        .top-logo {
            width: 100%;
            padding: 25px 0 15px 40px;
            box-sizing: border-box;
            img {
                display: inline-block;
                vertical-align: middle;
                width: 107px;
                height: 29px;
            }
        }
        .share-card {
            width: calc(100% - 50px);
            height: 470px;
            margin: 0 25px 25px;
            -webkit-box-sizing: border-box;
            box-sizing: border-box;
            padding: 0 20px 0;
            position: relative;
            border-radius: 10px;
            .share-form-img {
                display: inline-block;
                vertical-align: middle;
                width: 100%;
                height: 166px;
                margin: 20px auto 5px;
                border-radius: 10px;
            }
            .share-form-name {
                width: 100%;
                font-size: 14px;
                font-weight: 500;
                line-height: 20px;
                color: #404040;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
            .share-form-fill {
                font-size: 12px;
                line-height: 20px;
                color: #909090;
                margin-left: 70px;
                margin-top: 4px;
            }
            .share-form-bottom {
                width: 100%;
                margin-top: 25px;
                .share-form-qr {
                    width: 140px;
                    height: 140px;
                    margin: auto;
                    text-align: center;
                    display: block;
                }
                .share-form-qr-text {
                    width: 100%;
                    font-size: 11px;
                    line-height: 16px;
                    color: #394349;
                    text-align: center;
                    display: block;
                    margin-top: 10px;
                }
            }
        }
    }
    .list-share-image {
        width: 380px;
        height: 550px;
        display: block;
        margin: auto;
        image-rendering: -moz-crisp-edges; /* Firefox */
        image-rendering: -o-crisp-edges; /* Opera */
        image-rendering: -webkit-optimize-contrast; /*Webkit (non-standard naming) */
        image-rendering: crisp-edges;
        -ms-interpolation-mode: nearest-neighbor; /* IE (non-standard property) */
    }
}
</style>
