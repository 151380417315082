<template v-cloak>
  <div class="user-group-page">
    <el-form :inline="true" label-position="right">
      <el-row>
        <el-col :span="4">
          <el-form-item label="">
            <el-input v-model="queryParams.name" placeholder="请输入用户组名称" clearable size="mini" />
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item>
            <el-button class="filter-item" type="primary" icon="el-icon-search" size="mini" @click="onHandleQuery">搜索</el-button>
            <el-button icon="el-icon-refresh" size="mini" @click="onResetQuery">重置</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="14" style="text-align: right;">
          <el-button type="primary" icon="el-icon-plus" size="mini" @click="onHandleAdd">新增</el-button>
        </el-col>
      </el-row>
    </el-form>
    <el-table :data="table.data" style="width: 100%; cursor: pointer">
      <el-table-column prop="groupName" label="用户组名称" align="left"></el-table-column>
      <el-table-column width="240" label="" align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="text" icon="el-icon-user" @click="onHandleUserGroupClick(scope.row)">成员</el-button>
          <!-- <el-button size="mini" type="text" icon="el-icon-view" @click="onHandleFormViewClick(scope.row)">视图</el-button> -->
          <el-button size="mini" type="text" icon="el-icon-edit" @click="onHandleUpdateClick(scope.row)">修改</el-button>
          <el-button size="mini" type="text" icon="el-icon-delete" @click="onHandleDeleteClick(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination v-show="total>0" :total="total" :page.sync="queryParams.pageNum" :limit.sync="queryParams.pageSize" @pagination="onHandleQuery" />

    <!--新增、修改、查看用户组-->
    <el-dialog :title="dialogTitle" :visible.sync="userGroupDialog" width="700px" append-to-body @close="onCloseDialogClick">
      <el-form ref="userGroupForm" :model="addUserGroupForm" :rules="addUserGroupRules" size="small" label-width="100px" label-position="top">
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户组名称" prop="groupName">
              <el-input v-model="addUserGroupForm.groupName" placeholder="请输入用户组名称" :disabled="dialogFormMode==='view'" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="用户组描述" prop="description">
              <el-input v-model="addUserGroupForm.description" placeholder="请输入用户组描述" :disabled="dialogFormMode==='view'" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="分发哪些应用项" prop="groupType">
              <el-radio-group v-model="addUserGroupForm.groupType">
                <el-radio :label="0">所有应用表单</el-radio>
                <el-radio :label="1">选择应用表单</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="addUserGroupForm.groupType == 0">
          <el-col :span="24">
            <el-form-item label="权限" prop="authType">
              <el-select v-model="addUserGroupForm.authType" style="width:100%" placeholder="">
                <el-option v-for="(authItem,authIndex) in authTypeList" :label="authItem.name" :value="authItem.value" :key="authIndex"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row v-if="addUserGroupForm.groupType == 1">
          <el-col :span="24">
            <el-form-item label="选择应用表单">
              <UserGroupView v-model="applyFormView" :formList="formList" :userGroupId="userGroupId" :agencyId="agencyId" :projectId="projectId"></UserGroupView>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="操作权限" prop="operateAuth">
              <el-checkbox v-model="addUserGroupForm.operateAuth[actionItem.value]" v-for="(actionItem,actionIndex) in operateAuthList" :label="actionItem.value" :key="actionIndex" class="inline-box">{{actionItem.name}}</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer" v-if="dialogFormMode!=='view'">
        <el-button type="primary" @click="onSubmitFormClick">确 定</el-button>
        <el-button @click="onCloseDialogClick">取 消</el-button>
      </div>
      <div slot="footer" class="dialog-footer" v-if="dialogFormMode==='view'">
        <el-button @click="onCloseDialogClick">关 闭</el-button>
      </div>
    </el-dialog>

    <dialogUser :visible.sync="showUserDialog" :userGroupId="userGroupId" :agencyId="agencyId" :userGroupList="table.data"></dialogUser>
    <dialogView :visible.sync="showViewDialog" :userGroupId="userGroupId" :agencyId="agencyId" :projectId="projectId"></dialogView>

  </div>
</template>

<script>
import { getSessionStorageObj } from "@/utils/db";
import { projectGroup } from "@/api/system/form";
// 用户组相关api
import { addUserGroup, updateUserGroup, delUserGroup, listUserGroup } from "@/api/system/userGroup"
import dialogUser from './dialogUser.vue'
import dialogView from './dialogView.vue'
import UserGroupView from './view.vue'
import { deepClone } from '../../../../utils';
export default {
  name: 'UserGroup',
  components: {
    UserGroupView,
    dialogUser,
    dialogView
  },
  data () {
    return {
      projectId: '',
      // projectDetail: {},
      table: {
        data: [{
          id: '1',
          name: '管理员'
        }]
      },
      userSessionObj: {},
      total: 10,
      queryParams: {
        name: '',
        pageNum: 1,
        pageSize: 10,
      },
      userGroupId: '',
      // 弹出新增用户组弹窗
      userGroupDialog: false,
      // 弹出新增用户组弹窗标题
      dialogTitle: '新增用户组',
      dialogFormMode: 'add',
      /** 新增用户组表单数据 */
      addUserGroupForm: {
        groupName: '',
        description: '',
        groupType: 0,
        authType: 1,
        applyForm: '',
        operateAuth: { 'add': true, 'view': true, 'edit': true, 'delete': true, 'audit': true, 'batch': true }
      },
      // 选择应用表单
      applyFormView: [],
      formList: [],
      addUserGroupRules: {
        name: [
          { required: true, message: '请输入用户组名称', trigger: 'blur' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ]
      },
      authTypeList: [
        { name: '对所有记录只有查看权限', value: 1 },
        { name: '可查看、编辑、删除、所有记录', value: 2 },
        { name: '可查看所有记录、但只能编辑、删除自己拥有的记录。', value: 3 },
      ],
      operateAuthList: [
        { name: '新增', value: 'add' },
        { name: '查看', value: 'view' },
        { name: '修改', value: 'edit' },
        { name: '删除', value: 'delete' },
        { name: '审核', value: 'audit' },
        { name: '批量操作', value: 'batch' },
      ],
      showUserDialog: false,
      showViewDialog: false
    }
  },
  props: {
    agencyId: {
      type: Number,
      default: 0
    }
  },
  mounted () {
  },
  created () {
    this.projectId = this.decode(this.$route.params.id);
    this.userSessionObj = getSessionStorageObj('users')
    // this.getProjectDetail();
    this.getProjectGroup();
    this.getUserGroupList();
  },
  methods: {
    // getProjectDetail () {
    //   projectGroup(this.projectId).then((response) => {
    //     if (response.code == 200) {
    //       this.projectDetail = response.data;

    //     }
    //   });
    // },
    onHandleQuery () {
      this.getUserGroupList();
    },
    onResetQuery () {
      this.queryParams = {
        number: '',
        name: '',
      }
    },
    getUserGroupList () {
      listUserGroup({
        projectId: this.projectId,
        groupName: this.queryParams.name || undefined
      }).then(res => {
        this.table.data = res.rows;
      })

    },
    onHandleAdd () {
      this.userGroupDialog = true;
      this.dialogTitle = '新增用户组';
      this.dialogFormMode = 'add';
    },
    // 查看用户组
    onHandleDetailClick (row) {
      this.userGroupDialog = true;
      this.dialogTitle = '用户组';
      this.dialogFormMode = 'view';
      this.id = row.id;
    },
    // 修改用户组
    onHandleUpdateClick (row) {
      this.dialogTitle = '修改用户组';
      this.dialogFormMode = 'update';

      this.addUserGroupForm = deepClone(row);

      let applyFormView = [];
      if (row.applyForm) {
        let applyForm = JSON.parse(row.applyForm);
        applyForm.forEach(x => {
          let applyFormViewItem = applyFormView.find(x => x.formId === x.formId);
          if (!applyFormViewItem) {
            applyFormView.push({
              id: x.formId,
              name: x.formName,
              children: [
                {
                  id: x.id,
                  name: x.name,
                  view: x.view,
                  edit: x.edit,
                  auth: x.auth,
                  delete: x.delete,
                  enable: x.enable
                }
              ]
            })
          } else {
            applyFormViewItem.children.push({
              id: x.id,
              name: x.name,
              view: x.view,
              edit: x.edit,
              auth: x.auth,
              delete: x.delete,
              enable: x.enable
            })
          }
        });
      }

      this.applyFormView = applyFormView;
      this.userGroupDialog = true;
    },
    //  删除用户组
    onHandleDeleteClick (row) {
      this.$confirm('此操作将永久删除该用户组, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {

        delUserGroup(row.id).then(res => {
          this.$message({
            type: 'success',
            message: '删除成功!'
          });

          this.getUserGroupList();
        })
      }).catch(() => {
      })

    },
    onCloseDialogClick () {
      this.addUserGroupForm.groupName = '';
      this.addUserGroupForm.description = '';
      this.addUserGroupForm.groupType = 0;
      this.addUserGroupForm.authType = 1;
      this.addUserGroupForm.applyForm = '';
      this.addUserGroupForm.operateAuth = { 'add': true, 'view': true, 'edit': true, 'delete': true, 'audit': true, 'batch': true }

      this.userGroupDialog = false;

    },
    // 用户组弹窗提交表单
    onSubmitFormClick () {
      var addUserGroupForm = deepClone(this.addUserGroupForm);
      var applyFormView = [];

      this.applyFormView.forEach(x => {
        x.children.filter(y => y.enable).forEach(y => {
          applyFormView.push({
            ...y,
            formId: x.id,
            formName: x.name
          })
        })
      })
      addUserGroupForm.applyForm = JSON.stringify(applyFormView);
      addUserGroupForm.projectId = this.projectId;

      this.$refs.userGroupForm.validate((valid) => {
        if (valid) {
          const action = this.dialogFormMode == 'update' ? updateUserGroup : addUserGroup
          action(addUserGroupForm).then(res => {
            this.$message({
              type: 'success',
              message: '提交成功!'
            });
            this.userGroupDialog = false;
            this.getUserGroupList();
          })
        }
      })

    },
    onHandleUserGroupClick (row) {
      this.userGroupId = row.id;
      this.showUserDialog = true
      
    },
    onHandleFormViewClick (row) {
      this.userGroupId = row.id;
      this.showViewDialog = true
    },
    getProjectGroup () {
      var groupList = [];
      projectGroup(this.projectId).then(res => {
        groupList.splice(0);

        res.data.formGroupList.forEach(x => {
          groupList.push(x);
        });

        const findForm = (item) => {
          if (!item.isGroupType) {
            item.children = []; // 初始化空的数组，用于放视图
            this.formList.push({
              id: item.id,
              name: item.name,
              children: item.children
            })
          } else {
            item.children.forEach(x => {
              findForm(x)
            })
          }
        }

        this.formList.splice(0);
        groupList.forEach(x => {
          findForm(x)
        })
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.user-group-page {
  border-radius: 4px;
  display: inline-flex;
  background-color: #fff;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}
.inline-box {
  display: inline-block;
}
</style>

