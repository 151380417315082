/**
 * 北方工业大学大学生创新创业训练计划项目研究成果汇总表 表头
 */
export const agGridColumnDefs_bgd = [
  {
    headerName: "#",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    pinned: true,
    valueGetter: hashValueGetter,
    width: 90,
    filter: false,
  },
  {
    field: "学院",
    headerName: "学院",
  },
  {
    field: "申报级别",
    headerName: "申报级别",
  },
  {
    field: "项目级别",
    headerName: "项目级别",
  },
  {
    field: "项目名称",
    headerName: "项目名称",
    tooltipField: "项目名称",
  },
  {
    field: "项目负责人",
    headerName: "项目负责人",
  },
  {
    field: "指导教师",
    headerName: "指导教师",
  },
  {
    field: "组员",
    headerName: "组员",
    tooltipField: "组员",
  },
  {
    field: "奖项级别",
    headerName: "奖项级别（包括优秀项目）",
  },
  {
    headerName: "实物作品情况",
    children: [
      {
        field: "实物作品情况_样机数量",
        headerName: "样机数量",
      },
      {
        field: "实物作品情况_软件数量",
        headerName: "软件数量",
      },
      {
        field: "实物作品情况_模型数量",
        headerName: "模型数量",
      },
      {
        field: "实物作品情况_作品图册数量",
        headerName: "作品图册数量",
      },
    ],
  },
  {
    headerName: "项目获奖情况（包含竞赛）",
    children: [
      {
        field: "项目获奖情况（包含竞赛）_国家级获奖数量",
        headerName: "国家级获奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省级A获奖数量",
        headerName: "省部级A获奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省级B获奖数量",
        headerName: "省部级B获奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_具体获奖情况（比赛名称、获奖名次等）",
        headerName: "具体获奖情况（比赛名称、获奖名次等）",
        tooltipField:
          "项目获奖情况（包含竞赛）_具体获奖情况（比赛名称、获奖名次等）",
      },
      {
        field: "项目获奖情况（包含竞赛）_国家级项目获特等奖数量",
        headerName: "国家级（项目）获特等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_国家级项目获一等奖数量",
        headerName: "国家级（项目）获一等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_国家级项目获二等奖数量",
        headerName: "国家级（项目）获二等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_国家级项目获三等奖数量",
        headerName: "国家级（项目）获三等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_国家级考试获特等奖数量",
        headerName: "国家级（考试）获特等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_国家级考试获一等奖数量",
        headerName: "国家级（考试）获一等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_国家级考试获二等奖数量",
        headerName: "国家级（考试）获二等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_国家级考试获三等奖数量",
        headerName: "国家级（考试）获三等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级A项目获特等奖数量",
        headerName: "省部级A（项目）获特等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级A项目获一等奖数量",
        headerName: "省部级A（项目）获一等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级A项目获二等奖数量",
        headerName: "省部级A（项目）获二等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级A项目获三等奖数量",
        headerName: "省部级A（项目）获三等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级A考试获特等奖数量",
        headerName: "省部级A（考试）获特等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级A考试获一等奖数量",
        headerName: "省部级A（考试）获一等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级A考试获二等奖数量",
        headerName: "省部级A（考试）获二等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级A考试获三等奖数量",
        headerName: "省部级A（考试）获三等奖数量",
      },

      {
        field: "项目获奖情况（包含竞赛）_省部级B项目获特等奖数量",
        headerName: "省部级B（项目）获特等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级B项目获一等奖数量",
        headerName: "省部级B（项目）获一等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级B项目获二等奖数量",
        headerName: "省部级B（项目）获二等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级B项目获三等奖数量",
        headerName: "省部级B（项目）获三等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级B考试获特等奖数量",
        headerName: "省部级B（考试）获特等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级B考试获一等奖数量",
        headerName: "省部级B（考试）获一等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级B考试获二等奖数量",
        headerName: "省部级B（考试）获二等奖数量",
      },
      {
        field: "项目获奖情况（包含竞赛）_省部级B考试获三等奖数量",
        headerName: "省部级B（考试）获三等奖数量",
      },

    ],
  },
  {
    headerName: "发表论文情况（第一作者）",
    children: [
      {
        field: "发表论文情况（第一作者）_其他期刊",
        headerName: "其他期刊",
      },
      {
        field: "发表论文情况（第一作者）_EI（工程索引）会议",
        headerName: "EI（工程索引）会议",
      },
      {
        field: "发表论文情况（第一作者）_CPCI（国际会议录索引）",
        headerName: "CPCI（国际会议录索引）",
      },
      {
        field: "发表论文情况（第一作者）_北大中文核心期刊数量",
        headerName: "北大中文核心期刊数量",
      },
      {
        field: "发表论文情况（第一作者）_CSSCI扩展库集刊收录",
        headerName: "CSSCI扩展库集刊收录",
      },
      {
        field: "发表论文情况（第一作者）_CSSCI核心库收录",
        headerName: "CSSCI核心库收录",
      },
      {
        field: "发表论文情况（第一作者）_CSCD（中国科技期刊引证报告）",
        headerName: "CSCD（中国科技期刊引证报告）",
      },
      {
        field: "发表论文情况（第一作者）_SCI（科学引文索引）",
        headerName: "SCI（科学引文索引）",
      },
      {
        field: "发表论文情况（第一作者）_SSCI（社会科学引文索引）",
        headerName: "SSCI（社会科学引文索引）",
      },
      {
        field: "发表论文情况（第一作者）_EI（工程索引）期刊",
        headerName: "EI（工程索引）期刊",
      },
      {
        field: "发表论文情况（第一作者）_A&HCI（艺术与人文科学索引）",
        headerName: "A&HCI（艺术与人文科学索引）",
      },
      {
        field: "发表论文情况（第一作者）_国际期刊论文数量",
        headerName: "国际期刊论文数量",
      },
      {
        field: "发表论文情况（第一作者）_国家级期刊论文数量",
        headerName: "国家级期刊论文数量",
      },
      {
        field: "发表论文情况（第一作者）_省级期刊论文数量",
        headerName: "省级期刊论文数量",
      },
    ],
  },
  {
    headerName: "发表论文情况（非第一作者）",
    children: [
      {
        field: "发表论文情况（非第一作者）_其他期刊",
        headerName: "其他期刊",
      },
      {
        field: "发表论文情况（非第一作者）_EI（工程索引）会议",
        headerName: "EI（工程索引）会议",
      },
      {
        field: "发表论文情况（非第一作者）_CPCI（国际会议录索引）",
        headerName: "CPCI（国际会议录索引）",
      },
      {
        field: "发表论文情况（非第一作者）_北大中文核心期刊数量",
        headerName: "北大中文核心期刊数量",
      },
      {
        field: "发表论文情况（非第一作者）_CSSCI扩展库集刊收录",
        headerName: "CSSCI扩展库集刊收录",
      },
      {
        field: "发表论文情况（非第一作者）_CSSCI核心库收录",
        headerName: "CSSCI核心库收录",
      },
      {
        field: "发表论文情况（非第一作者）_CSCD（中国科技期刊引证报告）",
        headerName: "CSCD（中国科技期刊引证报告）",
      },
      {
        field: "发表论文情况（非第一作者）_SCI（科学引文索引）",
        headerName: "SCI（科学引文索引）",
      },
      {
        field: "发表论文情况（非第一作者）_SSCI（社会科学引文索引）",
        headerName: "SSCI（社会科学引文索引）",
      },
      {
        field: "发表论文情况（非第一作者）_EI（工程索引）期刊",
        headerName: "EI（工程索引）期刊",
      },
      {
        field: "发表论文情况（非第一作者）_A&HCI（艺术与人文科学索引）",
        headerName: "A&HCI（艺术与人文科学索引）",
      },
      {
        field: "发表论文情况（非第一作者）_国际期刊论文数量",
        headerName: "国际期刊论文数量",
      },
      {
        field: "发表论文情况（非第一作者）_国家级期刊论文数量",
        headerName: "国家级期刊论文数量",
      },
      {
        field: "发表论文情况（非第一作者）_省级期刊论文数量",
        headerName: "省级期刊论文数量",
      },
    ],
  },
  {
    field: "论文名称及期刊名称",
    headerName: "论文名称及期刊名称",
    tooltipField: "论文名称及期刊名称",
  },
  {
    headerName: "项目转化情况",
    children: [
      {
        field: "项目转化情况_发明专利数量",
        headerName: "发明专利数量",
      },
      {
        field: "项目转化情况_发明专利名称",
        headerName: "发明专利名称",
        tooltipField: "项目转化情况_发明专利名称",
      },
      {
        field: "项目转化情况_计算机软件著作权数量",
        headerName: "计算机软件著作权数量",
      },
      {
        field: "项目转化情况_计算机软件著作权名称",
        headerName: "计算机软件著作权名称",
        tooltipField: "项目转化情况_计算机软件著作权名称",
      },
      {
        field: "项目转化情况_实用新型专利数量",
        headerName: "实用新型专利数量",
      },
      {
        field: "项目转化情况_实用新型专利名称",
        headerName: "实用新型专利名称",
        tooltipField: "项目转化情况_实用新型专利名称",
      },
      {
        field: "项目转化情况_外观设计专利数量",
        headerName: "外观设计专利数量",
      },
      {
        field: "项目转化情况_外观设计专利名称",
        headerName: "外观设计专利名称",
        tooltipField: "项目转化情况_外观设计专利名称",
      },
    ],
  },
  {
    field: "参与项目学生保研数量",
    headerName: "参与项目学生保研数量",
  },
  {
    field: "参与项目学生创业数量",
    headerName: "参与项目学生创业数量",
  },
  {
    field: "其他成果",
    headerName: "其他成果",
    tooltipField: "其他成果",
  },
];

export const agGridColumnDefs_bgd_ysjx = [
  {
    headerName: "序号",
    headerCheckboxSelection: true,
    checkboxSelection: true,
    pinned: true,
    valueGetter: hashValueGetter,
    width: 90,
    filter: false
  },
  {
    field: "主管部门",
    headerName: "主管部门"
  },
  {
    field: "学院",
    headerName: "学院"
  },
  {
    field: "指导老师",
    headerName: "指导老师"
  },
  {
    field: "指导老师工号",
    headerName: "指导老师工号",
    cellClass: 'stringType'
  },
  {
    field: "项目名称",
    headerName: "项目名称",
    tooltipField: "项目名称"
  },
  {
    field: "组长",
    headerName: "组长"
  },
  {
    field: "组员",
    headerName: "组员",
    tooltipField: "组员"
  },
  {
    field: "申报级别",
    headerName: "申报级别"
  },
  {
    field: "项目级别",
    headerName: "项目级别"
  },
  {
    field: "总计",
    headerName: "总计"
  },
  {
    field: "启动经费总计",
    headerName: "启动经费总计"
  },
  {
    field: "第二阶段经费总计",
    headerName: "第二阶段经费总计"
  },
  {
    headerName: "启动经费",
    children: [
      {
        field: "启动经费_材料费",
        headerName: "材料费",
      },
      {
        field: "启动经费_图书资料费",
        headerName: "图书资料费",
      },
      {
        field: "启动经费_印刷费",
        headerName: "印刷费",
      },
      {
        field: "启动经费_版面费",
        headerName: "版面费",
      },
      {
        field: "启动经费_知识产权事务费",
        headerName: "知识产权事务费",
      },
      {
        field: "启动经费_专家咨询费",
        headerName: "专家咨询费",
      },
    ],
  },
  {
    headerName: "第二阶段经费",
    children: [
      {
        field: "第二阶段经费_材料费",
        headerName: "材料费",
      },
      {
        field: "第二阶段经费_图书资料费",
        headerName: "图书资料费",
      },
      {
        field: "第二阶段经费_印刷费",
        headerName: "印刷费",
      },
      {
        field: "第二阶段经费_版面费",
        headerName: "版面费",
      },
      {
        field: "第二阶段经费_知识产权事务费",
        headerName: "知识产权事务费",
      },
      {
        field: "第二阶段经费_专家咨询费",
        headerName: "专家咨询费",
      },
    ],
  },
];

export function hashValueGetter(params) {
  return params.node ? params.node.rowIndex + 1 : null;
}
