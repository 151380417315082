var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-info-content", staticStyle: { width: "100%" } },
    [
      _c(
        "div",
        {
          staticStyle: {
            padding: "20px 20px 0 20px",
            "background-color": "white",
            "border-radius": "4px",
          },
        },
        [
          _c(
            "el-row",
            {
              staticClass: "el-row",
              staticStyle: { margin: "0", "padding-bottom": "20px" },
              attrs: { gutter: 20, type: "flex" },
            },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    padding: "0",
                    width: "200px",
                    height: "120px",
                  },
                  attrs: { span: 4, xs: 24 },
                },
                [
                  _c("div", { staticClass: "project-detail-img" }, [
                    _vm.projectDetail && _vm.projectDetail.cover
                      ? _c("img", { attrs: { src: _vm.projectDetail.cover } })
                      : _vm._e(),
                  ]),
                ]
              ),
              _c(
                "el-col",
                {
                  staticStyle: {
                    padding: "0 0 0 20px",
                    position: "relative",
                    "box-sizing": "border-box",
                    flex: "1",
                    "-webkit-flex": "1",
                  },
                  attrs: { span: 8, xs: 24 },
                },
                [
                  _c("div", { staticClass: "project-detail-info" }, [
                    _c("h1", { staticClass: "clearfix project-detail-title" }, [
                      _c("span", { staticClass: "title-text" }, [
                        _vm._v(_vm._s(_vm.projectDetail.name)),
                      ]),
                    ]),
                    _c("div", { staticClass: "project-detail-top-bottom" }, [
                      _c(
                        "div",
                        { staticClass: "clearfix project-detail-date" },
                        [
                          _c("p", { staticClass: "create-date" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "70px",
                                  display: "inline-block",
                                },
                              },
                              [_vm._v("创建时间")]
                            ),
                            _c("span", { staticStyle: { margin: "0 10px" } }, [
                              _vm._v(":"),
                            ]),
                            _c("span", [
                              _vm._v(_vm._s(_vm.projectDetail.createTime)),
                            ]),
                          ]),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "clearfix project-detail-date" },
                        [
                          _c("p", { staticClass: "create-date" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  width: "70px",
                                  display: "inline-block",
                                },
                              },
                              [
                                _vm._v("类"),
                                _c("i", { staticStyle: { opacity: "0" } }, [
                                  _vm._v("类型"),
                                ]),
                                _vm._v("型"),
                              ]
                            ),
                            _c("span", { staticStyle: { margin: "0 10px" } }, [
                              _vm._v(":"),
                            ]),
                            _c("span", [_vm._v("应用")]),
                          ]),
                        ]
                      ),
                      _c("div", { staticClass: "project-detail-creater" }, [
                        _c("p", { staticClass: "creater" }, [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                width: "70px",
                                display: "inline-block",
                              },
                            },
                            [
                              _c(
                                "i",
                                { staticStyle: { "font-style": "normal" } },
                                [_vm._v("创")]
                              ),
                              _c(
                                "i",
                                {
                                  staticStyle: {
                                    "font-style": "normal",
                                    margin: "0 6px",
                                  },
                                },
                                [_vm._v("建")]
                              ),
                              _c(
                                "i",
                                { staticStyle: { "font-style": "normal" } },
                                [_vm._v("人")]
                              ),
                            ]
                          ),
                          _c("span", { staticStyle: { margin: "0 10px" } }, [
                            _vm._v(":"),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(_vm.projectDetail.createName)),
                          ]),
                          _vm.projectDetail.agencyId
                            ? _c("img", {
                                staticClass: "is-agency-icon",
                                attrs: {
                                  src: "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/list-agency-icon.png",
                                },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ]),
                ]
              ),
              _c("el-col", { attrs: { span: 12 } }, [
                _c(
                  "div",
                  { staticStyle: { "text-align": "right" } },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "collect-btn",
                        class: { active: _vm.projectDetail.favorite },
                        attrs: { size: "mini" },
                        on: { click: _vm.onCollectClick },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "btn-icon",
                          attrs: {
                            "icon-class": _vm.projectDetail.favorite
                              ? "formListCollect"
                              : "formListUnCollect",
                          },
                        }),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm.projectDetail.favorite ? "已收藏" : "收藏"
                            )
                          ),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "btn",
                        attrs: { size: "mini" },
                        on: { click: _vm.onShareProjectClick },
                      },
                      [
                        _c("svg-icon", {
                          staticClass: "btn-icon",
                          attrs: { "icon-class": "formListShare" },
                        }),
                        _c("span", [_vm._v("分享")]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "form-info-tab-bottom" }, [
        _c(
          "div",
          { staticClass: "tab-content-nav", staticStyle: { display: "block" } },
          [
            _c(
              "div",
              {
                staticClass: "content-nav-title",
                class: [_vm.appInfoCur == 1 ? "active" : ""],
                on: {
                  click: function ($event) {
                    _vm.appInfoCur = 1
                  },
                },
              },
              [
                _c("svg-icon", { attrs: { "icon-class": "formDetailInfo" } }),
                _vm._v("应用介绍 "),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "content-nav-title",
                class: [_vm.appInfoCur == 2 ? "active" : ""],
                on: {
                  click: function ($event) {
                    _vm.appInfoCur = 2
                  },
                },
              },
              [
                _c("svg-icon", { attrs: { "icon-class": "formDetailFile" } }),
                _vm._v("相关附件 "),
              ],
              1
            ),
          ]
        ),
        _c("div", { staticStyle: { padding: "10px 0" } }, [
          _vm.appInfoCur === 1
            ? _c(
                "div",
                { staticClass: "info-tab-content" },
                [
                  _vm.projectDetail && _vm.projectDetail.details != ""
                    ? _c("el-row", { staticClass: "el-row" }, [
                        _c(
                          "div",
                          {
                            staticClass: "project-intro-con",
                            attrs: {
                              style1: {
                                height: _vm.advanced ? "100%" : "200px",
                              },
                              vhtml: "projectDetail.details",
                            },
                          },
                          [
                            _c("pre", {
                              domProps: {
                                innerHTML: _vm._s(_vm.projectDetail.details),
                              },
                            }),
                          ]
                        ),
                      ])
                    : _c("el-row", [
                        _c(
                          "div",
                          {
                            style:
                              "margin: 0px;padding: 20px 20px 10px 15px;" +
                              (_vm.projectDetail.userId === _vm.loginUserId
                                ? "height:340px;padding-top:0px;margin-top:0px;"
                                : "height:calc(100vh - 352px);margin-top:20px;") +
                              ";background-color: white;border-radius: 4px;box-sizing: border-box;",
                          },
                          [
                            _c("el-empty", {
                              attrs: {
                                "image-size": 200,
                                description: "暂无内容",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                ],
                1
              )
            : _vm._e(),
          _vm.appInfoCur === 2
            ? _c("div", { staticClass: "info-tab-content" }, [
                _c(
                  "div",
                  {
                    style:
                      "margin: 0px;padding: 0px;" +
                      (_vm.projectDetail.userId === _vm.loginUserId
                        ? "height:340px;"
                        : "height:calc(100vh - 342px);") +
                      ";background-color: white;border-radius: 4px;box-sizing: border-box;",
                  },
                  [
                    _c("Download", {
                      attrs: { fileList: _vm.projectDetail.fileList },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm.projectDetail.userId === _vm.loginUserId
        ? _c("div", { staticStyle: { "margin-top": "20px" } }, [
            _vm.projectDetail.projectId === "1695831421140074496"
              ? _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: { border: "none" },
                                attrs: { shadow: "hover" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("统计"),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            color: "rgba(0, 0, 0, 0.45)",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "（下方内容仅应用创建者可见，实时统计）"
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                        },
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v("更多")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "extra-content" }, [
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "投资融资" },
                                        }),
                                        _vm._v(" 已填报人数"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.fillUserNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "定制" },
                                        }),
                                        _vm._v(" 提交数据总量"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.totalFillNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "记事本" },
                                        }),
                                        _vm._v(" 今日新增"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.todayFillNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "卡包" },
                                        }),
                                        _vm._v(" 本周新增"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.weekFillNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "审核" },
                                        }),
                                        _vm._v(" 本月新增"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.monthFillNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: {
                                  border: "none",
                                  "margin-top": "20px",
                                },
                                attrs: { shadow: "hover" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("图表展示 "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            color: "rgba(0, 0, 0, 0.45)",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "（此区域可根据学校需求自定义设置，未设置则只显示下方样例图表，有统计需求请直接联系我们）"
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                        },
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v("更多")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-row",
                                      { attrs: { gutter: 10 } },
                                      [
                                        _c("el-col", { attrs: { span: 12 } }, [
                                          _c("div", [_c("pie")], 1),
                                        ]),
                                        _c("el-col", { attrs: { span: 12 } }, [
                                          _c("div", [_c("bar")], 1),
                                        ]),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "el-row",
                                      [
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "margin-top": "20px",
                                            },
                                            attrs: { span: 24 },
                                          },
                                          [_c("wordcloud")],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: { border: "none" },
                                attrs: { shadow: "hover" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [_vm._v("文件统计")]),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                        },
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v("更多")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        staticClass: "extra-content",
                                        attrs: { gutter: 40 },
                                      },
                                      [
                                        _c("el-col", { attrs: { span: 12 } }, [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-statistic-title",
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "档案",
                                                  },
                                                }),
                                                _vm._v(" 文件数量"),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-statistic-content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.projectStatistics
                                                      .fileNum
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "border-left":
                                                "1px solid #e8e8e8",
                                            },
                                            attrs: { span: 12 },
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-statistic-title",
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class": "文件资料",
                                                    },
                                                  }),
                                                  _vm._v(" 文件大小（GB）"),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-statistic-content",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.projectStatistics
                                                        .fileSize
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: {
                                  border: "none",
                                  "margin-top": "20px",
                                },
                                attrs: {
                                  shadow: "hover",
                                  "body-style": { padding: "0px" },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("svg-icon", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "200px",
                                      },
                                      attrs: { "icon-class": "数据分析" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: {
                                  border: "none",
                                  "margin-top": "20px",
                                },
                                attrs: {
                                  shadow: "hover",
                                  "body-style": { padding: "0px 5px" },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [_vm._v("报表展示")]),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                        },
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v("更多")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", [
                                  _c("div", { staticClass: "ant-list" }, [
                                    _c(
                                      "ul",
                                      { staticClass: "ant-list-items" },
                                      [
                                        _c(
                                          "li",
                                          { staticClass: "ant-list-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-list-item-meta",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-avatar",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        width: "30px",
                                                        height: "30px",
                                                      },
                                                      attrs: {
                                                        "icon-class": "数据",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-title",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.agGridDialogVisible = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "（一）人文、社会科学活动人员情况表 "
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-description",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 各种统计类报表 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "ant-list-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-list-item-meta",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-avatar",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        width: "30px",
                                                        height: "30px",
                                                      },
                                                      attrs: {
                                                        "icon-class": "表格",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-title",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.agGridDialogVisible = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "（二）人文、社会科学R＆D人员情况表"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-description",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 实现更多的功能 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "ant-list-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-list-item-meta",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-avatar",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        width: "30px",
                                                        height: "30px",
                                                      },
                                                      attrs: {
                                                        "icon-class": "数据",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-title",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.agGridDialogVisible = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "（三）人文、社会科学R＆D经费情况表"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-description",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 实现各种统计报表的展示 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "ant-list-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-list-item-meta",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-avatar",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        width: "30px",
                                                        height: "30px",
                                                      },
                                                      attrs: {
                                                        "icon-class": "数据",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-title",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.agGridDialogVisible = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "（四）人文、社会科学研究机构一览表"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-description",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 实现各种统计报表的展示 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "ant-list-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-list-item-meta",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-avatar",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        width: "30px",
                                                        height: "30px",
                                                      },
                                                      attrs: {
                                                        "icon-class": "数据",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-title",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.agGridDialogVisible = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "（五）人文、社会科学R＆D课题情况表1"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-description",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 实现各种统计报表的展示 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "li",
                                          { staticClass: "ant-list-item" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-list-item-meta",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-avatar",
                                                  },
                                                  [
                                                    _c("svg-icon", {
                                                      staticStyle: {
                                                        width: "30px",
                                                        height: "30px",
                                                      },
                                                      attrs: {
                                                        "icon-class": "数据",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta-content",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-title",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            _vm.agGridDialogVisible = true
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "（六）人文、社会科学R＆D课题情况表2"
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-description",
                                                      },
                                                      [
                                                        _vm._v(
                                                          " 实现各种统计报表的展示 "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  [
                    _c(
                      "el-row",
                      { attrs: { gutter: 20 } },
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: { border: "none" },
                                attrs: { shadow: "hover" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("统计"),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            color: "rgba(0, 0, 0, 0.45)",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "（下方内容仅应用创建者可见，实时统计）"
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                        },
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v("更多")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", { staticClass: "extra-content" }, [
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "投资融资" },
                                        }),
                                        _vm._v(" 已填报人数"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.fillUserNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "定制" },
                                        }),
                                        _vm._v(" 提交数据总量"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.totalFillNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "记事本" },
                                        }),
                                        _vm._v(" 今日新增"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.todayFillNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "卡包" },
                                        }),
                                        _vm._v(" 本周新增"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.weekFillNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _c("div", { staticClass: "stat-item" }, [
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-title" },
                                      [
                                        _c("svg-icon", {
                                          attrs: { "icon-class": "审核" },
                                        }),
                                        _vm._v(" 本月新增"),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "ant-statistic-content" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            this.projectStatistics.monthFillNum
                                          )
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: {
                                  border: "none",
                                  "margin-top": "20px",
                                },
                                attrs: { shadow: "hover" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [
                                      _vm._v("图表展示 "),
                                      _c(
                                        "span",
                                        {
                                          staticStyle: {
                                            "font-size": "12px",
                                            color: "rgba(0, 0, 0, 0.45)",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "（此区域可根据学校需求自定义设置，未设置则只显示下方样例图表，有统计需求请直接联系我们）"
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                        },
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v("更多")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c("chart"),
                                    _c("el-divider"),
                                    _c("geo-map-chart", {
                                      attrs: { data: _vm.collegesData },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-col",
                          { attrs: { span: 8 } },
                          [
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: { border: "none" },
                                attrs: { shadow: "hover" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [_vm._v("文件统计")]),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                        },
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v("更多")]
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  [
                                    _c(
                                      "el-row",
                                      {
                                        staticClass: "extra-content",
                                        attrs: { gutter: 40 },
                                      },
                                      [
                                        _c("el-col", { attrs: { span: 12 } }, [
                                          _c("div", [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-statistic-title",
                                              },
                                              [
                                                _c("svg-icon", {
                                                  attrs: {
                                                    "icon-class": "档案",
                                                  },
                                                }),
                                                _vm._v(" 文件数量"),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ant-statistic-content",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    this.projectStatistics
                                                      .fileNum
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                        _c(
                                          "el-col",
                                          {
                                            staticStyle: {
                                              "border-left":
                                                "1px solid #e8e8e8",
                                            },
                                            attrs: { span: 12 },
                                          },
                                          [
                                            _c("div", [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-statistic-title",
                                                },
                                                [
                                                  _c("svg-icon", {
                                                    attrs: {
                                                      "icon-class": "文件资料",
                                                    },
                                                  }),
                                                  _vm._v(" 文件大小（GB）"),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "ant-statistic-content",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      this.projectStatistics
                                                        .fileSize
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: {
                                  border: "none",
                                  "margin-top": "20px",
                                },
                                attrs: {
                                  shadow: "hover",
                                  "body-style": { padding: "0px" },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  [
                                    _c("svg-icon", {
                                      staticStyle: {
                                        width: "100%",
                                        height: "200px",
                                      },
                                      attrs: { "icon-class": "数据分析" },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: {
                                  border: "none",
                                  "margin-top": "20px",
                                },
                                attrs: {
                                  shadow: "hover",
                                  "body-style": { padding: "0px" },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [_c("span", [_vm._v("项目申报统计")])]
                                ),
                                _c("pie", { attrs: { height: "200px" } }),
                              ],
                              1
                            ),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: {
                                  border: "none",
                                  "margin-top": "20px",
                                },
                                attrs: {
                                  shadow: "hover",
                                  "body-style": { padding: "0px" },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [_c("span", [_vm._v("项目词云统计")])]
                                ),
                                _c("wordcloud", {
                                  attrs: {
                                    height: "280px",
                                    sizeRange: [12, 30],
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "el-card",
                              {
                                staticClass: "box-card",
                                staticStyle: {
                                  border: "none",
                                  "margin-top": "20px",
                                },
                                attrs: {
                                  shadow: "hover",
                                  "body-style": { padding: "0px 5px" },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "clearfix",
                                    attrs: { slot: "header" },
                                    slot: "header",
                                  },
                                  [
                                    _c("span", [_vm._v("报表展示")]),
                                    _c(
                                      "el-button",
                                      {
                                        staticStyle: {
                                          float: "right",
                                          padding: "3px 0",
                                        },
                                        attrs: { type: "text" },
                                      },
                                      [_vm._v("更多")]
                                    ),
                                  ],
                                  1
                                ),
                                _c("div", [
                                  _c("div", { staticClass: "ant-list" }, [
                                    _vm.reportList.length > 0
                                      ? _c(
                                          "ul",
                                          { staticClass: "ant-list-items" },
                                          _vm._l(
                                            _vm.reportList,
                                            function (item) {
                                              return _c(
                                                "li",
                                                {
                                                  key: item,
                                                  staticClass: "ant-list-item",
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "ant-list-item-meta",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ant-list-item-meta-avatar",
                                                        },
                                                        [
                                                          _c("svg-icon", {
                                                            staticStyle: {
                                                              width: "30px",
                                                              height: "30px",
                                                            },
                                                            attrs: {
                                                              "icon-class":
                                                                item.icon
                                                                  ? item.icon
                                                                  : "数据",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "ant-list-item-meta-content",
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ant-list-item-meta-title",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.getReportAgGridData(
                                                                      item.url
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  item.name
                                                                ) + " "
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "ant-list-item-meta-description",
                                                            },
                                                            [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    item.description
                                                                  ) +
                                                                  " "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        )
                                      : _c(
                                          "ul",
                                          { staticClass: "ant-list-items" },
                                          [
                                            _vm.projectDetail.projectId ===
                                            "1722176593545134080"
                                              ? _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "ant-list-item",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-avatar",
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              staticStyle: {
                                                                width: "30px",
                                                                height: "30px",
                                                              },
                                                              attrs: {
                                                                "icon-class":
                                                                  "数据",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-list-item-meta-title",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.loadReportData(
                                                                        "1722176593545134080_综合测评"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "综合测评 "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-list-item-meta-description",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 各学院总体状态统计表 "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.projectDetail.projectId ===
                                            "1722176593545134080"
                                              ? _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "ant-list-item",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-avatar",
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              staticStyle: {
                                                                width: "30px",
                                                                height: "30px",
                                                              },
                                                              attrs: {
                                                                "icon-class":
                                                                  "数据",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-list-item-meta-title",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.loadReportData(
                                                                        "1722176593545134080_各学院总体状态统计表"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "各学院总体状态统计表 "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-list-item-meta-description",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 各学院总体状态统计表 "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.projectDetail.projectId ===
                                            "1719614615022731264"
                                              ? _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "ant-list-item",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-avatar",
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              staticStyle: {
                                                                width: "30px",
                                                                height: "30px",
                                                              },
                                                              attrs: {
                                                                "icon-class":
                                                                  "数据",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-list-item-meta-title",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.loadReportData(
                                                                        "1719745623990730752_北方工业大学大创项目预算及绩效申报统计",
                                                                        "1719745623990730752"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "北方工业大学大创项目预算及绩效申报统计 "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-list-item-meta-description",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 北方工业大学大创项目预算及绩效申报统计 "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.projectDetail.projectId ===
                                            "1719614615022731264"
                                              ? _c(
                                                  "li",
                                                  {
                                                    staticClass:
                                                      "ant-list-item",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-avatar",
                                                          },
                                                          [
                                                            _c("svg-icon", {
                                                              staticStyle: {
                                                                width: "30px",
                                                                height: "30px",
                                                              },
                                                              attrs: {
                                                                "icon-class":
                                                                  "数据",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-content",
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-list-item-meta-title",
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.loadReportData(
                                                                        "1719614615022731264_北方工业大学大学生创新创业训练计划项目研究成果汇总表",
                                                                        "1719748254993743872"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "大学生创新创业训练计划项目研究成果汇总表 "
                                                                ),
                                                              ]
                                                            ),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "ant-list-item-meta-description",
                                                              },
                                                              [
                                                                _vm._v(
                                                                  " 大学生创新创业训练计划项目研究成果汇总表 "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _c(
                                              "li",
                                              { staticClass: "ant-list-item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-avatar",
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          staticStyle: {
                                                            width: "30px",
                                                            height: "30px",
                                                          },
                                                          attrs: {
                                                            "icon-class":
                                                              "数据",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-content",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-title",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.agGridDialogVisible = true
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "专家评审汇总表1 "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-description",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 各种统计类报表 "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "li",
                                              { staticClass: "ant-list-item" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ant-list-item-meta",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-avatar",
                                                      },
                                                      [
                                                        _c("svg-icon", {
                                                          staticStyle: {
                                                            width: "30px",
                                                            height: "30px",
                                                          },
                                                          attrs: {
                                                            "icon-class":
                                                              "数据",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "ant-list-item-meta-content",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-title",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.agGridDialogVisible = true
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "专家评审汇总表2 "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "ant-list-item-meta-description",
                                                          },
                                                          [
                                                            _vm._v(
                                                              " 各种统计类报表 "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                  ]),
                                ]),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
          ])
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "这是xxx报表",
            visible: _vm.agGridDialogVisible,
            width: "80%",
            "destroy-on-close": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.agGridDialogVisible = $event
            },
          },
        },
        [
          _c("ag-grid-table", {
            attrs: {
              columnDefs: _vm.columnDefs,
              rowData: _vm.rowData,
              autoSizeStrategy: _vm.autoSizeStrategy,
              defaultExcelExportParams: _vm.defaultExcelExportParams,
              height: "800px",
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }