<template>
  <el-dialog title="视图" :visible="visible" @close="closeDialog">
    <div>
      <!-- 用户管理表格 -->
      <el-table :data="groupList" size="small" :row-class-name="isShowExpend">
        <el-table-column type="expand">
          <template slot-scope="props">
            {{props.row.children}}
            <el-table :data="props.row.children" :show-header="false">
              <el-table-column label="必填" width='80'>
                <template slot-scope="scope">
                  <el-checkbox v-model="scope.row.isCheck" :disabled="scope.row.disabled"></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column label="查看" prop="name"></el-table-column>
              <el-table-column label="说明" prop="desc"></el-table-column>
              <el-table-column label="类型" prop="type"></el-table-column>
              <el-table-column label="值">
              </el-table-column>
            </el-table>

          </template>
        </el-table-column>
        <el-table-column label="查看"></el-table-column>
        <el-table-column label="修改"></el-table-column>
        <el-table-column label="删除"></el-table-column>
        <el-table-column label="删除"></el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="closeDialog">关闭</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { projectGroup } from "@/api/system/form"
export default {
  props: {
    visible: {
      default: false,
      type: Boolean
    },
    agencyId: {
      type: Number,
      default: 0
    },
    userGroupId: {
      type: Number | String,
      default: 0
    },
    projectId: {
      type: String,
      default: ''
    }
  },
  components: {
  },
  data () {
    return {
      search: "",
      currentPage: 1,
      total: 10,
      // 分页相关属性
      pageNum: 1,
      pageSize: 10,

      //表单的分类
      groupList: []
    };
  },
  computed: {

  },
  methods: {
    isShowExpend ({ row }, index) {
      console.log('row', row)
      if (!row.children || row.children.length == 0) {
        return 'hide-expend'
      }
      return ''
    },
    clearSearch () {
      this.search = "";
    },
    handleSizeChange (newSize) {
      this.pageSize = newSize;
    },
    handleCurrentChange (newPage) {
      this.currentPage = newPage;
    },
    openUserDialog () {
      this.$refs.userSelect.showDialog(this.userList, {});
    },
    closeDialog () {
      this.$emit("update:visible", false)
    },
    removeUser (user) {
      // 在这里添加移除用户的逻辑
      console.log("移除用户", user);
    },
    moveUser (user) {
      // 在这里添加移动用户的逻辑
      console.log("移动用户", user);
    },
    getProjectGroup () {
      projectGroup(this.projectId).then(res => {
        this.groupList.splice(0);

        res.data.formGroupList.forEach(x => {
          this.groupList.push(x);
        });
      })
    },
    onHandleQuery () {
      this.getUserList();
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.getProjectGroup();
      }
    }
  }
};
</script>

<style scoped>
.pagination-container {
}
</style>

<style lang="scss" scoped>
::v-deep .hide-expend .el-table__expand-icon {
  display: none;
}
</style>