<template>
  <div class="swiperBox">
    <img class="banner" :src="imagePlaceHolder" width="100%" />
    <el-carousel
      class="swiperBox-m"
      loop
      :interval="4000"
      height="100%"
    >
      <el-carousel-item
        class="swiperBox-m-01"
        v-for="(item, index) in formDesign.bannerFiles"
        :key="index"
        :style="`background-image: url(${item.url})`"
      >
      </el-carousel-item>
    </el-carousel>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  props:{    
    formGroupList: {
      type: Array,
      default: () => [],
    },
    formDesign:{
      type:Object,
      default: ()=>{}
    }
  },
  data() {
    return {
      imagePlaceHolder: "",
      input3: "",

      swiperList: [
        {
          name: "xx",
          url: require("@/assets/webview/img/banner1.jpg"),
        },
        {
          name: "xx",
          url: require("@/assets/webview/img/banner1.jpg"),
        },
        {
          name: "xx",
          url: require("@/assets/webview/img/banner1.jpg"),
        },
      ],
    };
  },
  mounted() {
    this.generateTransparentImage();
  },
  methods: {
    async generateTransparentImage() {
      // 创建一个canvas元素
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      // 设置canvas尺寸，这里以100x100为例
      canvas.width = 2.8;
      canvas.height = 1;

      // 绘制透明矩形
      ctx.fillStyle = "rgba(0, 0, 0, 0)"; // 透明颜色
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 将canvas内容转为DataURL
      const dataURL = canvas.toDataURL("image/png"); // 导出为PNG格式

      // 将生成的透明图片URL赋值给data中的imageUrl
      this.imagePlaceHolder = dataURL;
    },
  },
};
</script>
<style lang="scss" scoped>
.swiperBox {
  position: relative;
  flex-shrink: 0;
  box-sizing: border-box;
  .banner {
    width: 100%;
    margin: 0px;
    display: block;
    flex-shrink: 0;
  }

  .swiperBox-m {
    position: absolute;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    flex-shrink: 0;
    .swiperBox-m-01 {
      background-size: cover;
      background-position: center center;
      flex-shrink: 0;
    }
  }
  // & >>> .el-carousel__indicators{
  //   // position: relative;
  //   // top: -20px;
  // }
}
.swiperBox::v-deep .el-carousel__indicators .is-active .el-carousel__button {
  background: #666666 !important;
}
</style>
