<template>
  <div style="width: 100%; text-align: center">
    <WebViewHeader :formDesign="formDesign" :isLogin="isLogin" @go-login="gotoLogin"></WebViewHeader>
    <WebViewBanner :formDesign="formDesign"></WebViewBanner>
    <WebViewMenu :formDesign="formDesign" :formGroupList="formGroupList" :isLogin="isLogin" @gotoLogin="gotoLogin"></WebViewMenu>
    <WebViewIntroduce :formDesign="formDesign" :fileList="fileList" :projectDetail="projectDetail"></WebViewIntroduce>
    <template v-for="(item,index) in formDesign.extra">      
      <WebViewRich :formDesign="formDesign" :projectDetail="projectDetail" :value="item" v-if="item.type=='richtext'"  v-key="index"></WebViewRich>
      <WebViewPicture :formDesign="formDesign" :projectDetail="projectDetail" :value="item" v-if="item.type=='pictures'"  v-key="index"></WebViewPicture>
      <WebViewVideo :formDesign="formDesign" :projectDetail="projectDetail" :value="item" v-if="item.type=='video'" v-key="index"></WebViewVideo>
    </template>
    <WebViewFooter :formDesign="formDesign"></WebViewFooter>
  </div>
</template>
<script>
import { getAgency } from '@/api/system/agency'
import WebViewHeader from "@/views/system/webView/app/header";
import WebViewBanner from "@/views/system/webView/app/banner";
import WebViewMenu from "@/views/system/webView/app/menu";
import WebViewIntroduce from "@/views/system/webView/introduce";
import WebViewRich from "@/views/system/webView/content-rich";
import WebViewVideo from "@/views/system/webView/content-video";
import WebViewPicture from "@/views/system/webView/content-picture";
import WebViewFooter from "@/views/system/webView/footer";

import { saveFormDesign, getFormDesign } from "@/api/system/designView";
import { mapState } from "vuex";
export default {
  components: {
    WebViewHeader,
    WebViewBanner,
    WebViewMenu,
    WebViewIntroduce,
    WebViewRich,
    WebViewVideo,
    WebViewPicture,
    WebViewFooter,
  },
  computed:{    
    ...mapState({
      viewName: state => state.designView.viewName
    }),
  },
  props: {
    agencyId: {
      type: Number||String,
      default: () => 0,
    },
    projectDetail: {
      type: Object,
      default: () => {},
    },
    formGroupList: {
      type: Array,
      default: () => [],
    },
    fileList:{
      type:Array,
      default:()=>[]
    },
    isLogin:{
      type: Boolean,
      default: () => true,
    }
  },
  data() {
    return {
      projectInfo: {},
      formDesign: {},
    };
  },
  mounted() {
    this.getAgency();
    this.getGetDesign();
  },
  methods: {
    getAgency () {
      if(this.projectDetail.agencyId  && this.isLogin)
      getAgency(this.projectDetail.agencyId).then(res => {
        this.logoImg = res.data.leftTopLogo || res.data.logo
        this.$store.dispatch('SetDesignViewLogo',this.logoImg);
      })
    },
    getGetDesign() {
      getFormDesign("1", this.projectDetail.projectId).then((res) => {
        res.fieldConfig.forEach(x=>{
          console.log(x.type, x.config);
          try{
          this.$set(this.formDesign,x.type, x.config);
          }catch(e){}
        })
      });
    },
    gotoLogin(){
        this.$emit('go-login')
    }
  },
};
</script>
