<template>
  <div class="headMain">
    <div class="headBox">
      <div class="leftBox">
        <img :src="agencyLog" alt="" style="height:40px;width:unset" />
        <div class="leftBox-title" v-html="pageTitle"></div>
      </div>
      <div class="rightBox">
        <div>
          <el-input
            class="searchBox"
            :style="`border: 1px solid ${formDesign.themeColor}`"
            placeholder="请输入关键字"
            v-model="search"
          >
            <el-button
              :style="`
                background: ${formDesign.themeColor};
                color: #fff;
                border-radius: 0%;
                border: 1px solid ${formDesign.themeColor};
                margin: -10px -22px;
              `"
              type="warning"
              slot="append"
              >搜索</el-button
            >
          </el-input>
        </div>
        <div class="loginlogo" :class="[viewName]" >
          <template v-if="isLogin">
              <TitleUserMenu @click="toBaseView"/>
          </template>
          <template v-else>
            <template v-if="formDesign.unloginLogo && Array.isArray(formDesign.unloginLogo) && formDesign.unloginLogo[0]">
              <img :src="formDesign.unloginLogo[0].url" height="90" @click="gotoLogin"/>
            </template>
            <template v-else>
              <img :src="require('@/assets/images/user-avatar-default.png')" width="35" height="35" @click="gotoLogin"/>
            </template>
          </template>
          <!-- <img :src="require('@/assets/webview/img/huilogo.png')" alt="" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import TitleUserMenu from "@/components/TitleUserMenu/index.vue";
export default {
  props:{
    formDesign:{
      type:Object,
      default: ()=>{}
    },
    isLogin:{
      type: Boolean,
      default:()=>{}
    }
  },
  computed:{
    ...mapState({
      agencyLog: (state) => state.designView.agencyLog,
      viewName: state => state.designView.viewName

    }),
    pageTitle: function(){
      return (this.formDesign.pageTitle||'').replace('\n','<br>')
    }
  },
  components: {
    TitleUserMenu
  },
  data(){
    return {
      search: ''
    }
  },
  methods:{
    toBaseView(){
      this.$store.dispatch('EnableDesignView','baseView')
    },
    gotoLogin(){
      this.$emit("go-login")
    }
  }
}
</script>
<style lang="scss" scoped>
@media (min-width: 768px) {
  .headMain {
    box-sizing: border-box;
    padding: 20px 40px;
    width: 100%;
    .headBox {
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .leftBox {
        display: flex;
        align-items: center;
        img {
          // width: 300px;
          display: inline-block;
        }
        .leftBox-title {
          font-style: italic;
          font-size: 28px;
          font-weight: bold;
          letter-spacing: 4px;
          margin-left: 20px;
          text-align: left;
        }
      }
      .rightBox {
        display: flex;
        align-items: center;
        .searchBox {
        }
        .loginlogo {
          margin-left: 40px;
          img {
            // width: 100px;
            cursor: pointer;
          }

          &.webView{
            ::v-deep .menu-agency-name{
              color: #5c5c5c!important;
            }

            ::v-deep .el-icon-arrow-down{
              color: #5c5c5c!important;
            }

            ::v-deep .el-icon-bell{
              color: #5c5c5c!important;
            }
          }
        }
      }
    }
  }
}
</style>
