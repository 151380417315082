import request from '@/utils/request'
// 用户组相关API


/**
 * 获取用户组列表
 * @param {Object} query - 查询参数
 * @returns {Promise} - 返回一个Promise对象，该对象的值是用户组列表
 */
export function listUserGroup(query) {
  return request({
    url: '/userGroup/list',
    method: 'get',
    params: query
  })
}


/**
 * 获取用户组信息
 * @returns {Promise} 返回一个Promise对象，该对象的值是用户组信息
 */
export function getUserGroupInfo(id) {
  return request({
    url: `/userGroup/${id}`,
    method: 'get'
  })
}


/**
 * 添加用户组
 * @param {Object} data - 用户组数据
 * @returns {Promise} - 返回一个Promise对象，该对象的值是添加用户组的响应数据
 */
export function addUserGroup(data) {
  return request({
    url: '/userGroup',
    method: 'post',
    data: data
  })
}


/**
 * 更新用户组信息
 * @param {Object} data - 用户组信息
 * @returns {Promise} - 返回一个Promise对象，该对象的值是API请求的响应数据
 */
export function updateUserGroup(data) {
  return request({
    url: '/userGroup',
    method: 'put',
    data: data
  })
}


/**
 * 删除用户组
 * @param {string} userId - 用户ID
 * @returns {Promise} - 返回一个Promise对象，该对象的值是删除操作的结果
 */
export function delUserGroup(userId) {
  return request({
    url: '/userGroup/' + userId,
    method: 'delete'
  })
}

/**
 * 添加用户到用户组
 * @param {Object} data - 用户数据
 * @returns {Promise} - 返回一个Promise对象，该对象的值是请求的结果
 */
export function addUserToGroup(data) {
  return request({
    url: '/userGroup/member/addMember',
    method: 'post',
    data: data
  })
}


/**
 * 获取用户组成员列表
 * @param {string} userGroupId 用户组ID
 * @param {object} query 查询参数
 * @returns {Promise} 返回用户组成员列表的Promise对象
 */
export function getUserGroupMemberList(userGroupId) {
  return request({
    url: `/userGroup/member/list/${userGroupId}`,
    method: 'get'
  })
}

/**
 * 从用户组删除用户
 * @param {Object} data - 用户数据
 * @returns {Promise} - 返回一个Promise对象，该对象的值是请求的结果
 */
export function delUserFromGroup(userGroupId, type, id) {
  return request({
    url: `/userGroup/member/delete/${userGroupId}/${type}/${id}`,
    method: 'get'
  })
}

/**
 * 移动用户到用户组
 * @param {string} fromUserGroupId - 原用户组ID
 * @param {string} toUserGroupId - 目标用户组ID
 * @param {string} type - 移动类型，1为加入，2为移除
 * @param {string} id - 用户ID
 * @returns {Promise} - 返回一个Promise对象
 */
export function moveUserFromGroup(fromUserGroupId, toUserGroupId, type, id) {
  return request({
    url: `/userGroup/member/move/${fromUserGroupId}/${toUserGroupId}/${type}/${id}`,
    method: 'get'
  })
}