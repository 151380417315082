var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "user-group-page" },
    [
      _c(
        "el-form",
        { attrs: { inline: true, "label-position": "right" } },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 4 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "" } },
                    [
                      _c("el-input", {
                        attrs: {
                          placeholder: "请输入用户组名称",
                          clearable: "",
                          size: "mini",
                        },
                        model: {
                          value: _vm.queryParams.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.queryParams, "name", $$v)
                          },
                          expression: "queryParams.name",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 6 } },
                [
                  _c(
                    "el-form-item",
                    [
                      _c(
                        "el-button",
                        {
                          staticClass: "filter-item",
                          attrs: {
                            type: "primary",
                            icon: "el-icon-search",
                            size: "mini",
                          },
                          on: { click: _vm.onHandleQuery },
                        },
                        [_vm._v("搜索")]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { icon: "el-icon-refresh", size: "mini" },
                          on: { click: _vm.onResetQuery },
                        },
                        [_vm._v("重置")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { staticStyle: { "text-align": "right" }, attrs: { span: 14 } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        icon: "el-icon-plus",
                        size: "mini",
                      },
                      on: { click: _vm.onHandleAdd },
                    },
                    [_vm._v("新增")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%", cursor: "pointer" },
          attrs: { data: _vm.table.data },
        },
        [
          _c("el-table-column", {
            attrs: { prop: "groupName", label: "用户组名称", align: "left" },
          }),
          _c("el-table-column", {
            attrs: { width: "240", label: "", align: "center" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-user",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleUserGroupClick(scope.row)
                          },
                        },
                      },
                      [_vm._v("成员")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-edit",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleUpdateClick(scope.row)
                          },
                        },
                      },
                      [_vm._v("修改")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: {
                          size: "mini",
                          type: "text",
                          icon: "el-icon-delete",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.onHandleDeleteClick(scope.row)
                          },
                        },
                      },
                      [_vm._v("删除")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.onHandleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.dialogTitle,
            visible: _vm.userGroupDialog,
            width: "700px",
            "append-to-body": "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.userGroupDialog = $event
            },
            close: _vm.onCloseDialogClick,
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "userGroupForm",
              attrs: {
                model: _vm.addUserGroupForm,
                rules: _vm.addUserGroupRules,
                size: "small",
                "label-width": "100px",
                "label-position": "top",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户组名称", prop: "groupName" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入用户组名称",
                              disabled: _vm.dialogFormMode === "view",
                            },
                            model: {
                              value: _vm.addUserGroupForm.groupName,
                              callback: function ($$v) {
                                _vm.$set(_vm.addUserGroupForm, "groupName", $$v)
                              },
                              expression: "addUserGroupForm.groupName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "用户组描述", prop: "description" } },
                        [
                          _c("el-input", {
                            attrs: {
                              placeholder: "请输入用户组描述",
                              disabled: _vm.dialogFormMode === "view",
                            },
                            model: {
                              value: _vm.addUserGroupForm.description,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.addUserGroupForm,
                                  "description",
                                  $$v
                                )
                              },
                              expression: "addUserGroupForm.description",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "分发哪些应用项", prop: "groupType" },
                        },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.addUserGroupForm.groupType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.addUserGroupForm,
                                    "groupType",
                                    $$v
                                  )
                                },
                                expression: "addUserGroupForm.groupType",
                              },
                            },
                            [
                              _c("el-radio", { attrs: { label: 0 } }, [
                                _vm._v("所有应用表单"),
                              ]),
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("选择应用表单"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.addUserGroupForm.groupType == 0
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "权限", prop: "authType" } },
                            [
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "100%" },
                                  attrs: { placeholder: "" },
                                  model: {
                                    value: _vm.addUserGroupForm.authType,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.addUserGroupForm,
                                        "authType",
                                        $$v
                                      )
                                    },
                                    expression: "addUserGroupForm.authType",
                                  },
                                },
                                _vm._l(
                                  _vm.authTypeList,
                                  function (authItem, authIndex) {
                                    return _c("el-option", {
                                      key: authIndex,
                                      attrs: {
                                        label: authItem.name,
                                        value: authItem.value,
                                      },
                                    })
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.addUserGroupForm.groupType == 1
                ? _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            { attrs: { label: "选择应用表单" } },
                            [
                              _c("UserGroupView", {
                                attrs: {
                                  formList: _vm.formList,
                                  userGroupId: _vm.userGroupId,
                                  agencyId: _vm.agencyId,
                                  projectId: _vm.projectId,
                                },
                                model: {
                                  value: _vm.applyFormView,
                                  callback: function ($$v) {
                                    _vm.applyFormView = $$v
                                  },
                                  expression: "applyFormView",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "操作权限", prop: "operateAuth" } },
                        _vm._l(
                          _vm.operateAuthList,
                          function (actionItem, actionIndex) {
                            return _c(
                              "el-checkbox",
                              {
                                key: actionIndex,
                                staticClass: "inline-box",
                                attrs: { label: actionItem.value },
                                model: {
                                  value:
                                    _vm.addUserGroupForm.operateAuth[
                                      actionItem.value
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.addUserGroupForm.operateAuth,
                                      actionItem.value,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "addUserGroupForm.operateAuth[actionItem.value]",
                                },
                              },
                              [_vm._v(_vm._s(actionItem.name))]
                            )
                          }
                        ),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.dialogFormMode !== "view"
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onSubmitFormClick },
                    },
                    [_vm._v("确 定")]
                  ),
                  _c("el-button", { on: { click: _vm.onCloseDialogClick } }, [
                    _vm._v("取 消"),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.dialogFormMode === "view"
            ? _c(
                "div",
                {
                  staticClass: "dialog-footer",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c("el-button", { on: { click: _vm.onCloseDialogClick } }, [
                    _vm._v("关 闭"),
                  ]),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c("dialogUser", {
        attrs: {
          visible: _vm.showUserDialog,
          userGroupId: _vm.userGroupId,
          agencyId: _vm.agencyId,
          userGroupList: _vm.table.data,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showUserDialog = $event
          },
        },
      }),
      _c("dialogView", {
        attrs: {
          visible: _vm.showViewDialog,
          userGroupId: _vm.userGroupId,
          agencyId: _vm.agencyId,
          projectId: _vm.projectId,
        },
        on: {
          "update:visible": function ($event) {
            _vm.showViewDialog = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }