var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    _vm._l(_vm.formGroupList, function (menu, index) {
      return _c("div", { key: index, staticClass: "menu" }, [
        _c("span", { staticClass: "menu-text" }, [_vm._v(_vm._s(menu.name))]),
        _c(
          "div",
          { staticClass: "menu-content" },
          _vm._l(menu.children, function (smenu, sindex) {
            return _c(
              "div",
              {
                key: sindex,
                staticClass: "menu-content-item",
                on: {
                  click: function ($event) {
                    return _vm.naviToForm(smenu.formId)
                  },
                },
              },
              [_c("div", [_vm._v(_vm._s(smenu.name))])]
            )
          }),
          0
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }