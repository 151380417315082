<template>
  <div class="edit-icon-wrapper" :class="{ 'show-menu' : showIconSelect }">
    <div v-if="showHint">请选择图标</div>
    <div class="icon-wrapper" @click="selectIcon" :style="{width:width?width:'201px'}">
      <div :class="[icon,value==icon?'active':'']" class="icon-item" v-for="icon in icons" :key="icon"></div>
    </div>
  </div>
</template>
<script>
export default {
    props:{
        value:String,
        showIconSelect:Boolean,
        width:String,
        showHint:{
            type:Boolean,
            default:true
        }
    },
    data(){
        return {
            icons:[
                'el-icon-date',
                'el-icon-edit',
                'el-icon-document-checked',
                'el-icon-document',
                'el-icon-monitor',
                'el-icon-attract',
                'el-icon-printer',
                'el-icon-magic-stick',
                'el-icon-coordinate',
                'el-icon-mouse',
                'el-icon-mobile',
                'el-icon-scissors',
                'el-icon-chat-line-square',
                'el-icon-headset',
                'el-icon-brush',
                'el-icon-pie-chart',
                'el-icon-collection-tag',
                'el-icon-message',
                'el-icon-medal',
                'el-icon-discover'
            ]
        }
    },
    methods:{
        selectIcon(e) {
            let iconName = e.target.className.split(' ')[1]
            if(iconName==this.value){
                this.$emit("input",'')
                this.$emit("on-selected",'')
            }else{
                this.$emit("input",iconName)
                this.$emit("on-selected",iconName)
            }
        }
    }
}
</script>
<style lang="scss" scoped>

.icon-wrapper {
  width: 201px;
  display: inline-flex;
  flex-wrap: wrap;
  box-sizing: border-box;

  border-top: solid 1px #eaeefb;
  border-right: solid 1px #eaeefb;
  border-bottom: none;

  .icon-item {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: cell;
    border-collapse: collapse;
    border: solid 1px #eaeefb;
    border-top: 0px;
    box-sizing: border-box;
    border-right: none;
    cursor: pointer;

    &:nth-child(5n) {
      border-right: none;
    }
    &:hover, &.active{
      background-color: #FAFBFB;
      color: 646D7A ;
    }
  }
}
</style>