var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "填报地址",
        visible: _vm.shareOpen,
        width: "1000px",
        "append-to-body": "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.shareOpen = $event
        },
        close: _vm.handleFillDiaClose,
      },
    },
    [
      _c("div", { staticClass: "share-container" }, [
        _c(
          "h5",
          {
            staticStyle: {
              "font-size": "20px",
              color: "#404040",
              margin: "20px 0 10px",
            },
          },
          [_vm._v("链接分享")]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-size": "12px",
              color: "#909090",
              "margin-bottom": "10px",
            },
          },
          [_vm._v("将填报地址粘贴到浏览器访问，或者直接发给填报者")]
        ),
        _c(
          "div",
          { staticStyle: { width: "100%", height: "auto" } },
          [
            _c("el-input", {
              staticClass: "fl",
              staticStyle: { width: "60%", "margin-right": "40px" },
              attrs: { type: "text", value: _vm.shareFillUrl, readonly: "" },
            }),
            _c(
              "p",
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.onCopyFillClick },
                  },
                  [_vm._v("复制链接")]
                ),
                _c(
                  "el-button",
                  {
                    staticStyle: { "margin-left": "20px" },
                    attrs: { type: "primary" },
                    on: { click: _vm.onToClick },
                  },
                  [_vm._v("直接打开")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "h5",
          {
            staticStyle: {
              "font-size": "20px",
              color: "#404040",
              margin: "20px 0 10px",
            },
          },
          [_vm._v("小程序码分享")]
        ),
        _c(
          "p",
          {
            staticStyle: {
              "font-size": "12px",
              color: "#909090",
              "margin-bottom": "10px",
            },
          },
          [_vm._v("选择小程序码样式，保存小程序码直接发给填报者")]
        ),
        _c("img", {
          staticClass: "list-share-image",
          attrs: { src: _vm.shareImageUrl },
        }),
        _c(
          "div",
          {
            staticStyle: {
              width: "398px",
              margin: "auto",
              "text-align": "center",
            },
          },
          [
            _c(
              "el-button",
              {
                staticStyle: { margin: "20px auto 0" },
                attrs: { type: "primary" },
                on: { click: _vm.onSaveClick },
              },
              [_vm._v("保存小程序码")]
            ),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }