var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "headMain" }, [
    _c("div", { staticClass: "headBox" }, [
      _c("div", { staticClass: "leftBox" }, [
        _c("img", {
          staticStyle: { height: "40px", width: "unset" },
          attrs: { src: _vm.agencyLog, alt: "" },
        }),
        _c("div", {
          staticClass: "leftBox-title",
          domProps: { innerHTML: _vm._s(_vm.pageTitle) },
        }),
      ]),
      _c("div", { staticClass: "rightBox" }, [
        _c(
          "div",
          [
            _c(
              "el-input",
              {
                staticClass: "searchBox",
                style: "border: 1px solid " + _vm.formDesign.themeColor,
                attrs: { placeholder: "请输入关键字" },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              },
              [
                _c(
                  "el-button",
                  {
                    style:
                      "\n              background: " +
                      _vm.formDesign.themeColor +
                      ";\n              color: #fff;\n              border-radius: 0%;\n              border: 1px solid " +
                      _vm.formDesign.themeColor +
                      ";\n              margin: -10px -22px;\n            ",
                    attrs: { slot: "append", type: "warning" },
                    slot: "append",
                  },
                  [_vm._v("搜索")]
                ),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "loginlogo", class: [_vm.viewName] },
          [
            _vm.isLogin
              ? [_c("TitleUserMenu", { on: { click: _vm.toBaseView } })]
              : [
                  _vm.formDesign.unloginLogo &&
                  Array.isArray(_vm.formDesign.unloginLogo) &&
                  _vm.formDesign.unloginLogo[0]
                    ? [
                        _c("img", {
                          attrs: {
                            src: _vm.formDesign.unloginLogo[0].url,
                            height: "90",
                          },
                          on: { click: _vm.gotoLogin },
                        }),
                      ]
                    : [
                        _c("img", {
                          attrs: {
                            src: require("@/assets/images/user-avatar-default.png"),
                            width: "35",
                            height: "35",
                          },
                          on: { click: _vm.gotoLogin },
                        }),
                      ],
                ],
          ],
          2
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }