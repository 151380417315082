var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "form-view-table" },
    [
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.formList,
            size: "small",
            "row-class-name": _vm.isShowExpend,
            "cell-style": { padding: "0px;" },
          },
          on: { "expand-change": _vm.handleExpandChange },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand", width: "30px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (props) {
                  return [
                    _c(
                      "el-table",
                      {
                        attrs: {
                          data: props.row.children,
                          "show-header": false,
                          "cell-style": { padding: "0px" },
                        },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            label: "查看",
                            width: "60px",
                            align: "right",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-cell-right" },
                                      [
                                        _c("el-checkbox", {
                                          model: {
                                            value: props.row.enable,
                                            callback: function ($$v) {
                                              _vm.$set(props.row, "enable", $$v)
                                            },
                                            expression: "props.row.enable",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: { prop: "name" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-cell-left" },
                                      [_vm._v(_vm._s(props.row.name))]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "查看",
                            width: "80px",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-cell-center" },
                                      [
                                        _c("el-checkbox", {
                                          model: {
                                            value: props.row.view,
                                            callback: function ($$v) {
                                              _vm.$set(props.row, "view", $$v)
                                            },
                                            expression: "props.row.view",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "修改",
                            width: "80px",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-cell-center" },
                                      [
                                        _c("el-checkbox", {
                                          model: {
                                            value: props.row.edit,
                                            callback: function ($$v) {
                                              _vm.$set(props.row, "edit", $$v)
                                            },
                                            expression: "props.row.edit",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "删除",
                            width: "80px",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-cell-center" },
                                      [
                                        _c("el-checkbox", {
                                          model: {
                                            value: props.row.delete,
                                            callback: function ($$v) {
                                              _vm.$set(props.row, "delete", $$v)
                                            },
                                            expression: "props.row.delete",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "审核",
                            width: "80px",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (props) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "table-cell-center" },
                                      [
                                        _c("el-checkbox", {
                                          model: {
                                            value: props.row.auth,
                                            callback: function ($$v) {
                                              _vm.$set(props.row, "auth", $$v)
                                            },
                                            expression: "props.row.auth",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _c("el-table-column", {
                          attrs: {
                            label: "字段",
                            width: "80px",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (props) {
                                  return props.row.editableFiles !== undefined
                                    ? [
                                        _c(
                                          "el-button",
                                          {
                                            attrs: {
                                              slot: "reference",
                                              type: "text",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.showSetFields(
                                                  props.row
                                                )
                                              },
                                            },
                                            slot: "reference",
                                          },
                                          [_vm._v("设置")]
                                        ),
                                      ]
                                    : undefined
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("el-table-column", { attrs: { label: "名称", prop: "name" } }),
          _c("el-table-column", {
            attrs: { label: "查看", width: "80px", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "修改", width: "80px", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "删除", width: "80px", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "审核", width: "80px", align: "center" },
          }),
          _c("el-table-column", {
            attrs: { label: "字段", width: "80px", align: "center" },
          }),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            title: "设置表单字段",
            visible: _vm.showDrawer,
            "append-to-body": true,
            "with-header": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDrawer = $event
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-content" },
            [
              _c(
                "el-table",
                { attrs: { data: _vm.viewShowFields } },
                [
                  _c("el-table-column", {
                    attrs: { label: "字段名", prop: "headerName" },
                  }),
                  _c("el-table-column", {
                    attrs: { label: "修改", width: "80px", align: "center" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (props) {
                          return [
                            _c(
                              "div",
                              { staticClass: "table-cell-center" },
                              [
                                _c("el-checkbox", {
                                  model: {
                                    value: props.row.edit,
                                    callback: function ($$v) {
                                      _vm.$set(props.row, "edit", $$v)
                                    },
                                    expression: "props.row.edit",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ]),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "drawer-footer" },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "default" },
                      on: { click: _vm.onCancelSaveEditableFields },
                    },
                    [_vm._v("关闭")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.onConfirmEditableFields },
                    },
                    [_vm._v("确定")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }