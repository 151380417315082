var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "edit-icon-wrapper",
      class: { "show-menu": _vm.showIconSelect },
    },
    [
      _vm.showHint ? _c("div", [_vm._v("请选择图标")]) : _vm._e(),
      _c(
        "div",
        {
          staticClass: "icon-wrapper",
          style: { width: _vm.width ? _vm.width : "201px" },
          on: { click: _vm.selectIcon },
        },
        _vm._l(_vm.icons, function (icon) {
          return _c("div", {
            key: icon,
            staticClass: "icon-item",
            class: [icon, _vm.value == icon ? "active" : ""],
          })
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }