<template>
  <div class="content-wrapper">
    <content-title
      :formDesign="formDesign"
      v-model="value.title"
    ></content-title>
    <div class="content-wrapper-content">
      <img
        :src="file.url"
        v-for="(file, index) in value.config.urls"
        :key="index"
        :height="value.config.height"
      />
    </div>
  </div>
</template>
<script>
import contentTitle from "./content-title";
export default {
  components: {
    contentTitle,
  },
  props: {
    formDesign: {
      type: Object,
      default: () => {},
    },
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      title: "标题",
    };
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper {
  width: 100%;
  display: inline-block;
  background-color:white;

  /* 超出自动换行 */
  word-wrap: break-word;
  word-break: break-all;
  white-space: wrap;
  text-align: center;
}
.content-wrapper-content {
  width: 1280px;
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    margin: 15px;
  }
}

.content-wrapper-content {
  ::v-deep .ql-align-left {
    text-align: left;
  }
  ::v-deep .ql-align-right {
    text-align: right;
  }
  ::v-deep .ql-align-center {
    text-align: center;
  }
}
</style>
