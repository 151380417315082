<template>
  <div v-cloak @click="onCloseFormPop">
    <template  v-if="viewName=='baseView'">

    <detail-nav :breadcrumb="breadcrumb" @login-success="loginSuccess" @naviTo1="onProjectNameClick" @naviTo2="onBreadcrumbFormNameClick"></detail-nav>
    <!-- <div class="detail-nav">
      <div class="detail-container app-inner-container">
        <div class="detail-left">
          <div class="detail-left-logo" @click="naviHome">
            <svg-icon icon-class="home" />
          </div>
          <div class="separator"></div>
          <span class="headerNow" @click="onProjectNameClick">{{
            projectDetail.name
          }}</span>
          <template v-if="cur != null && formCur != 1 && formCur != 2">
            <div class="separator"></div>
            <span class="headerNow" @click="onBreadcrumbFormNameClick">{{
              breadcrumbFormName
            }}</span>
            <div class="separator"></div>
            <span class="headerNow" style="cursor: inherit;">{{
              breadcrumbName
            }}</span>
          </template>
        </div>
        <title-user-menu name-color="#2B344199" icon-color="#2B344199"></title-user-menu>
      </div>
    </div> -->
    
    <div class="clearfix app-container app-detail-container">
      <div class="clearfix project-relate-form">
        <div class="relate-form-left fl left-menu" :class="menuExpand ? '' : 'shrink'">
          <div class="menu-tool menu-title" v-if="menuExpand">
            <div class="menu-title-text" @click="onProjectNameClick">
              应用概览
            </div>
            <div class="menu-title-action">
              <div class="menu-title-action-icon" @click="showMenuSearch = !showMenuSearch">
                <el-tooltip class="item" effect="dark" content="搜索" placement="top-start">
                  <svg-icon icon-class="searchNew" />
                </el-tooltip>
              </div>
              <div class="menu-title-action-icon" v-if="projectDetail.userId == loginUserId">
                <el-tooltip class="item" effect="dark" content="创建分组" placement="top-start">
                  <svg-icon icon-class="formAddDir" @click="onShowFormDialog" />
                </el-tooltip>
              </div>
              <div class="menu-title-action-icon" v-if="projectDetail.userId == loginUserId">
                <el-tooltip class="item" effect="dark" content="新建表单" placement="top-start">
                  <svg-icon icon-class="formAdd" @click="onNewFormClick" />
                </el-tooltip>
              </div>
            </div>
          </div>
          <div style="height:100%;overflow-y:auto">
            <div class="menu-tool" v-if="menuExpand && showMenuSearch" style="padding:5px 16px">
              <el-autocomplete v-model="detailFormName" :fetch-suggestions="querySearchName" @select="handleSelect" clearable placeholder="输入表单名称来搜索" :trigger-on-focus="false" size="small" class="nameSearch" style="width: 100%"></el-autocomplete>
            </div>
            <div :style="{ paddingTop: menuExpand ? '0px' : '25px' }">
              <draggable v-model="formGroupList" :options="{
                group: { name: 'group' },
                sort: true,
                handle: '.inner-list-group,.form-icon'
              }" animation="300" tag="ul" @end="onGroupDraggableEnd" :disabled="!loginUserId || projectDetail.userId != loginUserId" ghost-class="ghost" style="padding: 0;margin:0px;" v-if="formGroupList && formGroupList.length > 0">
                <transition-group>
                  <formGroupWrapper v-for="(formGroupItem, groupIndex) in formGroupList" :key="groupIndex" :item="formGroupItem" :isExpand="menuExpand" :expandName="searchHandleGroupId" :page="currentPage" :projectDetail="projectDetail" :loginUserId="loginUserId">
                    <template v-slot:icon>
                      <!-- <svg-icon icon-class="form-group" class="form-icon" :style="{color:menuExpand?'#646D7A':'white',fillColor:'#646D7A'}"/> -->
                      <div class="form-icon element-form-icon el-icon-folder" :style="{cursor: (!!loginUserId && projectDetail.userId == loginUserId) ? 'move' : 'unset' }"></div>
                    </template>
                    <template v-slot:title>
                      <div style="font-size: 14px;height:30px;line-height:30px;overflow:hidden;user-select:none">
                        {{
                        menuExpand
                          ? formGroupItem.name
                          : formGroupItem.name.substring(0, 1).toUpperCase()
                      }}
                      </div>
                    </template>
                    <template v-slot:list>
                      <!-- 收缩后表单分组的列表 -->
                      <draggable v-model="formGroupItem.children" :options="{
                        group: { name: 'formItem' },
                        sort: true,
                        handle: '.inner-list-group,.form-icon'
                      }" animation="300" tag="ul" :componentData="{ groupId: formGroupItem.formId }" @add="onDraggableAdd" @end="onDraggableEnd" :disabled="!loginUserId || projectDetail.userId != loginUserId" ghost-class="ghost" style="padding: 0px; margin:0px; min-height:30px;margin-bottom: 0px">
                        <li v-for="(item, index) in formGroupItem.children" :key="index" :index="index" :data-formId="item.formId" :data-groupId="formGroupItem.formId" data-isGroup="true" class="dragItem" style="height:initial" :class="{
                          active: cur == index && formGroupCur == groupIndex,
                          disabled: !loginUserId || projectDetail.userId != loginUserId?'disabled':''
                        }" :ref="'scroller' + index">
                          <formGroupItemWrapper :isExpand="true" :item="item" :cur="index" :formGroupCur="groupIndex" :page="currentPage" :projectDetail="projectDetail" :loginUserId="loginUserId">
                            <template v-slot:icon v-if="!item.icon">
                              <svg-icon icon-class="formO" class="form-icon" :style="{
                                color: (menuExpand ? 'inherit' : 'white'),
                                cursor:
                                  (!!loginUserId && projectDetail.userId == loginUserId)
                                    ? 'move'
                                    : 'unset'
                              }" />
                            </template>
                            <template v-slot:icon v-else-if="item.icon">
                              <div :class="item.icon" class="form-icon element-form-icon" :style="{
                                color: menuExpand ? 'inherit' : 'inherit',
                                cursor: (!!loginUserId && projectDetail.userId == loginUserId)
                                    ? 'move'
                                    : 'unset'
                              }"></div>
                            </template>
                            <template v-slot:title>
                              <div style="height:30px;line-height:30px;overflow:hidden;user-select:none">
                                {{ item.name }}
                              </div>
                            </template>
                          </formGroupItemWrapper>
                        </li>
                        <li :data-groupId="formGroupItem.formId" data-isGroup="true" class="dragItem" :class="{
                          active: cur == groupIndex && formGroupCur == 0
                        }" v-if="
                          !formGroupItem.children ||
                            formGroupItem.children.length == 0
                        ">
                          <div class="formGroupItemEmpty formItem">
                            分组内暂无表单
                          </div>
                        </li>
                      </draggable>
                    </template>
                  </formGroupWrapper>
                </transition-group>
              </draggable>
            </div>
            <!-- 未分组表单列表 -->
            <draggable v-model="formList" :options="{
              group: { name: 'formItem' },
              sort: true,
              handle: '.inner-list-group,.form-icon'
            }" animation="300" tag="ul" @end="onDraggableEnd" :disabled="!loginUserId || projectDetail.userId != loginUserId" ghost-class="ghost" style="padding: 0;margin:0px;min-height:40px;">
              <!-- <transition-group> -->
              <!-- item.dataset.formid -->
              <li v-for="(item, index) in formList" :style="{ width: menuExpand ? 'initial' : '42px' }" :key="index" :index="index" class="dragItem" :data-formId="item.formId" data-isGroup="false" :class="{ active: cur == index && formGroupCur == -1 }" :ref="'scroller' + index">
                <formGroupItemWrapper :isExpand="menuExpand" :item="item" :cur="index" :formGroupCur="-1" :page="currentPage" :projectDetail="projectDetail" :loginUserId="loginUserId">
                  <template v-slot:icon v-if="!item.icon">
                    <div class="form-icon element-form-icon el-icon-document" :style="{
                      color: menuExpand ? '#646D7A' : 'white',
                      cursor: projectDetail.userId == loginUserId ? 'move' : ''
                    }" />
                  </template>
                  <template v-slot:icon v-else-if="item.icon">
                    <div :class="item.icon" class="form-icon element-form-icon" :style="{
                      color: menuExpand ? '#646D7A' : 'white',
                      cursor: projectDetail.userId == loginUserId ? 'move' : ''
                    }"></div>
                  </template>
                  <template v-slot:title v-if="menuExpand">
                    <div style="height:30px;line-height:30px;overflow:hidden;user-select:none">
                      {{ item.name }}
                    </div>
                  </template>
                  <template v-slot:title v-else-if="!menuExpand">
                    <template v-if="item.icon">
                      <div :class="item.icon" class="form-icon element-form-icon" :style="{
                        color: menuExpand ? '#646D7A' : 'white',
                        cursor:
                          projectDetail.userId == loginUserId ? 'move' : ''
                      }"></div>
                    </template>
                    <template v-else>
                      <div style="height:30px;line-height:30px;overflow:hidden;user-select:none">
                        {{ item.name.substring(0, 1).toUpperCase() }}
                      </div>
                    </template>
                  </template>
                </formGroupItemWrapper>
              </li>
              <li data-isGroup="false" class="dragItem" style="height:30px;" v-if="formList.length == 0">
                <div class="formGroupItemEmpty formItem">
                  暂无表单
                </div>
              </li>
              <!-- </transition-group> -->
            </draggable>
          </div>
        </div>
        <div class="relate-form-middle" :class="menuExpand ? '' : 'shrink'">
          <div class="shrink-icon" @click="shrinkMenuClickHandler">
            <svg-icon icon-class="myRightArrow" />
          </div>
        </div>
        <div v-if="showUserGroup" class="relate-form-right fl" :class="menuExpand ? '' : 'shrink'" style="position:relative;">
          <!-- 用户组管理代码 -->
          <userGroup :agencyId="projectDetail.agencyId"></userGroup>
        </div>

        <div v-else class="relate-form-right fl" :class="menuExpand ? '' : 'shrink'" style="position:relative;">
          <info v-if="cur == null || cur == -1" :loginUserId="loginUserId" :projectDetail="projectDetail" :advanced.sync="advanced" />
          <!-- TODO 视图功能暂时去掉了 -->
          <formViews v-else ref="formDetail" :standalone="false" :style="[{ width: formCur == 5 ? '100%' : '100%' }]"></formViews>
          <!-- <div class="form-info-content" v-else :style="[{ width: formCur == 5 ? '100%' : '100%' }]">
            <detail ref="formDetail" :standalone="false" :reloadProjectList="reloadList" @cur_changed="onCurPageChanged" @multipleFormDelete="onMultipleFormDelete"></detail>
          </div> -->
        </div>
      </div>
    </div>
    </template>
    <template v-else-if="viewName=='webView'">
      <WebViewIndex :projectDetail="projectDetail" :fileList="projectDetail.fileList" :formGroupList="formGroupList"></WebViewIndex>
    </template>



    <share ref="share"></share>
    <el-dialog title="移动表单" :visible.sync="formMoveVisible" width="600px" @close="onCloseMoveClick">
      <el-form ref="formMove" :model="formMoveObj" :rules="formMoveRules" label-width="100px" label-position="right" @submit.native.prevent>
        <el-form-item label="移动到应用" prop="projectId">
          <el-col :span="24">
            <el-select v-model="formMoveObj.projectId" placeholder="移动到..." filterable style="width: 100%">
              <el-option value="0" label="移动成为独立表单">移动成为独立表单</el-option>
              <el-option v-for="item in moveFormProjectList" :key="item.projectId" :label="item.name" :value="item.projectId" />
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmMoveClick">确 定</el-button>
        <el-button @click="onCancelMoveClick">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="showFormDialogCreate ? '创建分组' : '编辑分组'" :visible.sync="showFormDialogFlag" width="502px" @close="onSaveFromGroupClick">
      <el-form ref="createGroupForm" :model="formGroup" :rules="formGroupRule" label-width="60px" label-position="right" @submit.native.prevent>
        <el-form-item label="名称" prop="name">
          <el-col :span="24">
            <el-input v-model="formGroup.name" placeholder=""></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmCreateGroupClick">确 定</el-button>
        <el-button @click="showFormDialogFlag = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog title="移动到分组" :visible.sync="showMoveToGroupDialogFlag" width="502px" @close="onSaveFromGroupClick">
      <el-form ref="formGroupList" label-width="100px" label-position="right" @submit.native.prevent>
        <el-form-item label="移动到分组" prop="name">
          <el-col :span="24">
            <el-select v-model="moveToGroupId" placeholder="">
              <el-option :value="opt.formId" :label="opt.name" v-for="opt in formGroupList" :key="opt.formId"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="onConfirmMoveToGroupClick">确 定</el-button>
        <el-button @click="showMoveToGroupDialogFlag = false">取 消</el-button>
      </span>
    </el-dialog>
    <el-dialog :title="'这是xxx报表'" :visible.sync="agGridDialogVisible" width="80%" :destroy-on-close="true">
      <!-- <ag-grid-table :columnDefs="columnDefs" :rowData="rowData" height="800px"></ag-grid-table> -->
    </el-dialog>
  </div>
</template>
<script>
import detail from "./detail";
import info from './info/project.vue';
import formViews from './dataView/views.vue'
import share from "@/components/FavorAndShare";
import { mapState } from 'vuex'
import {
  projectSelectList,
  projectDetail,
  copyForm,
  deleteForm,
  moveForm,
  formSort,
  saveFormGroup,
  projectGroup,
  moveFormToGroup,
  moveFormOutGroup,
  removeFormGroup,
  updateFormGroup,
  editFormIcon,
  projectStatistics,
} from "@/api/system/form";
import { beforeBackConfirm } from "@/utils/formUtil";
import { getSessionStorageObj } from "@/utils/db";
import { getToken } from "@/utils/auth";
import { userBrowseRecord } from "@/api/system/user";
import { agGridTest } from "@/api/system/form";
import {
  getColleges,
  getReportListByProjectId,
  getReportData,
} from "@/api/system/statistics";
import formActionMenu from "./components/formGroup/formActionMenu";
import formGroupWrapper from "./components/formGroup/formGroupWrapper";
import formGroupItemWrapper from "./components/formGroup/formGroupItemWrapper";
import formIconSelector from "./components/formGroup/formIconSelector.vue";
import { deepClone } from "../../../utils";
import TitleUserMenu from "@/components/TitleUserMenu/index.vue";
import userGroup from './userGroup/userGroup.vue'
import WebViewIndex from '@/views/system/webView/app/index'
import DetailNav from '@/components/DetailNav/index.vue'

export default {
  name: "multipleDetail",
  componentName: 'multipleDetail',
  components: {
    TitleUserMenu,
    DetailNav,
    info,
    detail,
    share,
    formActionMenu,
    formGroupWrapper,
    formGroupItemWrapper,
    formIconSelector,
    formViews,
    userGroup,

    WebViewIndex
  },
  computed: {
    ...mapState({
      viewName: state => state.designView.viewName
    }),
    breadcrumb:function(){
      return [
        this.projectDetail.name,
        this.breadcrumbFormName,
        this.breadcrumbName
      ]
    }
  },
  provide(){
    return {
      getFormDetailObj: null
    }
  },
  data () {
    return {
      logoImg:
        "https://smart-form-prod.oss-cn-beijing.aliyuncs.com/statics/pc_logo.png",
      projectId: null,
      projectDetail: {},
      loginUserId: null,
      formList: [],
      formListCopy: [],
      isShowAddFormBtn: true,
      isShow: -1,
      // 新版
      detailFormName: "",
      apiPathUrl: process.env.VUE_APP_BASE_API,
      formStatics: {}, // 表单统计
      fieldList: [],
      formGroupList: [],
      // 移动表单
      formMoveVisible: false,
      formMoveObj: {
        projectId: null,
      },
      formMoveRules: {
        projectId: [
          {
            required: true,
            message: "所移动应用不能为空",
            trigger: ["change"],
          },
        ],
      },
      moveFormId: null,
      moveFormProjectList: [], // 表单移动所选择的应用列表
      cur: null,
      formCur: null,
      formGroupCur: null,
      breadcrumbFormName: "",
      breadcrumbName: "",
      formLeftHeight: document.documentElement.clientHeight - 84,
      fillDataMaxHeight: document.documentElement.clientHeight - 152,
      offsetPopClient: false, // 新版---气泡弹框所在位置(判断当前点击位置+弹框高度是否大于可视高度)
      menuExpand: true,
      showMenuSearch: false,
      currentPage: {
        onFormClick: this.onNavTabClick,
        onEditFormClick: this.onEditFormClick,
        onCopyFormClick: this.onCopyFormClick,
        onMoveFormClick: this.onMoveFormClick,
        onDeleteFormClick: this.onDeleteFormClick,
        onEditFormIcon: this.onEditFormIcon,

        onMoveToGroupFormClick: this.onMoveToGroupFormClick,
        onMoveOutGroupFormClick: this.onMoveOutGroupFormClick,
        onAddGroupFormClick: this.onAddGroupFormClick,
        onEditGroupClick: this.onEditGroupClick,
        onDeleteGroupClick: this.onDeleteGroupClick,
      },

      //表单分组
      showFormDialogFlag: false,
      showFormDialogCreate: true,
      formGroup: {
        formId: "",
        projectId: "",
        name: "",
        icon: "empty",
        sort: 0,
      },
      formGroupRule: {
        name: [
          { required: true, message: "分组名称不能为空", trigger: ["change"] },
        ],
      },
      agGridDialogVisible: false,

      showMoveToGroupDialogFlag: false,
      moveToGroupId: "",
      moveToGroupFormId: "",
      advanced: false,

      searchHandleGroupId: "",
      columnDefs: [
        {
          headerName: "#",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          pinned: true,
          width: 50,
        },
        {
          field: "fenlei",
          headerName: "分类",
        },
        {
          field: "zymc",
          headerName: "专业名称",
        },
        {
          field: "zydm",
          headerName: "专业代码",
        },
        {
          field: "zzzymc",
          headerName: "自主专业名称",
        },
        {
          field: "zzzydm",
          headerName: "自主专业代码",
        },
        {
          field: "nz",
          headerName: "年制",
        },
        {
          field: "bysCount",
          headerName: "毕业生数",
        },
        {
          field: "syxwCount",
          headerName: "授予学位数",
        },
        {
          field: "zssCount",
          headerName: "招生数",
        },
        {
          field: "zxssCount",
          headerName: "在校生数",
        },
        {
          headerName: "各年级在校生数",
          children: [
            {
              field: "yiCount",
              headerName: "一年级数",
            },
            {
              field: "erCount",
              headerName: "二年级数",
            },
            {
              field: "sanCount",
              headerName: "三年级数",
            },
            {
              field: "siCount",
              headerName: "四年级数",
            },
            {
              field: "wuCount",
              headerName: "五年级数",
            },
          ],
        },
        {
          field: "yjbysCount",
          headerName: "预计毕业生数",
        },
      ],
      rowData: [
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "应用经济学",
          nz: "3",
          zymc: "财政学类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0202TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "应用经济学",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "金融",
          nz: "3",
          zymc: "金融_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0251TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "金融",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "社会学",
          nz: "3",
          zymc: "社会学类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0303TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "社会学",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "马克思主义理论",
          nz: "3",
          zymc: "马克思主义理论类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0305TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "马克思主义理论",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 11,
          sanCount: 13,
          bysCount: 3,
          syxwCount: 0,
          zzzydm: "法律(非法学)",
          yjbysCount: 13,
          zssCount: 8,
          zxssCount: 24,
          zzzymc: "法律(非法学)",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 5,
          sanCount: 13,
          bysCount: 4,
          syxwCount: 0,
          zzzydm: "法律(法学)",
          yjbysCount: 13,
          zssCount: 5,
          zxssCount: 18,
          zzzymc: "法律(法学)",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "心理学",
          nz: "3",
          zymc: "体育学类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0402TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "心理学",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 6,
          syxwCount: 3,
          zssCount: 11,
          zxssCount: 21,
          zzzymc: "教育管理",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "045101",
          sanCount: 15,
          bysCount: 5,
          zzzydm: "教育管理",
          yjbysCount: 15,
          yiCount: 0,
        },
        {
          erCount: 24,
          siCount: 23,
          syxwCount: 8,
          zssCount: 24,
          zxssCount: 65,
          zzzymc: "学科教学(语文)",
          nz: "4",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "045103",
          sanCount: 18,
          bysCount: 10,
          zzzydm: "学科教学(语文)",
          yjbysCount: 23,
          yiCount: 0,
        },
        {
          erCount: 9,
          siCount: 14,
          syxwCount: 8,
          zssCount: 11,
          zxssCount: 32,
          zzzymc: "学科教学(数学)",
          nz: "4",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "045104",
          sanCount: 9,
          bysCount: 11,
          zzzydm: "学科教学(数学)",
          yjbysCount: 14,
          yiCount: 0,
        },
        {
          erCount: 6,
          syxwCount: 0,
          zssCount: 7,
          zxssCount: 12,
          zzzymc: "学科教学(物理)",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "045105",
          sanCount: 6,
          bysCount: 0,
          zzzydm: "学科教学(物理)",
          yjbysCount: 6,
          yiCount: 0,
        },
        {
          erCount: 1,
          syxwCount: 0,
          zssCount: 2,
          zxssCount: 1,
          zzzymc: "学科教学(英语)",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "045108",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "学科教学(英语)",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 1,
          syxwCount: 0,
          zssCount: 3,
          zxssCount: 3,
          zzzymc: "学科教学(地理)",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "045110",
          sanCount: 2,
          bysCount: 0,
          zzzydm: "学科教学(地理)",
          yjbysCount: 2,
          yiCount: 0,
        },
        {
          erCount: 3,
          syxwCount: 0,
          zssCount: 4,
          zxssCount: 8,
          zzzymc: "小学教育",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "045115",
          sanCount: 5,
          bysCount: 0,
          zzzydm: "小学教育",
          yjbysCount: 5,
          yiCount: 0,
        },
        {
          erCount: 22,
          syxwCount: 14,
          zssCount: 21,
          zxssCount: 56,
          zzzymc: "心理健康教育",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "045116",
          sanCount: 34,
          bysCount: 13,
          zzzydm: "心理健康教育",
          yjbysCount: 34,
          yiCount: 0,
        },
        {
          erCount: 9,
          syxwCount: 8,
          zssCount: 7,
          zxssCount: 30,
          zzzymc: "特殊教育",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "045119",
          sanCount: 21,
          bysCount: 7,
          zzzydm: "特殊教育",
          yjbysCount: 21,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "体育",
          nz: "2",
          zymc: "体育_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0452TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "体育",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "汉语国际教育",
          nz: "3",
          zymc: "汉语国际教育学科_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0453TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "汉语国际教育",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "中国语言文学",
          nz: "3",
          zymc: "中国语言文学类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0501TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "中国语言文学",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 2,
          syxwCount: 4,
          zssCount: 3,
          zxssCount: 9,
          zzzymc: "外国语言学及应用语言学",
          nz: "3",
          zymc: "普通本科",
          fenlei: "普通本科",
          zydm: "050211",
          sanCount: 7,
          bysCount: 3,
          zzzydm: "外国语言学及应用语言学",
          yjbysCount: 7,
          yiCount: 0,
        },
        {
          erCount: 14,
          syxwCount: 10,
          zssCount: 12,
          zxssCount: 38,
          zzzymc: "英语笔译",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "055101",
          sanCount: 24,
          bysCount: 11,
          zzzydm: "英语笔译",
          yjbysCount: 24,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "新闻与传播",
          nz: "3",
          zymc: "新闻与传播_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0552TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "新闻与传播",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "出版",
          nz: "3",
          zymc: "出版_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0553TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "出版",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "数学",
          nz: "3",
          zymc: "数学类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0701TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "数学",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "物理学",
          nz: "3",
          zymc: "物理学类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0702TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "物理学",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "化学",
          nz: "3",
          zymc: "化学类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0703TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "化学",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "生物学",
          nz: "3",
          zymc: "生物科学类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0710TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "生物学",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "机械工程",
          nz: "3",
          zymc: "机械类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0802TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "机械工程",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "材料科学与工程",
          nz: "3",
          zymc: "能源动力类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0805TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "材料科学与工程",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "信息与通信工程",
          nz: "3",
          zymc: "土木类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0810TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "信息与通信工程",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "控制科学与工程",
          nz: "3",
          zymc: "水利类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0811TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "控制科学与工程",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "计算机科学与技术",
          nz: "3",
          zymc: "测绘类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0812TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "计算机科学与技术",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "土木工程",
          nz: "3",
          zymc: "地质类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0814TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "土木工程",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "水利工程",
          nz: "3",
          zymc: "矿业类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0815TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "水利工程",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "化学工程与技术",
          nz: "3",
          zymc: "轻工类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0817TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "化学工程与技术",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "环境科学与工程",
          nz: "3",
          zymc: "生物工程类专业_普通本科",
          fenlei: "普通本科",
          zydm: "0830TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "环境科学与工程",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          sanCount: 1,
          bysCount: 28,
          syxwCount: 29,
          zzzydm: "机械工程",
          yjbysCount: 1,
          zssCount: 0,
          zxssCount: 1,
          zzzymc: "机械工程",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 0,
          sanCount: 2,
          bysCount: 30,
          syxwCount: 32,
          zzzydm: "材料工程",
          yjbysCount: 2,
          zssCount: 0,
          zxssCount: 2,
          zzzymc: "材料工程",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 0,
          sanCount: 3,
          bysCount: 24,
          syxwCount: 27,
          zzzydm: "控制工程",
          yjbysCount: 3,
          zssCount: 0,
          zxssCount: 3,
          zzzymc: "控制工程",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 0,
          sanCount: 5,
          bysCount: 27,
          syxwCount: 29,
          zzzydm: "计算机技术",
          yjbysCount: 5,
          zssCount: 0,
          zxssCount: 5,
          zzzymc: "计算机技术",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 0,
          sanCount: 6,
          bysCount: 20,
          syxwCount: 15,
          zzzydm: "建筑与土木工程",
          yjbysCount: 6,
          zssCount: 0,
          zxssCount: 6,
          zzzymc: "建筑与土木工程",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 0,
          sanCount: 2,
          bysCount: 18,
          syxwCount: 18,
          zzzydm: "水利工程",
          yjbysCount: 2,
          zssCount: 0,
          zxssCount: 2,
          zzzymc: "水利工程",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 0,
          sanCount: 1,
          bysCount: 36,
          syxwCount: 34,
          zzzydm: "化学工程",
          yjbysCount: 1,
          zssCount: 0,
          zxssCount: 1,
          zzzymc: "化学工程",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 0,
          sanCount: 2,
          bysCount: 16,
          syxwCount: 19,
          zzzydm: "生物工程",
          yjbysCount: 2,
          zssCount: 0,
          zxssCount: 2,
          zzzymc: "生物工程",
          nz: "3",
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "电子信息",
          nz: "3",
          zymc: "电子信息_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0854TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "电子信息",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "机械",
          nz: "3",
          zymc: "机械_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0855TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "机械",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "材料与化工",
          nz: "3",
          zymc: "材料与化工_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0856TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "材料与化工",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "资源与环境",
          nz: "3",
          zymc: "资源与环境_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0857TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "资源与环境",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "土木水利",
          nz: "3",
          zymc: "土木水利_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0859TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "土木水利",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "生物与医药",
          nz: "3",
          zymc: "生物与医药_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "0860TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "生物与医药",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "管理科学与工程",
          nz: "3",
          zymc: "管理科学与工程类专业_普通本科",
          fenlei: "普通本科",
          zydm: "1201TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "管理科学与工程",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "工商管理",
          nz: "3",
          zymc: "工商管理类专业_普通本科",
          fenlei: "普通本科",
          zydm: "1202TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "工商管理",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 4,
          zssCount: 0,
          zxssCount: 5,
          zzzymc: "会计学",
          nz: "3",
          zymc: "普通本科",
          fenlei: "普通本科",
          zydm: "120201",
          sanCount: 5,
          bysCount: 4,
          zzzydm: "会计学",
          yjbysCount: 5,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 2,
          zssCount: 0,
          zxssCount: 1,
          zzzymc: "技术经济及管理",
          nz: "3",
          zymc: "普通本科",
          fenlei: "普通本科",
          zydm: "120204",
          sanCount: 1,
          bysCount: 1,
          zzzydm: "技术经济及管理",
          yjbysCount: 1,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "公共管理",
          nz: "3",
          zymc: "公共管理_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "1252TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "公共管理",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "会计",
          nz: "3",
          zymc: "会计_研究生专业型",
          fenlei: "专业型研究生",
          zydm: "1253TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "会计",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 1,
          syxwCount: 0,
          zssCount: 2,
          zxssCount: 18,
          zzzymc: "工程管理",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "125601",
          sanCount: 17,
          bysCount: 0,
          zzzydm: "工程管理",
          yjbysCount: 17,
          yiCount: 0,
        },
        {
          erCount: 7,
          syxwCount: 0,
          zssCount: 10,
          zxssCount: 24,
          zzzymc: "物流工程与管理",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "125604",
          sanCount: 17,
          bysCount: 0,
          zzzydm: "物流工程与管理",
          yjbysCount: 17,
          yiCount: 0,
        },
        {
          erCount: 0,
          syxwCount: 0,
          zssCount: 0,
          zxssCount: 0,
          zzzymc: "设计学",
          nz: "3",
          zymc: "设计学类专业_普通本科",
          fenlei: "普通本科",
          zydm: "1305TP",
          sanCount: 0,
          bysCount: 0,
          zzzydm: "设计学",
          yjbysCount: 0,
          yiCount: 0,
        },
        {
          erCount: 7,
          syxwCount: 0,
          zssCount: 8,
          zxssCount: 13,
          zzzymc: "音乐",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "135101",
          sanCount: 6,
          bysCount: 1,
          zzzydm: "音乐",
          yjbysCount: 6,
          yiCount: 0,
        },
        {
          erCount: 10,
          syxwCount: 2,
          zssCount: 10,
          zxssCount: 18,
          zzzymc: "美术",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "135107",
          sanCount: 8,
          bysCount: 2,
          zzzydm: "美术",
          yjbysCount: 8,
          yiCount: 0,
        },
        {
          erCount: 12,
          syxwCount: 8,
          zssCount: 9,
          zxssCount: 33,
          zzzymc: "艺术设计",
          nz: "3",
          zymc: "专业型研究生",
          fenlei: "专业型研究生",
          zydm: "135108",
          sanCount: 21,
          bysCount: 11,
          zzzydm: "艺术设计",
          yjbysCount: 21,
          yiCount: 0,
        },
      ],
      zfdxColumnDefs: [
        {
          headerName: "#",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          pinned: true,
          width: 50,
        },
        {
          field: "学号",
          headerName: "学号",
        },
        {
          field: "xingming",
          headerName: "姓名",
        },
        {
          field: "xingbie",
          headerName: "性别",
        },
        {
          field: "shenfenzheng",
          headerName: "身份证号",
        },
        {
          field: "yuanxi",
          headerName: "院系",
        },
        {
          field: "zhuanye",
          headerName: "专业",
        },
        {
          field: "bysCount",
          headerName: "毕业生数",
        },
        {
          field: "syxwCount",
          headerName: "授予学位数",
        },
        {
          field: "zssCount",
          headerName: "招生数",
        },
        {
          field: "zxssCount",
          headerName: "在校生数",
        },
        {
          headerName: "各年级在校生数",
          children: [
            {
              field: "yiCount",
              headerName: "一年级数",
            },
            {
              field: "erCount",
              headerName: "二年级数",
            },
            {
              field: "sanCount",
              headerName: "三年级数",
            },
            {
              field: "siCount",
              headerName: "四年级数",
            },
            {
              field: "wuCount",
              headerName: "五年级数",
            },
          ],
        },
        {
          field: "yjbysCount",
          headerName: "预计毕业生数",
        },
      ],
      showUserGroup: false
    };
  },
  created () {
    this.$store.dispatch('EnableDesignView','')
    this.projectId = this.decode(this.$route.params.id);
    this.formGroup.projectId = this.decode(this.$route.params.id);
    if (!this.isBlank(this.$route.query.formId)) {
      this.formId = this.decode(this.$route.query.formId);
    }

    this.getProjectDetail();

    this.loginUserId = getSessionStorageObj("users")?.userId;

    this.Authorization = getToken();

    this.reserveDate = this.getNowFormatDate();

    if(this.loginUserId){
      userBrowseRecord({
        type: "project",
        typeId: this.projectId,
      });
    }

    // this.getRowData();
  },
  mounted () {
    window.addEventListener("scroll", this.initHeight);
  },
  destroyed () {
    window.removeEventListener("scroll", this.initHeight, false);
  },
  watch: {},
  methods: {
    // 返回事件
    naviHome () {
      if (this.$router.options.from.path === "/login") {
        this.$router.push({
          path: "/index",
        });
      } else {
        this.$router.go(-1);
      }
      // const naviBack = () => {
      //   this.$router.push({
      //     path: '/index'
      //   })
      // }
      // //判断是否处在填报页面(formCur=2),如果是检查表单是否编辑过，给个提示再后退
      // if (this.formCur == 2) {
      //   let vformRender = this.$refs.formDetail.$refs.vformRender;
      //   let onSaveClick = this.$refs.formDetail.onSaveFillClick;

      //   beforeBackConfirm(vformRender, () => { onSaveClick(naviBack) }, naviBack);
      // } else {
      //   naviBack();
      // }
    },
    // 新版 --- 点击导航 项目名称
    onProjectNameClick () {
      const naviBack = () => {
        this.cur = null;
        this.formCur = null;
        this.formId = null;
        this.getProjectDetail();
      };
      this.breadcrumbFormName = '';
      if (this.formCur == 2) {
        let vformRender = this.$refs.formDetail.$refs.vformRender;
        let onSaveClick = this.$refs.formDetail.onSaveFillClick;

        // beforeBackConfirm(
        //   vformRender,
        //   (clearFormHandler) => {
        //     onSaveClick(() => {
        //       clearFormHandler();
        naviBack();
        //     });
        //   },
        //   naviBack
        // );
      } else {
        naviBack();
      }
    },
    // 点击导航 面包屑 表单名称
    onBreadcrumbFormNameClick () {
      // this.getFormDetail(this.formId)
      this.formCur = 1;
      this.$nextTick(() => {
        this.$refs.formDetail.switchCur(1);
      });
    },
    // 应用详情
    getProjectDetail () {
      const _this = this;

      const findFormIdInGroup = (fromGroupItem, formId) => {
        if (!fromGroupItem.children) return -1;
        return fromGroupItem.children.findIndex((x) => x.formId === formId);
      };

      projectGroup(_this.projectId).then((response) => {
        if (response.code == 200) {
          _this.projectDetail = response.data;

          if (_this.projectDetail.userId !== this.loginUserId) {
            this.advanced = true;
          }

          this.$store.dispatch('EnableDesignView',_this.projectDetail.enableDesign? 'webView':'baseView')

          if (_this.projectDetail.fileList)
            _this.projectDetail.fileList = _this.projectDetail.fileList.map(
              (x) => {
                return { ...x, suffixType: this.getSuffix(x.name) };
              }
            );

          if (!_this.isBlank(response.data.formGroupList)) {
            _this.formList = response.data.formGroupList.filter(
              (x) => !x.isGroupType
            );
            _this.formGroupList = response.data.formGroupList.filter(
              (x) => x.isGroupType
            );

            if (!_this.isBlank(_this.formId)) {
              const findFormIndex = _this.formList.findIndex(
                (item) => item.formId == _this.formId
              );
              if (findFormIndex != -1) {
                _this.cur = findFormIndex;
                _this.formCur = 1;
                const curItem = _this.formList[_this.cur];
                _this.onNavTabClick(curItem, _this.cur);
                //   _this.getFormDetail(_this.formId)
                //   _this.getFormAuth(_this.formId)
              } else {
                for (let j = 0; j < _this.formGroupList.length; j++) {
                  let formIndex = findFormIdInGroup(
                    _this.formGroupList[j],
                    _this.formId
                  );
                  if (formIndex >= 0) {
                    _this.onNavTabClick(
                      _this.formGroupList[j].children[formIndex],
                      formIndex,
                      j
                    );
                    break;
                  }
                }
              }
            }
          } else {
            _this.formList = [];
            _this.formGroupList = [];
          }
        }
      });


      if(this.needLogin){
        projectStatistics(_this.projectId).then((response) => {
          if (response.code == 200) {
            this.projectStatistics = response.data;
          }
        });
      }

      // 获取全国高校统计
      getColleges("1716758288843931648").then((response) => {
        if (response.code == 200) {
          this.collegesData = response.data;
        }
      });

      // 获取应用下是否配置了统计报表
      getReportListByProjectId(_this.projectId).then((response) => {
        if (response.code == 200) {
          this.reportList = response.data;
        }
      });
    },
    // 右侧页面跳转事件，更新面包屑
    onCurPageChanged (detail) {
      // this.cur=1;
      this.formCur = detail.cur;
      this.breadcrumbFormName = detail.formDetailName;
      this.breadcrumbName = detail.breadcrumbName;
    },
    // 表单权限
    getFormAuth (formId) {
      // formAuth(formId).then((response) => {
      //   if (response.code == 200) {
      //     this.formAuth = response.data;
      //   }
      // });
    },
    // 新版 左边表单搜索
    querySearchName (queryString, cb) {
      const _this = this;
      const formList = _this.formList;
      const formGroupList = _this.formGroupList;
      const res = [];

      for (let i = 0; i < formGroupList.length; i++) {
        if (formGroupList[i].children) {
          formGroupList[i].children.forEach((c) => {
            res.push({
              value: `${formGroupList[i].name}>${c.name}`,
              formId: c.formId,
              groupId: formGroupList[i].formId,
            });
          });
        }
      }
      for (let i = 0; i < formList.length; i++) {
        res.push({
          value: formList[i].name,
          formId: formList[i].formId,
          groupId: "",
        });
      }
      if (queryString != "") {
        setTimeout(() => {
          const callBackArr = []; // 准备一个空数组，此数组是最终返给输入框的数组
          // 这个res是发请求，从后台获取的数据
          res.forEach((item, index) => {
            // 把数据库做遍历，拿用户输入的这个字，和数据库中的每一项做对比
            if (item.value.indexOf(queryString) > -1) {
              // 大于-1，只要包含就行，不再乎位置
              // 如果有具有关联性的数据
              callBackArr.push(item); // 就存到callBackArr里面准备返回呈现
            }
          });
          // 经过这么一波查询操作以后，如果这个数组还为空，说明没有查询到具有关联的数据，就直接返回给用户暂无数据
          if (callBackArr.length == 0) {
            cb([{ value: "暂无数据", formId: "暂无数据" }]);
          }
          // 如果经过这一波查询操作以后，找到数据了，就把装有关联数据的数组callBackArr呈现给用户
          else {
            cb(callBackArr);
          }
        }, 0);
      }
    },
    handleSelect (selectItem) {
      const formId = selectItem.formId;
      const groupId = selectItem.groupId;

      if (groupId == "") {
        const index = this.formList.findIndex((item) => item.formId == formId);
        // 搜索完成后 需要选中某个表单 并知道选中索引 把选中索引赋值给this.cur 并调用 this.getFormDetail()
        this.cur = index;
        this.formGroupCur = -1;

        this.onNavTabClick(this.formList[index], this.cur, this.formGroupCur);
      } else {
        this.searchHandleGroupId = groupId;
        this.formGroupCur = this.formGroupList.findIndex(
          (x) => x.formId == groupId
        );

        const index = this.formGroupList
          .find((x) => x.formId == groupId)
          .children.findIndex((item) => item.formId == formId);
        this.cur = index;

        this.onNavTabClick(
          this.formGroupList.find((x) => x.formId == groupId).children[index],
          this.cur,
          this.formGroupCur
        );
      }
    },
    handleFocus () {
      this.isShowAddFormBtn = false;
    },
    handleBlur () {
      this.isShowAddFormBtn = true;
    },
    // 左边 表单tab切换
    // onNavTabClick(item, index,formGroupIndex) {
    //   this.cur = index
    //   this.formCur = 1
    //   this.formDetail = {}
    //   this.formAuth = {}
    //   this.formFieldList = []
    //   this.isEditFill = false
    //   this.clearFormDetail()
    //   this.formId = item.formId
    //   this.getFormDetail(item.formId)
    //   this.getFormAuth(item.formId)

    //   // 针对历史表单
    //   this.historyFillId = null
    //   this.currentFormHistory = []
    //   this.isHistoryFill = false
    //   this.historyFillDetailDialog = false
    //   this.historyFillFormFieldList = []
    //   this.historyFillDetail = {}
    //   this.historyFillDetailId = null

    //   // 面包屑
    //   this.breadcrumbFormName = item.name
    // },
    // 左边 表单 点击更多
    onMoreClick (item, index) {
      if (this.isShow == index) {
        this.isShow = -1;
      } else {
        this.isShow = index;
      }
      this.top = 10 * index;
      const clientHeight = document.documentElement.clientHeight;
      const offsetTop = this.$refs[`scroller${index}`][0].offsetTop;
      if (offsetTop + 170 >= clientHeight) {
        this.offsetPopClient = true;
      } else {
        this.offsetPopClient = false;
      }
    },
    // 左边表单pop 点击其他地方关闭
    onCloseFormPop () {
      this.isShow = -1;
      this.top = 1;
    },
    // 复制表单
    onCopyFormClick (item) {
      const _this = this;
      _this
        .$confirm("确认复制该表单？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(function () {
          return copyForm(item.formId);
        })
        .then(() => {
          _this.$message({
            type: "success",
            message: "复制成功",
          });
          _this.getProjectDetail();
          _this.isShow = -1;
          _this.top = 1;
        })
        .catch(function () { });
    },
    onEditFormIconClick (item) {
      console.log("item", item);
    },
    // 编辑表单
    onEditFormClick (item) {
      if (item.type != "page") {
        this.$router.push({
          path: "/form/edit/" + this.encode(item.formId),
          query: {
            name: this.encode(item.name),
            type: this.encode("detail"),
            projectId: this.encode(this.projectId),
          },
        });
      } else {
        this.$router.push({
          path: "/form/edit/" + this.encode(item.formId),
          query: {
            name: this.encode(item.name),
            type: this.encode("detail"),
            create: this.encode("page"),
          },
        });
      }
    },
    reloadList () {
      this.getProjectDetail();
    },
    // 移动表单
    onMoveFormClick (id) {
      this.moveFormId = id;
      this.formMoveVisible = true;
      if (this.$refs["formMove"]) {
        this.$refs["formMove"].clearValidate();
      }
      this.getMoveProject();
    },
    // 删除表单
    onDeleteFormClick (item) {
      const _this = this;
      _this
        .$confirm("确认删除该表单？", "警告", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(function () {
          return deleteForm(item.formId);
        })
        .then(() => {
          _this.$message({
            message: "删除表单成功",
            type: "success",
          });
          _this.formList = [];
          _this.getProjectDetail();
          _this.isShow = -1;
          _this.top = 1;
        })
        .catch(function () { });
    },
    // 移动表单 项目列表
    getMoveProject () {
      const _this = this;
      projectSelectList().then((response) => {
        if (response.code == 200) {
          _this.moveFormProjectList = response.data;
        }
      });
    },
    onConfirmMoveClick () {
      const _this = this;
      this.$refs["formMove"].validate((valid) => {
        if (valid) {
          const param = {
            formId: _this.moveFormId,
            projectId: _this.formMoveObj.projectId,
          };
          moveForm(param).then((response) => {
            if (response.code == 200) {
              _this.msgSuccess("移动成功");
              _this.formMoveVisible = false;
              _this.clearMoveFormData();
              _this.cur = null;
              _this.formCur = null;
              _this.formId = null;
              _this.formList = [];
              _this.getProjectDetail();
            }
          });
        }
      });
    },
    onCancelMoveClick () {
      this.formMoveVisible = false;
      this.clearMoveFormData();
    },
    onCloseMoveClick () {
      this.clearMoveFormData();
    },
    clearMoveFormData () {
      if (this.$refs["formMove"]) {
        this.$refs["formMove"].clearValidate();
      }
      this.moveFormId = null;
      this.formMoveObj = {
        projectId: null,
      };
      this.moveFormProjectList = [];
    },
    // 创建表单
    onNewFormClick () {
      this.$router.push({
        path: "/form/new",
        query: {
          type: this.encode("detail"),
          id: this.encode(this.projectDetail.projectId),
        },
      });
    },
    onDraggableAdd (e) {
      const dragFormId = e.clone.dataset.formid;
      const dropGroupId = e.to.firstChild.dataset.groupid;
      const dropIsGroup = e.to.firstChild.dataset.isgroup == "true";

      const setFormSort = (formList) => {
        const formIds = formList.map((x) => x.formId);

        if (!formIds || formIds.length == 0) return;
        const param = {
          formIds: formIds.toString(),
          projectId: this.projectId,
        };
        formSort(param).then((response) => {
          if (response.code == 200) {
            // this.getFormDetail(formIds[0])
          }
        });
      };

      if (dropIsGroup) {
        //把表单添加到到分组
        this.moveToGroupFormId = dragFormId;
        this.moveToGroupId = dropGroupId;
        this.onConfirmMoveToGroupClick(() => {
          // setFormSort([{ formId: dragFormId }]);
          let formIdList = [];
          e.srcElement.children.forEach((li) => {
            formIdList.push({ formId: li.dataset.formid });
          });

          // console.log( this.formGroupList.find((x) => x.formId == dropGroupId).children)
          setFormSort(
            // this.formGroupList.find((x) => x.formId == dropGroupId).children
            formIdList
          );
        });
      } else {
        //把表单移出分组

        //拖出分组
        moveFormOutGroup(dragFormId);
        //排序
        setFormSort(this.formList);
      }
    },
    // 左边表单 拖拽完成时的事件
    onDraggableEnd (e) {
      let drag = null;
      let dropFormId;
      let dropToGroup;
      let dropGroupId;

      const setFormSort = (formList) => {
        const formIds = formList.map((x) => x.formId);
        if (!formIds || formIds.length == 0) return;
        const param = {
          formIds: formIds.toString(),
          projectId: this.projectId,
        };
        formSort(param).then((response) => {
          if (response.code == 200) {
            // this.getFormDetail(formIds[0])
          }
        });
      };

      const dragFormId = e.clone.dataset.formid;

      if (e.to.children && e.to.children.length > 0) {
        e.to.children.forEach((x) => {
          console.log("x", x, x.dataset);
          if (x.dataset.formid == dragFormId) {
            drag = x;
          }
        });
      }

      if (drag) {
        dropFormId = drag.getAttribute("data-formid");
        dropToGroup = drag.getAttribute("data-isgroup") == "true";
        dropGroupId = drag.getAttribute("data-groupid");
      } else {
        dropToGroup = true;
        dropGroupId = e.to.__vue__.componentData.groupId;
      }

      if (e.item.dataset.isgroup == "false") {
        // 拖动不在分组里的表单
        if (dropToGroup) {
          //如果拖进了分组，加入分组，重设排序
          this.moveToGroupFormId = dragFormId;
          this.moveToGroupId = dropGroupId;
          this.onConfirmMoveToGroupClick();

          setFormSort(
            this.formGroupList.find((x) => x.formId == dropGroupId).children
          );
        } else {
          console.log("sort form");
          //排序
          setFormSort(this.formList);
        }
      } else {
        //拖动在分组里的表单
        if (dropToGroup) {
          console.log("sort group form");
          const dragGroupId = e.item.dataset.groupid;
          if (dragGroupId == dropGroupId) {
            //如果同组拖动，仅排序
            setFormSort(
              this.formGroupList.find((x) => x.formId == dropGroupId).children
            );
          } else {
            console.log("drag group to form");
            //如果拖进了其他分组，判断加入的是哪个分组
            this.moveToGroupFormId = dragFormId;
            this.moveToGroupId = dropGroupId;
            this.onConfirmMoveToGroupClick();
            setFormSort(
              this.formGroupList.find((x) => x.formId == dropGroupId).children
            );
          }
        } else {
          //拖出分组
          moveFormOutGroup(dragFormId);
          //排序
          setFormSort(this.formList);
        }
      }
    },
    // 左边表单 拖拽完成时的事件
    onGroupDraggableEnd (e) {
      const newIndex = e.newIndex;
      this.cur = newIndex;
      const formIds = [];
      for (let i = 0; i < this.formGroupList.length; i++) {
        formIds.push(this.formGroupList[i].formId);
      }
      for (let i = 0; i < this.formList.length; i++) {
        formIds.push(this.formList[i].formId);
      }

      if (!formIds || formIds.length == 0) return;
      const param = {
        formIds: formIds.toString(),
        projectId: this.projectId,
      };
      formSort(param).then((response) => {
        if (response.code == 200) {
          this.getFormDetail(formIds[0]);
        }
      });
    },
    // 获取年月日
    getNowFormatDate () {
      const date = new Date();
      const seperator1 = "-";
      const year = date.getFullYear();
      let month = date.getMonth() + 1;
      let strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      const currentDate = year + seperator1 + month + seperator1 + strDate;
      return currentDate;
    },


    clearFormDetail () {
      const _this = this;
      _this.formData = {};
    },
    // 左边 表单tab切换
    onNavTabClick (item, index, formGroupIndex) {

      let naviBack = () => {
        this.cur = index;
        this.formGroupCur = formGroupIndex;
        this.formCur = 1;
        this.formDetail = {};
        this.formAuth = {};
        this.formFieldList = [];
        this.isEditFill = false;
        this.showUserGroup = false;
        this.clearFormDetail();

        //   this.getFormDetail(item.formId)
        //   this.getFormAuth(item.formId)
        if (item && this.cur >= 0) {
          // 面包屑
          this.formId = this.encode(item.formId);
          this.breadcrumbFormName = item.name;
          this.$nextTick(() => {
            this.$refs.formDetail && this.$refs.formDetail.init("project", item.formId);
          });
        }

        // 针对历史表单
        this.historyFillId = null;
        this.currentFormHistory = [];
        this.isHistoryFill = false;
        this.historyFillDetailDialog = false;
        this.historyFillFormFieldList = [];
        this.historyFillDetail = {};
        this.historyFillDetailId = null;
      };
      let $this = this;
      if (!this.$refs.formDetail) {
        naviBack();
      } else {
        if (this.$refs.formDetail) {
          let vformRender = this.$refs.formDetail.$refs.vformRender;
          let onSaveClick = this.$refs.formDetail.onSaveFillClick;
          naviBack();
        }
      }
    },
    shrinkMenuClickHandler () {
      this.menuExpand = !this.menuExpand;
    },
    onUserGroupClick () {
      this.showUserGroup = true;
    },
    onMultipleFormDelete () {
      this.cur = null;
      this.formCur = 1;
      this.getProjectDetail();
    },
    onShowFormDialog () {
      this.showFormDialogFlag = true;
      this.showFormDialogCreate = true;
    },
    onConfirmCreateGroupClick () {
      this.$refs.createGroupForm.validate(async (valid) => {
        if (valid) {
          let method = this.showFormDialogCreate
            ? saveFormGroup
            : updateFormGroup;

          this.formGroup.sort = this.formGroupList.length + 1;

          const result = await method(this.formGroup);
          if (result.code == 200) {
            this.$message({
              message: `分组${this.showFormDialogCreate ? "创建" : "编辑"}成功`,
              type: "success",
            });
            this.showFormDialogFlag = false;
            this.formGroup.name = "";
            this.formGroup.icon = "";
            this.formGroup.sort = 0;
            this.getProjectDetail();
          } else {
            this.$message({
              message: `分组${this.showFormDialogCreate ? "创建" : "编辑"}失败`,
              type: "error",
            });
          }
        }
      });
    },
    onMoveToGroupFormClick (item) {
      this.moveToGroupFormId = item.formId;
      this.showMoveToGroupDialogFlag = true;
    },
    onConfirmMoveToGroupClick (callback) {
      moveFormToGroup(this.moveToGroupFormId, this.moveToGroupId).then(() => {
        if (callback) callback();
        this.getProjectDetail();
        this.showMoveToGroupDialogFlag = false;
      });
    },
    onMoveOutGroupFormClick (formId) {
      const _this = this;
      _this
        .$confirm("确实要把表单移出分组吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(function () {
          return moveFormOutGroup(formId);
        })
        .then(() => {
          _this.$message({
            type: "success",
            message: "复制成功",
          });
          _this.getProjectDetail();
        })
        .catch(function () { });
    },
    onEditGroupClick (item) {
      this.showFormDialogFlag = true;
      this.showFormDialogCreate = false;
      this.formGroup.formId = item.formId;
      this.formGroup.name = item.name;
    },
    onAddGroupFormClick (item) {
      this.$router.push({
        path: "/form/new",
        query: {
          type: this.encode("detail"),
          id: this.encode(this.projectDetail.projectId),
          groupId: item.formId
        },
      });
    },
    onDeleteGroupClick (item) {
      if (item.children && item.children.length > 0) {
        this.$message({
          type: "error",
          message: "请先删除或移出分组中的表单再删除分组。",
        });
        return;
      }
      const _this = this;
      _this
        .$confirm("确实要删除分组吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(function () {
          return removeFormGroup(item.formId);
        })
        .then(() => {
          _this.$message({
            type: "success",
            message: "删除成功",
          });
          _this.getProjectDetail();
        })
        .catch(function () { });
    },
    onEditFormIcon (item, iconName) {
      editFormIcon(item.formId, iconName).then(() => {
        this.$message({
          type: "success",
          message: "修改成功",
        });
        this.getProjectDetail();
      });
    },
    getSuffix (name) {
      let suffix = name.split(".").pop().toLowerCase();

      let suffixType = null;
      if (
        suffix == "jpeg" ||
        suffix == "JPEG" ||
        suffix == "jpg" ||
        suffix == "JPG" ||
        suffix == "png" ||
        suffix == "PNG"
      ) {
        suffixType = "image";
      } else if (
        suffix == "doc" ||
        suffix == "DOC" ||
        suffix == "docx" ||
        suffix == "DOCX"
      ) {
        suffixType = "word";
      } else if (
        suffix == "exl" ||
        suffix == "EXL" ||
        suffix == "xlsx" ||
        suffix == "XLSX"
      ) {
        suffixType = "excel";
      } else if (suffix == "pdf" || suffix == "PDF") {
        suffixType = "pdf";
      } else if (suffix == "mp3" || suffix == "MP3" || suffix == "wave") {
        suffixType = "audio";
      } else if (
        suffix == "mp4" ||
        suffix == "MP4" ||
        suffix == "mov" ||
        suffix == "m4v" ||
        suffix == "mpg" ||
        suffix == "wma" ||
        suffix == "avi" ||
        suffix == "flv" ||
        suffix == "mkv" ||
        suffix == "rm" ||
        suffix == "rmvb"
      ) {
        suffixType = "video";
      } else if (
        suffix == "zip" ||
        suffix == "ZIP" ||
        suffix == "rar" ||
        suffix == "RAR"
      ) {
        suffixType = "zip";
      } else if (
        suffix == "ppt" ||
        suffix == "PPT" ||
        suffix == "pptx" ||
        suffix == "PPTX"
      ) {
        suffixType = "ppt";
      } else {
        suffixType = "other";
      }

      return suffixType;
    },

    // 数据查看---上传附件---截取附件名
    getSuffName (url) {
      return !this.isBlank(url)
        ? decodeURIComponent(url).substring(url.indexOf("_") + 1)
        : "";
    },
    onDownloadFormFileClick (item) {
      const link = document.createElement("a");
      link.href = item.url;
      link.download = this.getSuffName(item.url);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    onSaveFromGroupClick () { },
    getRowData () {
      agGridTest().then((res) => {
        this.rowData = res.data.rowData;
      });
    },
    getReportAgGridData (url) {
      this.agGridDialogVisible = true;
      getReportData(url).then((res) => {
        this.columnDefs = res.data.columnDefs;
        this.rowData = res.data.rowData;
      });
    },
    loadReportData (name) {
      this.agGridDialogVisible = true
      if (name === '1722176593545134080_综合测评') {
        this.columnDefs = [{
          headerName: "#",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          pinned: true,
          width: 50,
        },
        {
          headerName: "基本信息",
          children: [
            {
              field: "学号",
              headerName: "学号",
            },
            {
              field: "姓名",
              headerName: "姓名",
            },
            {
              field: "性别",
              headerName: "性别",
            },
            {
              field: "身份证号",
              headerName: "身份证号",
            },
            {
              field: "专业",
              headerName: "专业",
            },
            {
              field: "班级",
              headerName: "班级",
            },
            {
              field: "专业人数",
              headerName: "专业人数",
            },
          ],
        },
        {
          headerName: "学习成绩",
          children: [
            {
              field: "学习成绩得分",
              headerName: "学习成绩得分",
            },
            {
              field: "学习成绩本院本专业排名",
              headerName: "本院本专业排名",
            },
            {
              field: "平均绩点",
              headerName: "平均绩点",
            },
          ],
        },
        {
          headerName: "科研和实践成果",
          children: [
            {
              field: "科研和实践成果得分",
              headerName: "科研和实践成果得分",
            },
          ],
        },
        {
          headerName: "综合测评",
          children: [
            {
              field: "综合测评得分",
              headerName: "综合测评得分",
            },
            {
              field: "综合测评本院本专业排名",
              headerName: "本院本专业排名",
            },
          ],
        }]
        this.rowData = [
          {
            "学号": "202301001",
            "姓名": "学生1",
            "性别": "男",
            "身份证号": "370323198707140236",
            "院系": "马克思主义学院",
            "专业": "马克思主义学",
            "班级": "1班",
            "专业人数": "20",
            "学习成绩得分": "99",
            "学习成绩本院本专业排名": "1",
            "平均绩点": "92.10",
            "科研和实践成果得分": "5",
            "综合测评得分": "97.1",
            "综合测评本院本专业排名": "1",

          },
          {
            "学号": "202301002",
            "姓名": "学生2",
            "性别": "男",
            "身份证号": "370323198707140236",
            "院系": "马克思主义学院",
            "专业": "马克思主义学",
            "班级": "2班",
            "专业人数": "20",
            "学习成绩得分": "98",
            "学习成绩本院本专业排名": "2",
            "平均绩点": "90.02",
            "科研和实践成果得分": "3.5",
            "综合测评得分": "93.52",
            "综合测评本院本专业排名": "2",

          },
          {
            "学号": "202301003",
            "姓名": "学生3",
            "性别": "女",
            "身份证号": "370323198707140236",
            "院系": "管理学院",
            "专业": "管理学",
            "班级": "1班",
            "专业人数": "19",
            "学习成绩得分": "96",
            "学习成绩本院本专业排名": "1",
            "平均绩点": "89.11",
            "科研和实践成果得分": "2",
            "综合测评得分": "91.11",
            "综合测评本院本专业排名": "1",

          },
          {
            "学号": "202301004",
            "姓名": "学生4",
            "性别": "男",
            "身份证号": "370323198707140236",
            "院系": "艺术学院",
            "专业": "艺术学",
            "班级": "1班",
            "专业人数": "30",
            "学习成绩得分": "91",
            "学习成绩本院本专业排名": "3",
            "平均绩点": "88.79",
            "科研和实践成果得分": "2",
            "综合测评得分": "90.79",
            "综合测评本院本专业排名": "1",

          },
          {
            "学号": "202301005",
            "姓名": "学生5",
            "性别": "女",
            "身份证号": "370323198707140236",
            "院系": "体育学院",
            "专业": "体育教育",
            "班级": "2班",
            "专业人数": "28",
            "学习成绩得分": "85",
            "学习成绩本院本专业排名": "4",
            "平均绩点": "93.55",
            "科研和实践成果得分": "4",
            "综合测评得分": "97.55",
            "综合测评本院本专业排名": "1",

          },
          {
            "学号": "202301006",
            "姓名": "学生6",
            "性别": "女",
            "身份证号": "370323198707140236",
            "院系": "护理学院",
            "专业": "护理学",
            "班级": "1班",
            "专业人数": "18",
            "学习成绩得分": "89",
            "学习成绩本院本专业排名": "5",
            "平均绩点": "79.11",
            "科研和实践成果得分": "3.5",
            "综合测评得分": "82.61",
            "综合测评本院本专业排名": "1",

          },
          {
            "学号": "202301007",
            "姓名": "学生7",
            "性别": "女",
            "身份证号": "370323198707140236",
            "院系": "政治与法律学院",
            "专业": "政治学",
            "班级": "3班",
            "专业人数": "18",
            "学习成绩得分": "88",
            "学习成绩本院本专业排名": "3",
            "平均绩点": "80.00",
            "科研和实践成果得分": "5",
            "综合测评得分": "85",
            "综合测评本院本专业排名": "5",

          },
          {
            "学号": "202301008",
            "姓名": "学生8",
            "性别": "男",
            "身份证号": "370323198707140236",
            "院系": "政治与法律学院",
            "专业": "政治学",
            "班级": "1班",
            "专业人数": "20",
            "学习成绩得分": "79",
            "学习成绩本院本专业排名": "1",
            "平均绩点": "90.03",
            "科研和实践成果得分": "10",
            "综合测评得分": "100.03",
            "综合测评本院本专业排名": "3",

          },
          {
            "学号": "202301009",
            "姓名": "学生9",
            "性别": "女",
            "身份证号": "370323198707140236",
            "院系": "政治与法律学院",
            "专业": "政治学",
            "班级": "1班",
            "专业人数": "20",
            "学习成绩得分": "92",
            "学习成绩本院本专业排名": "2",
            "平均绩点": "99.99",
            "科研和实践成果得分": "2",
            "综合测评得分": "101.99",
            "综合测评本院本专业排名": "1",

          },
          {
            "学号": "202301010",
            "姓名": "学生10",
            "性别": "女",
            "身份证号": "370323198707140236",
            "院系": "政治与法律学院",
            "专业": "政治学",
            "班级": "1班",
            "专业人数": "20",
            "学习成绩得分": "92",
            "学习成绩本院本专业排名": "4",
            "平均绩点": "100",
            "科研和实践成果得分": "1",
            "综合测评得分": "101",
            "综合测评本院本专业排名": "2",

          },
          {
            "学号": "202301011",
            "姓名": "学生11",
            "性别": "男",
            "身份证号": "370323198707140236",
            "院系": "政治与法律学院",
            "专业": "政治学",
            "班级": "1班",
            "专业人数": "20",
            "学习成绩得分": "91",
            "学习成绩本院本专业排名": "3",
            "平均绩点": "60.00",
            "科研和实践成果得分": "1",
            "综合测评得分": "61",
            "综合测评本院本专业排名": "8",

          },
          {
            "学号": "202301012",
            "姓名": "学生12",
            "性别": "女",
            "身份证号": "370323198707140236",
            "院系": "政治与法律学院",
            "专业": "政治学",
            "班级": "1班",
            "专业人数": "20",
            "学习成绩得分": "85",
            "学习成绩本院本专业排名": "5",
            "平均绩点": "70.89",
            "科研和实践成果得分": "10",
            "综合测评得分": "80.89",
            "综合测评本院本专业排名": "6",

          },
          {
            "学号": "202301013",
            "姓名": "学生13",
            "性别": "女",
            "身份证号": "370323198707140236",
            "院系": "政治与法律学院",
            "专业": "政治学",
            "班级": "1班",
            "专业人数": "20",
            "学习成绩得分": "88",
            "学习成绩本院本专业排名": "6",
            "平均绩点": "78.88",
            "科研和实践成果得分": "15",
            "综合测评得分": "93.88",
            "综合测评本院本专业排名": "4",

          },
          {
            "学号": "202301014",
            "姓名": "学生14",
            "性别": "女",
            "身份证号": "370323198707140236",
            "院系": "政治与法律学院",
            "专业": "政治学",
            "班级": "1班",
            "专业人数": "20",
            "学习成绩得分": "89",
            "学习成绩本院本专业排名": "7",
            "平均绩点": "70.99",
            "科研和实践成果得分": "0",
            "综合测评得分": "70.99",
            "综合测评本院本专业排名": "7",

          }
        ]
      }
      if (name === '1722176593545134080_各学院总体状态统计表') {
        this.columnDefs = [{
          headerName: "#",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          pinned: true,
          width: 50,
        },
        {
          field: "学院",
          headerName: "学院",
        },
        {
          field: "专业",
          headerName: "专业",
        },
        {
          field: "导入学生数",
          headerName: "导入学生数",
        },
        {
          field: "加分项填报学生数",
          headerName: "加分项填报学生数",
        },
        {
          field: "学院待审核数量",
          headerName: "学院待审核数量",
        },
        {
          field: "学院已审核数量",
          headerName: "学院已审核数量",
        },
        {
          field: "学校待审核数量",
          headerName: "学校待审核数量",
        },
        {
          field: "学校审核通过数量",
          headerName: "学校审核通过数量",
        }]
        this.rowData = [{
          "学院": "民商经济法学院",
          "专业": "法学（普通法学-民商经济法学院）",
          "导入学生数": "100",
          "加分项填报学生数": "10",
          "学院待审核数量": "100",
          "学院已审核数量": "10",
          "学校待审核数量": "100",
          "学校审核通过数量": "10"
        },
        {
          "学院": "国际法学院",
          "专业": "法学（普通法学-国际法学院）",
          "导入学生数": "120",
          "加分项填报学生数": "15",
          "学院待审核数量": "120",
          "学院已审核数量": "15",
          "学校待审核数量": "120",
          "学校审核通过数量": "15"
        },
        {
          "学院": "刑事司法学院",
          "专业": "法学（普通法学-刑事司法学院）",
          "导入学生数": "50",
          "加分项填报学生数": "28",
          "学院待审核数量": "50",
          "学院已审核数量": "28",
          "学校待审核数量": "50",
          "学校审核通过数量": "28"
        },
        {
          "学院": "刑事司法学院",
          "专业": "侦查学（网络犯罪侦查方向）",
          "导入学生数": "100",
          "加分项填报学生数": "30",
          "学院待审核数量": "100",
          "学院已审核数量": "30",
          "学校待审核数量": "100",
          "学校审核通过数量": "30"
        },
        {
          "学院": "政治与公共管理学院",
          "专业": "公共事业管理",
          "导入学生数": "30",
          "加分项填报学生数": "9",
          "学院待审核数量": "30",
          "学院已审核数量": "9",
          "学校待审核数量": "30",
          "学校审核通过数量": "9"
        },
        {
          "学院": "政治与公共管理学院",
          "专业": "国际政治",
          "导入学生数": "30",
          "加分项填报学生数": "6",
          "学院待审核数量": "30",
          "学院已审核数量": "6",
          "学校待审核数量": "30",
          "学校审核通过数量": "6"
        },
        {
          "学院": "政治与公共管理学院",
          "专业": "行政管理",
          "导入学生数": "40",
          "加分项填报学生数": "9",
          "学院待审核数量": "40",
          "学院已审核数量": "9",
          "学校待审核数量": "40",
          "学校审核通过数量": "9"
        },
        {
          "学院": "政治与公共管理学院",
          "专业": "政治学与行政学",
          "导入学生数": "40",
          "加分项填报学生数": "8",
          "学院待审核数量": "40",
          "学院已审核数量": "8",
          "学校待审核数量": "40",
          "学校审核通过数量": "8"
        },
        {
          "学院": "商学院",
          "专业": "工商管理",
          "导入学生数": "50",
          "加分项填报学生数": "7",
          "学院待审核数量": "50",
          "学院已审核数量": "7",
          "学校待审核数量": "50",
          "学校审核通过数量": "7"
        },
        {
          "学院": "商学院",
          "专业": "国际商务",
          "导入学生数": "50",
          "加分项填报学生数": "10",
          "学院待审核数量": "50",
          "学院已审核数量": "10",
          "学校待审核数量": "50",
          "学校审核通过数量": "10"
        },
        {
          "学院": "商学院",
          "专业": "经济学",
          "导入学生数": "50",
          "加分项填报学生数": "2",
          "学院待审核数量": "50",
          "学院已审核数量": "2",
          "学校待审核数量": "50",
          "学校审核通过数量": "2"
        },
        {
          "学院": "商学院",
          "专业": "金融工程（成思危现代金融菁英班）",
          "导入学生数": "20",
          "加分项填报学生数": "20",
          "学院待审核数量": "20",
          "学院已审核数量": "20",
          "学校待审核数量": "20",
          "学校审核通过数量": "20"
        },
        {
          "学院": "人文学院",
          "专业": "汉语言文学",
          "导入学生数": "200",
          "加分项填报学生数": "40",
          "学院待审核数量": "200",
          "学院已审核数量": "40",
          "学校待审核数量": "200",
          "学校审核通过数量": "40"
        },
        {
          "学院": "光明新闻与传播学院",
          "专业": "新闻学",
          "导入学生数": "100",
          "加分项填报学生数": "10",
          "学院待审核数量": "100",
          "学院已审核数量": "10",
          "学校待审核数量": "100",
          "学校审核通过数量": "10"
        },
        {
          "学院": "光明新闻与传播学院",
          "专业": "网络与新媒体",
          "导入学生数": "100",
          "加分项填报学生数": "10",
          "学院待审核数量": "100",
          "学院已审核数量": "10",
          "学校待审核数量": "100",
          "学校审核通过数量": "10"
        },
        {
          "学院": "社会学院",
          "专业": "社会工作",
          "导入学生数": "20",
          "加分项填报学生数": "2",
          "学院待审核数量": "20",
          "学院已审核数量": "2",
          "学校待审核数量": "20",
          "学校审核通过数量": "2"
        },
        {
          "学院": "社会学院",
          "专业": "社会学",
          "导入学生数": "30",
          "加分项填报学生数": "1",
          "学院待审核数量": "30",
          "学院已审核数量": "1",
          "学校待审核数量": "30",
          "学校审核通过数量": "1"
        },
        {
          "学院": "社会学院",
          "专业": "应用心理学",
          "导入学生数": "60",
          "加分项填报学生数": "3",
          "学院待审核数量": "60",
          "学院已审核数量": "3",
          "学校待审核数量": "60",
          "学校审核通过数量": "3"
        },
        {
          "学院": "外国语学院",
          "专业": "德语",
          "导入学生数": "20",
          "加分项填报学生数": "4",
          "学院待审核数量": "20",
          "学院已审核数量": "4",
          "学校待审核数量": "20",
          "学校审核通过数量": "4"
        },
        {
          "学院": "马克思主义学院",
          "专业": "思想政治教育",
          "导入学生数": "200",
          "加分项填报学生数": "33",
          "学院待审核数量": "200",
          "学院已审核数量": "33",
          "学校待审核数量": "200",
          "学校审核通过数量": "33"
        }]
      }

    },
    loginSuccess(){
      let _this = this;
      if (!_this.isBlank(_this.formId)) {
        _this.$router.push({
          path: '/form/multipleFormDetail/' + _this.encode(_this.projectId) + '?formId=' + _this.encode(_this.formId)
        })
      } else {
        _this.$router.push({
          path: '/form/multipleFormDetail/' + _this.encode(_this.projectId)
        })
      }
    }
  },
};
</script>

<style lang="scss" scoped>
[v-cloak] {
  display: none;
}
/* table 样式 */
.details-intro table {
  border-top: 1px solid #dfe6ec;
  border-left: 1px solid #dfe6ec;
  border-collapse: collapse;
}
.details-intro table td,
.details-intro table th {
  border-bottom: 1px solid #dfe6ec;
  border-right: 1px solid #dfe6ec;
  padding: 10px 5px;
  text-align: center;
}
/* blockquote 样式 */
.details-intro blockquote {
  display: block;
  border-left: 8px solid #d0e5f2;
  padding: 5px 10px;
  margin: 10px 0;
  line-height: 1.4;
  font-size: 100%;
  background-color: #f1f1f1;
}
/* code 样式 */
.details-intro code {
  display: inline-block;
  *display: inline;
  *zoom: 1;
  background-color: #f1f1f1;
  border-radius: 3px;
  padding: 10px;
  margin: 0 3px;
  overflow-x: scroll;
}
.details-intro pre code {
  display: block;
}
// TODO: 删除这里样式
.detail-nav {
  width: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  height: 56px;
  -webkit-transform: translateZ(0);
  margin: auto;
  box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);
  -webkit-box-shadow: 0 2px 4px 0 rgba(51, 45, 64, 0.1);

  .detail-container {
    display: inline-flex;
    justify-content: space-between;
  }

  .detail-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    line-height: 56px;
    font-size: 14px;
    color: #333333;
    .detail-left-logo {
      cursor: pointer;
      font-size: 20px;
    }
    .separator {
      width: 1px;
      height: 20px;
      border-right: 1px solid #dbdde7;
      margin: 0 16px;
    }
    .headerNow {
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .detail-center {
    max-width: 600px;
    margin: auto;
    line-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: 16px;
    color: #404040;
  }
  .form-fill-btn {
    line-height: 60px;
  }
}

.app-detail-container {
  // height: auto;
  height: calc(100vh - 80px);
  margin: 20px auto;
  padding-top: 0;

  ::v-deep .project-detail-img {
    width: 200px;
    height: 120px;
    img {
      width: 100%;
      height: 100%;
      max-width: 100%;
      border-radius: 6px;
    }
    .project-detail-title-btn-wrapper {
      width: 170px;
      display: flex;
      display: -webkit-flex;
      align-items: center;
      justify-content: flex-end;
      .btn-wrapper {
        cursor: pointer;
        display: inline-block;
        margin-left: 8px;
        .btn {
          // width: 58px;
          // height: 26px;
          // font-size: 12px;
          // font-weight: 400;
          // color: #646d7a;
          // line-height: 22px;
          // padding: 0;
          // display: flex;
          // flex-direction: row;
          // align-items: center;
          // justify-content: center;
        }
        .collect-btn {
          color: #646d7a;
          border-color: #dbdde7;
          background: transparent;
          &.active {
            color: #f09f35;
            // border-color: #F09F35;
          }
          &:hover {
            border-color: #f09f35;
            background-color: rgba(255, 165, 0, 0.1);
          }
          .collect-icon {
            margin-right: 8px;
            font-size: 14px;
          }
        }
        .btn-icon {
          margin-right: 4px;
          font-size: 14px;
        }
      }
    }
  }
  .project-detail-top-bottom {
    position: absolute;
    bottom: 0px;
    width: 100%;
    .project-detail-date {
      width: 100%;
      font-size: 12px;
      height: 22px;
      line-height: 22px;
      margin: 0;
      padding: 0 0;
      flex-shrink: 0;
      box-sizing: border-box;
      height: calc(100vh - 84px);
      position: relative;
      background: #ffffff;
      overflow: hidden;
      border-radius: 4px;
      transition: width 0.3s;
      display: flex;
      flex-direction: column;

      /* css滚动条美化，宽度为2px */
      ::-webkit-scrollbar {
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }

      &.shrink {
        // background-color: #fafafa;
      }

      .menu-tool {
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px;
        box-sizing: border-box;
        .nameSearch {
          flex: 1;
          -webkit-flex: 1;
          transition: width 2s;
        }
        &.menu-title {
          font-size: 16px;
          font-weight: bold;
          display: inline-flex;
          justify-content: space-between;
          border-bottom: solid 1px #eff1f8;

          .menu-title-text {
            width: 80px;
            height: 20px;
            line-height: 20px;
            display: inline-block;
            overflow: hidden;
            position: relative;
            padding-left: 10px;
            color: #232425;
            &::after {
              content: '';
              position: absolute;
              width: 4px;
              height: 15px;
              top: 50%;
              left: 0;
              margin-top: -7.5px;
              background: #3476f0;
              border-radius: 0 3px 3px 0;
            }
          }
          .menu-title-action {
            height: 20px;
            line-height: 20px;
            display: flex;

            &-icon {
              font-size: 18px;
              margin-left: 10px;
              cursor: pointer;
              color: #646d7a;
            }
          }
        }
      }
      .relate-form-list-title {
        height: 40px;
        line-height: 40px;
        margin: 0 0 10px;
        padding: 0;
      }
    }
    .project-detail-creater {
      width: 100%;
      font-size: 12px;
      height: 22px;
      line-height: 22px;
      margin: 0;
      color: #a5abb1;
      .creater {
        margin: 0;
        padding: 0;
        .is-agency-icon {
          display: inline-block;
          width: 40px;
          height: 16px;
          margin-left: 5px;
          vertical-align: -4px;
        }
      }
    }
  }
  .project-intro-title {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0;
    .title-span {
      font-size: 14px;
      color: #404040;
      line-height: 20px;
    }
    .title-line {
      -webkit-box-flex: 1;
      -ms-flex: 1;
      -o-flex: 1;
      -moz-flex: 1;
      flex: 1;
      height: 1px;
      background: #e5e5e5;
      margin-left: 20px;
    }
  }
  .project-intro-con {
    width: 100%;
    font-size: 14px;
    color: #394349;
    word-break: break-all;
    overflow-y: hidden;
    overflow-x: hidden;
  }
  .project-relate-form {
    width: 100%;
    margin: 0px auto;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;

    .relate-form-left {
      width: 240px;
      margin: 0;
      padding: 0 0;
      flex-shrink: 0;
      box-sizing: border-box;
      height: calc(100vh -  80px);
      position: relative;
      background: #ffffff;
      overflow: hidden;
      border-radius: 4px;
      transition: width 0.3s;
      display: flex;
      flex-direction: column;

      /* css滚动条美化，宽度为2px */
      ::-webkit-scrollbar {
        width: 4px;
      }

      ::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }

      &.shrink {
        // background-color: #fafafa;
      }

      .menu-tool {
        width: 100%;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        padding: 15px;
        box-sizing: border-box;
        .nameSearch {
          flex: 1;
          -webkit-flex: 1;
          transition: width 2s;
        }
        &.menu-title {
          font-size: 16px;
          font-weight: bold;
          display: inline-flex;
          justify-content: space-between;
          border-bottom: solid 1px #eff1f8;

          .menu-title-text {
            width: 80px;
            height: 20px;
            line-height: 20px;
            display: inline-block;
            overflow: hidden;
            position: relative;
            padding-left: 10px;
            color: #232425;
            &::after {
              content: '';
              position: absolute;
              width: 4px;
              height: 15px;
              top: 50%;
              left: 0;
              margin-top: -7.5px;
              background: #3476f0;
              border-radius: 0 3px 3px 0;
            }
          }
          .menu-title-action {
            height: 20px;
            line-height: 20px;
            display: flex;

            &-icon {
              font-size: 18px;
              margin-left: 10px;
              cursor: pointer;
              color: #646d7a;
            }
          }
        }
      }
      .relate-form-list-title {
        height: 40px;
        line-height: 40px;
        margin: 0 0 10px;
        padding: 0;
        background: #f0f2f5;
        font-size: 14px;
        color: #404040;
        text-align: center;
      }
      .dragItem {
        // width: 210px;
        cursor: pointer;
        height: 40px;
        // line-height: 40px;
        list-style: none;
        color: #1f2d3d;
        font-size: 14px;
        font-weight: 400;
        margin: 5px 15px 5px 15px;
        box-sizing: border-box;
        display: flex;
        display: -webkit-flex;
        position: relative;
        border-radius: 4px;

        &.disabled{
          cursor:pointer
        }
        .form-icon {
          width: 15px !important;
          height: 15px !important;
          line-height: 15px !important;
          font-size: 15px !important;
          margin-left: 10px;
          margin-right: 8px;
          display: inline-block !important;
          flex-shrink: 0;
          background-size: cover;
          background-position: center center;
        }
        .element-form-icon {
          width: 15px;
          height: 15px;
          line-height: 15px;
          margin-left: 10px;
          margin-right: 8px;
          color: #c5c6c7;
          cursor: move;
          color: #646d7a;
          font-size: 15px;
        }
        .form-name {
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: 10px;
          width: 238px;
        }
        &.active {
          background: rgba(0, 92, 238, 0.08);
          color: #3476f0;
          position: relative;
          .form-name {
            font-weight: 500;
          }
          .form-icon {
            color: #3476f0 !important;
          }
          &::after {
            content: '';
            position: absolute;
            width: 4px;
            height: 30px;
            top: 50%;
            left: 0;
            margin-top: -15px;
            background: rgba(0, 92, 238, 0.4);
            border-radius: 0 3px 3px 0;
          }
        }
        &:hover {
          background: rgba(0, 92, 238, 0.08);
          color: #3476f0;
          .form-more-icon {
            .el-dropdown-link {
              display: block;
            }
          }
        }
        &:hover .form-icon {
          color: #3476f0 !important;
        }
        &:last-child {
          // margin-bottom: 0;
        }
      }

      .more-dialog {
        position: absolute;
        right: -45px;
        min-width: 140px;
        max-width: 140px;
        background: #fff;
        padding: 5px;
        -webkit-box-shadow: 0 1px 10px 0 rgba(226, 226, 226, 0.5);
        box-shadow: 0 1px 10px 0 rgba(226, 226, 226, 0.5);
        -webkit-transition: 0.3s all ease;
        transition: 0.3s all ease;
        cursor: pointer;
        z-index: 101;
        margin: 0 0 0 -70px;
        list-style: none;
        .x-menu-item {
          color: #1f2d3d;
          font-size: 14px;
          text-align: center;
          &:last-child {
            margin-right: 0;
          }
          &:hover {
            background: #e8f4ff;
            border-radius: 3px;
          }
        }
        &.offsetBottom {
          bottom: 40px;
        }
        &.offsetTop {
          top: 40px;
        }
      }

      &.shrink {
        width: 68px;
        transition: width 0.3s;
        .nameSearch {
          display: none;
        }
        .svg-icon-form {
          display: none;
        }
        .form-name {
          margin-left: 0px;
          height: 40px;
          cursor: pointer;
        }
        .form-name-wrapper {
          // width: 30px;
          // height: 30px;
          // line-height: 30px;
          // overflow: hidden;
          // text-overflow: unset;
          // letter-spacing: 20px;
          // background-color: #ffc678;
          // text-align: center;
          // padding-left: 0px;
          // margin-left: 0px;
          // text-indent: 7px;
          // font-size: 16px;
          // color: #fff;
          // border-radius: 5px;
          // margin-top:5px;
          // display: inline-block;
          // transition: 0.3s;
        }

        .form-name-wrapper-shrink {
          transition: 0.3s;
          padding-left: 0px;
        }
        // .form-more-icon{
        //     display:none!important;
        // }

        .form-group-shrink {
          background-color: #f3f4f5;
          border-bottom: solid 1px #e5e6e7;
        }

        .shrink-icon {
          svg {
            transform: rotate(0deg);
          }
        }
      }

      .shrink-icon {
        width: 15px;
        height: 40px;
        line-height: 36px;
        margin-top: -10px;
        position: absolute;
        right: -15px;
        top: 15px;
        padding-top: 1px;
        background-color: #fafafa;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-align: center;
        border: solid 1px #f3f4f5;
        border-left: none 0px;
        border-radius: 0px 4px 4px 0px;
        cursor: pointer;
        z-index: 100;
        box-shadow: 2 2px 2px 0px rgba(51, 45, 64, 0.1);
        svg {
          margin-left: -2px;
          transition: 0.3s;
          transform: rotate(180deg);
        }

        &:hover {
          background-color: #f3f3f3;
        }
      }
    }
    .relate-form-middle {
      width: 16px;
      flex-shrink: 0;

      .shrink-icon {
        width: 15px;
        height: 40px;
        line-height: 36px;
        padding-top: 1px;
        background-color: #f4f8fe;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        text-align: center;
        border: solid 1px #f3f4f5;
        border-left: none 0px;
        border-radius: 0px 4px 4px 0px;
        cursor: pointer;
        z-index: 100;
        -webkit-box-shadow: 2 2px 2px 0px rgba(51, 45, 64, 0.1);
        box-shadow: 2 2px 2px 0px rgba(51, 45, 64, 0.1);

        &:hover {
          background-color: #efefef;
        }

        svg {
          width: 14px;
          margin: 0px;
          transition: 0.3s;
          transform: rotate(180deg);
        }
      }

      &.shrink {
        transition: width 0.3s;

        .shrink-icon {
          svg {
            transform: rotate(0deg);
          }
        }
      }
    }
    .relate-form-right {
      width: auto;
      height: 100%;
      display:flex;
      overflow-y: auto;
      flex-grow: 1;
      flex-shrink: 1;
      // min-width: calc(100% - 300px);
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
      // min-height: 500px;
      // height: max-content;
      border-radius: 4px;
      transition: width 0.3s;

      &.shrink {
        width: calc(100% - 68px);
        // min-width: calc(100% - 68px);
      }

      /* css滚动条美化，宽度为2px */
      &::-webkit-scrollbar {
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background-color: #f5f5f5;
      }
    }
  }
}
.form-icon {
  color: #3476f0;
  width: 15px !important;
  height: 15px !important;
  line-height: 15px !important;
  font-size: 15px !important;
  margin-left: 10px;
  margin-right: 8px;
  display: inline-block !important;
  flex-shrink: 0;
  background-size: cover;
  background-position: center center;
  cursor: move;
  flex-shrink: 0;
}
.formGroupItemEmpty {
  text-align: center;
  color: #c8c8c8;
  width: 100%;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
  user-select: none;
  margin-bottom: 10px;
  display: inline-block;
}
.form-fill-detail {
  margin-top: 30px;
  width: 710px;
  .fill-detail-item {
    width: 100%;
    margin-bottom: 20px;
    .label-title {
      display: inline-block;
      width: 100%;
      font-size: 13px;
      font-weight: 600;
      color: #394349;
      line-height: 18px;
      padding-bottom: 10px;
    }
    .time-slot-list {
      margin: 0;
      list-style: none;
      padding: 0;
      li {
        width: 23.5%;
        padding: 20px 7px;
        border: 1px solid #ebebeb;
        border-radius: 2px;
        color: #333;
        text-align: center;
        background-color: rgba(255, 255, 255, 0.65);
        -webkit-transition: color 0.2s, background-color 0.3s;
        transition: color 0.2s, background-color 0.3s;
        display: inline-block;
        margin-bottom: 20px;
        margin-right: 2%;
        cursor: pointer;
        &:nth-child(4n) {
          margin-right: 0;
        }
        &.is-disabled {
          color: #333;
          background-color: #f4f4f4;
          cursor: no-drop;
        }
        span {
          display: inline-block;
          width: 100%;
          line-height: 24px;
          font-size: 14px;
        }
        &.active {
          border: 1px solid #409eff;
        }
      }
    }
  }
  .fill-detail-footer {
    width: 100%;
    margin: 60px auto 0;
    text-align: center;
  }
  .edit-detail-item {
    width: 100%;
    margin-bottom: 10px;
    font-size: 14px;
    color: #394349;
  }
}
.fontWeight {
  color: #000000 !important;
  font-weight: inherit !important;
}
.fontWeightCur {
  color: #1890ff !important;
  font-weight: inherit !important;
}
.fontWeightHistory {
  color: #999 !important;
  font-weight: inherit !important;
}
.fontNormal {
  color: #606266 !important;
  font-weight: initial !important;
}
.colorBlue {
  color: #409eff !important;
  cursor: pointer;
}
.el-table .cell {
  white-space: nowrap;
  padding: 0 10px;
}
.abow_dialog {
  display: flex;
  justify-content: center;
  align-items: Center;
  overflow: hidden;
  .el-dialog {
    margin: 0 auto !important;
    height: 90%;
    overflow: hidden;
    .el-dialog__body {
      position: absolute;
      left: 0;
      top: 54px;
      bottom: 0;
      right: 0;
      z-index: 1;
      overflow: hidden;
      overflow-y: auto;
    }
  }
}
.form-type-content {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  .type-content-default {
    width: 156px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 156px;
      height: 120px;
      margin-top: 40px;
    }
  }
  .type-content-page {
    width: 135px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 135px;
      height: 126px;
      margin-top: 40px;
    }
  }
}
.el-select-dropdown__item {
  max-width: 710px;
  height: auto;
  overflow: inherit;
  white-space: inherit;
  text-overflow: inherit;
}
.el-radio {
  white-space: normal;
  margin-bottom: 10px;
}
.el-checkbox {
  white-space: normal;
  margin-bottom: 0;
  display: flex;
  display: -webkit-flex;
  align-items: center;
}
.data-download-content {
  width: 100%;
  display: flex;
  display: -webkit-flex;
  justify-content: space-around;
  .type-content-default {
    width: 156px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 156px;
      height: 120px;
      margin-top: 40px;
    }
  }
  .type-content-page {
    width: 160px;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #909090;
    img {
      display: inline-block;
      vertical-align: middle;
      width: 135px;
      height: 126px;
      margin-top: 40px;
    }
  }
}
.ghost {
  opacity: 0.8;
  color: #404040 !important;
  background: #fff !important;
}
/*新版数据统计style*/
.statics-wrapper {
  width: 100%;
  margin-bottom: 20px;
  .statics-title {
    color: #1f2d3d;
    font-weight: 500;
    font-size: 16px;
    padding-bottom: 20px;
  }
  .count-chart-container {
    display: flex;
    display: -webkit-flex;
    width: 100%;
    border: 1px solid #e5e5e5;
    padding: 20px 0;
    box-sizing: border-box;
    .count-chart-item {
      text-align: center;
      font-weight: 400;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      flex: 1;
      -webkit-flex: 1;
      .current-amount {
        font-size: 30px;
        color: #262a3e;
        margin-bottom: 10px;
      }
      .sum {
        padding-left: 2px;
        color: #b0b0b9;
        font-size: 14px;
      }
    }
  }
  .form-data-list {
    width: 100%;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    .data-list-item {
      width: 100%;
      display: flex;
      display: -webkit-flex;
      flex-direction: column;
      h3 {
        padding: 0;
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        color: #262a3e;
        .num {
          color: #1890ff;
          margin-right: 10px;
        }
      }
      .fieldContent {
        margin-top: 10px;
        .fieldContent-item {
          width: 100%;
          padding: 0;
          margin: 0;
          display: flex;
          display: -webkit-flex;
          justify-content: space-between;
          align-items: center;
          line-height: 30px;
          font-size: 14px;
          color: #262a3e;
          .field-value {
            color: #898989;
          }
        }
      }
      .statics-echarts-container {
        display: flex;
        flex-direction: row;
      }
    }
  }
}
/*预览图片*/
.preview-img-bar {
  width: 100%;
  margin-bottom: 10px;
  span {
    margin-right: 15px;
    line-height: 18px;
    height: 18px;
    display: inline-block;
    cursor: pointer;
  }
}
.show-image {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}
.noticeHTitle {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -30px;
  cursor: pointer;
}
.officialChatImg {
  width: 100px;
  height: 100px;
  display: block;
  position: absolute;
  right: 50px;
  display: none;
  z-index: 100;
}
.noticeHTitle:hover .officialChatImg {
  display: block;
}
.el-button--cyan {
  background-color: #20b2aa;
  border-color: #20b2aa;
  color: #ffffff;
}

.el-collapse .el-collapse-item__wrap {
  overflow: initial;
}
//如果嵌套
.app-container {
  .app-container {
    padding: 0px !important;
  }
  .form-info-content {
    padding-right: 10px;
  }
}
</style>
