<template>
  <div style="width:100%">
    <div v-if="isExpand" style="width:100%" :style="{textAlign:isExpand?'':'center'}">
      <div class="form-group-header" @click="page.onFormClick(item,cur,formGroupCur)" @mouseenter="showSettingIcon=true" @mouseleave="showSettingIcon=false">
        <div class="form-group-header-left">
          <slot name="icon"></slot>
          <slot name="title"></slot>
        </div>
        <formActionMenu :type="type" :item="item" :page="page" :projectDetail="projectDetail" :loginUserId="loginUserId" :showSettingIcon="showSettingIcon" :isExpand="isExpand"></formActionMenu>
      </div>
    </div>
    <div v-else class="form-group-shrink">
      <div class="form-name-wrapper form-name-group-wrapper" @click="page.onFormClick(item,cur,formGroupCur)">
        <slot name="title"></slot>
      </div>
    </div>

  </div>
</template>
<script>

import formActionMenu from '../formGroup/formActionMenu'
export default {
  components: {
    formActionMenu
  },
  props: {
    type: String,
    item: Object,
    cur:Number,
    formGroupCur:Number,
    page: Object,
    isExpand: Boolean,
    projectDetail:Object,
    loginUserId:Number,
  },
  data() {
    return {
      showSettingIcon: false
    }
  }
}
</script>
<style lang="scss" scoped>
.form-group-header {
  width: 100%;
  height: 40px;
  line-height: 40px;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    color: #3476F0;
  }
  .form-group-header-left {
    height: 35px;
    line-height: 35px;
    display: inline-flex;
    align-items: center;
  }
}

.form-group-shrink {
  width: 30px;
  height: 30px;
  line-height: 30px;
  overflow: hidden;
  text-overflow: unset;
  color: #fff;
  background-color: #d5d5d5;
  text-align: center;
  padding-left: 0px;
  margin-left: 5px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 5px;
  display: inline-block;
  text-align: center;

  .form-name-wrapper {
    cursor: pointer;
  }

  .form-name-group-wrapper {
    background-color: #d5d5d5;
    text-align: center;
    display: flex;
    height: 30px;
    line-height: 30px;
    width: 30px;
    align-items: center;
    justify-content: center;
  }
}
</style>
